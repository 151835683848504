import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _excluded = ["children", "options", "disabled", "theme", "optionListClassName"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import Button from './Button';
import Ripple from '../Ripple';
import { buttonDropdownContainer, buttonDropdownOptionsContainer, buttonDropdownOptionItem } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ButtonDropdown = function ButtonDropdown(_ref) {
  var children = _ref.children,
    _ref$options = _ref.options,
    options = _ref$options === void 0 ? [] : _ref$options,
    _ref$disabled = _ref.disabled,
    disabled = _ref$disabled === void 0 ? !options.length : _ref$disabled,
    theme = _ref.theme,
    optionListClassName = _ref.optionListClassName,
    buttonProps = _objectWithoutProperties(_ref, _excluded);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var handleBlur = function handleBlur(_ref2) {
    var currentTarget = _ref2.currentTarget;
    return setTimeout(function () {
      if (!currentTarget.contains(document.activeElement) && isOpen) setIsOpen(false);
    });
  };
  var renderBaseOptionElement = function renderBaseOptionElement(option, i) {
    var optionRender = option.render ? option.render : option.label;
    return ___EmotionJSX("div", {
      key: "buttonDropdownItem".concat(i * Math.random()),
      role: "presentation",
      onClick: option.onClick
    }, ___EmotionJSX(Ripple, {
      backColor: "secondaryDark",
      className: buttonDropdownOptionItem(theme)
    }, optionRender));
  };
  return ___EmotionJSX("div", {
    className: buttonDropdownContainer,
    onBlur: handleBlur
  }, ___EmotionJSX(Button, _extends({}, buttonProps, {
    disabled: disabled,
    onClick: function onClick() {
      return setIsOpen(!isOpen);
    }
  }), children), ___EmotionJSX("div", {
    className: buttonDropdownOptionsContainer(isOpen, options.length, optionListClassName, theme)
  }, options.map(renderBaseOptionElement)));
};
ButtonDropdown.propTypes = {
  options: PropTypes.array,
  children: PropTypes.any,
  theme: PropTypes.object,
  disabled: PropTypes.bool,
  optionListClassName: PropTypes.string
};
export default withTheme(ButtonDropdown);