import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNaN from "lodash/isNaN";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.number.constructor.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input } from '../../UI';
import { iconInputUpAndDown, counterInput, containerInputWrap } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CounterInput = function CounterInput(props) {
  var count = props.count,
    _props$max = props.max,
    max = _props$max === void 0 ? Infinity : _props$max,
    _props$min = props.min,
    min = _props$min === void 0 ? -Infinity : _props$min,
    width = props.width,
    onCountChange = props.onCountChange;
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    inputValue = _useState2[0],
    setInputValue = _useState2[1];
  useEffect(function () {
    setInputValue(count);
  }, [count]);
  var handleValueDecrement = function handleValueDecrement() {
    if (inputValue > min) {
      var decrementedValue = inputValue - 1;
      setInputValue(decrementedValue);
      onCountChange(decrementedValue);
    }
  };
  var handleValueIncrement = function handleValueIncrement() {
    if (inputValue < max) {
      var incrementedValue = inputValue + 1;
      setInputValue(incrementedValue);
      onCountChange(incrementedValue);
    }
  };
  var handleBlur = function handleBlur() {
    var num = inputValue;
    num = num > max ? max : num;
    num = num < min ? min : num;
    setInputValue(num);
    onCountChange(num);
  };
  var handleChangeInput = function handleChangeInput(_ref) {
    var value = _ref.target.value;
    var newValue = Number(value);
    if (!_isNaN(newValue)) {
      setInputValue(newValue);
    }
  };
  return ___EmotionJSX("div", {
    className: containerInputWrap
  }, ___EmotionJSX(Input, {
    className: counterInput(width),
    value: inputValue,
    onChange: handleChangeInput,
    onBlur: handleBlur
  }), ___EmotionJSX("div", null, ___EmotionJSX("div", {
    className: iconInputUpAndDown
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "keyboard_arrow_up",
    color: "textLightSecondary",
    onClick: handleValueIncrement
  })), ___EmotionJSX("div", {
    className: iconInputUpAndDown
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "keyboard_arrow_down",
    color: "textLightSecondary",
    onClick: handleValueDecrement
  }))));
};
CounterInput.propTypes = {
  count: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  width: PropTypes.number,
  onCountChange: PropTypes.func
};
export default CounterInput;