import _isNil from "lodash/isNil";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Row, Col, Skeleton } from '../../UI';
import OrderDetails from '../../Shared/ItemDetails';
import ColorBatch from '../../Shared/ColorBatch';
import { type } from '../../../enums/wallets';
import { orderStatus, orderPaymentLabels } from '../../../enums/orders';
import { currencyByType } from '../../../utils';
import { orderGeneralInfo, orderStatusAdditionalInfo, mapOrderStatus, mapOrderPaymentStatus } from './config';
import { labelContainer, valueContainer, paymentDataContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var OrderGeneralInfo = function OrderGeneralInfo(props) {
  var _mapOrderStatus$order, _mapOrderStatus$order2;
  var order = props.order;
  var theme = useTheme();
  var getBankAmount = function getBankAmount() {
    var paymentType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var totalAmount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    if (paymentType.toLowerCase().includes('bank75')) {
      var bankAmount75 = totalAmount.substring(2) * 0.75;
      return currencyByType(bankAmount75, type.CASH);
    }
    if (paymentType.toLowerCase().includes('bank50')) {
      var bankAmount50 = totalAmount.substring(2) * 0.5;
      return currencyByType(bankAmount50, type.CASH);
    }
    if (paymentType.toLowerCase().includes('bank25')) {
      var bankAmount25 = totalAmount.substring(2) * 0.25;
      return currencyByType(bankAmount25, type.CASH);
    }
    return currencyByType(totalAmount.substring(2), type.CASH);
  };
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(OrderDetails, {
    item: order,
    properties: orderGeneralInfo
  }), ((order === null || order === void 0 ? void 0 : order.paymentTypeId) || '').toLowerCase().includes('bank') && ___EmotionJSX(Row, {
    md: 6
  }, ___EmotionJSX(Col, {
    className: labelContainer
  }, ___EmotionJSX("h4", null, "Bank Amount")), ___EmotionJSX(Col, {
    className: valueContainer
  }, _isNil(order.totalAmount) ? ___EmotionJSX(Skeleton, {
    width: 120,
    height: 18
  }) : ___EmotionJSX("span", null, getBankAmount(order.paymentTypeId, order.totalAmount)))), ((order === null || order === void 0 ? void 0 : order.payments) || []).map(function (payment, ind) {
    var _mapOrderPaymentStatu, _mapOrderPaymentStatu2, _payment$refId;
    return ___EmotionJSX("div", {
      key: ind,
      className: paymentDataContainer(theme)
    }, ___EmotionJSX(Row, {
      md: 6
    }, ___EmotionJSX(Col, {
      className: labelContainer
    }, ___EmotionJSX("h4", null, orderPaymentLabels[payment.origin], " Payment Status")), ___EmotionJSX(Col, {
      className: valueContainer
    }, ___EmotionJSX(ColorBatch, {
      type: (_mapOrderPaymentStatu = mapOrderPaymentStatus[payment.status]) === null || _mapOrderPaymentStatu === void 0 ? void 0 : _mapOrderPaymentStatu.color
    }, (_mapOrderPaymentStatu2 = mapOrderPaymentStatus[payment.status]) === null || _mapOrderPaymentStatu2 === void 0 ? void 0 : _mapOrderPaymentStatu2.text))), ___EmotionJSX(Row, {
      md: 6
    }, ___EmotionJSX(Col, {
      className: labelContainer
    }, ___EmotionJSX("h4", null, orderPaymentLabels[payment.origin], " Transaction Id")), ___EmotionJSX(Col, {
      className: valueContainer
    }, [(_payment$refId = payment.refId) !== null && _payment$refId !== void 0 ? _payment$refId : 'N/A'])));
  }), ___EmotionJSX(Row, {
    md: 6,
    className: paymentDataContainer(theme)
  }, ___EmotionJSX(Col, {
    className: labelContainer
  }, ___EmotionJSX("h4", null, "Order Status")), ___EmotionJSX(Col, {
    className: valueContainer
  }, _isNil(order.status) ? ___EmotionJSX(Skeleton, {
    width: 120,
    height: 18
  }) : ___EmotionJSX(ColorBatch, {
    type: (_mapOrderStatus$order = mapOrderStatus[order.status]) === null || _mapOrderStatus$order === void 0 ? void 0 : _mapOrderStatus$order.color
  }, (_mapOrderStatus$order2 = mapOrderStatus[order.status]) === null || _mapOrderStatus$order2 === void 0 ? void 0 : _mapOrderStatus$order2.text))), order.status !== orderStatus.Pending && ___EmotionJSX(OrderDetails, {
    item: order,
    properties: orderStatusAdditionalInfo
  }));
};
OrderGeneralInfo.propTypes = {
  order: PropTypes.any
};
export default OrderGeneralInfo;