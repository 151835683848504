import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isArray from "lodash/isArray";
import _isNil from "lodash/isNil";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import queryString from 'query-string';
import { defaultDate, validDate } from '../../utils';
import avatar from '../../assets/images/default-avatar.png';
export var getProfileModel = function getProfileModel(response) {
  var _response$pictureUrl, _response$picThumbnai;
  return response && _objectSpread(_objectSpread({}, response), {}, {
    dateOfBirth: validDate(response.dateOfBirth),
    idExpiration: validDate(response.idExpiration),
    pictureUrl: (_response$pictureUrl = response.pictureUrl) !== null && _response$pictureUrl !== void 0 ? _response$pictureUrl : avatar,
    picThumbnailUrl: (_response$picThumbnai = response.picThumbnailUrl) !== null && _response$picThumbnai !== void 0 ? _response$picThumbnai : avatar
  });
};
export var getAllProfilesParams = function getAllProfilesParams(options) {
  var _options$searchInput;
  var defaultSort = {
    sortField: 'id',
    sortOrder: 'desc'
  };
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField === 'userId' ? 'id' : sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  var filterField = [];
  var filterValue = [];
  if (options.status) {
    filterField.push('status');
    filterValue.push(options.status);
  }
  if (options.searchCriteria && (_options$searchInput = options.searchInput) !== null && _options$searchInput !== void 0 && _options$searchInput.length) {
    filterField.push(options.searchCriteria);
    filterValue.push(options.searchInput);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), options.sort ? mapSort(options.sort) : defaultSort), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};
export var updateProfileBody = function updateProfileBody(data) {
  var _data$idExpiration, _data$dateOfBirth;
  var keysToOmit = ['accounts', 'pictureUrl', 'picThumbnailUrl', 'sponsorAccountId', 'userId', 'lastModified', 'kycs', 'hasMobileAccess'];
  return _objectSpread(_objectSpread({}, _omit(data, keysToOmit)), {}, {
    idExpiration: (_data$idExpiration = data.idExpiration) !== null && _data$idExpiration !== void 0 ? _data$idExpiration : defaultDate,
    dateOfBirth: (_data$dateOfBirth = data.dateOfBirth) !== null && _data$dateOfBirth !== void 0 ? _data$dateOfBirth : defaultDate
  });
};
export var getAllAccountsParams = function getAllAccountsParams(options) {
  var _options$searchInput2;
  var filterField = [];
  var filterValue = [];
  if (options.searchCriteria && (_options$searchInput2 = options.searchInput) !== null && _options$searchInput2 !== void 0 && _options$searchInput2.length) {
    filterField.push(options.searchCriteria);
    filterValue.push(options.searchInput);
  }
  return queryString.stringify(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};
export var getProfileHistoryParams = function getProfileHistoryParams(options) {
  var changeType = options.changeType;
  return queryString.stringify(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), !_isNil(changeType) && {
    changeType: changeType
  }), {
    arrayFormat: 'index'
  });
};
export var getProfileHistoryModel = function getProfileHistoryModel(response) {
  if (!_isArray(response === null || response === void 0 ? void 0 : response.content)) return null;
  var newResults = [];
  response.content.forEach(function (profileData) {
    Object.keys(profileData).forEach(function (property) {
      var keys = property.split('After');
      if (keys.length > 1) {
        var keyBefore = "".concat(keys[0], "Before");
        if (profileData[property] !== profileData[keyBefore]) {
          newResults.push({
            type: keys[0],
            after: profileData[property],
            before: profileData[keyBefore],
            changeDate: profileData.changeDate,
            changedByEmail: profileData.changedByEmail,
            changedByName: profileData.changedByName
          });
        }
      }
    });
  });
  return {
    profileHistory: newResults,
    total: newResults.length
  };
};
export var getNetworkAccountsListParams = function getNetworkAccountsListParams(options) {
  var _options$search;
  var filterField = [];
  var filterValue = [];
  if (options.searchBy && (_options$search = options.search) !== null && _options$search !== void 0 && _options$search.length) {
    filterField.push(options.searchBy);
    filterValue.push(options.search);
  }
  return queryString.stringify(_objectSpread({}, filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};
export var getCommentsListModel = function getCommentsListModel(response) {
  var _response$comments;
  var sortedComments = response === null || response === void 0 || (_response$comments = response.comments) === null || _response$comments === void 0 ? void 0 : _response$comments.sort(function (a, b) {
    var firstDate = new Date(a.date);
    var secondDate = new Date(b.date);
    return secondDate.getTime() - firstDate.getTime();
  });
  return {
    comments: sortedComments !== null && sortedComments !== void 0 ? sortedComments : []
  };
};