function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isString from "lodash/isString";
import _isFunction from "lodash/isFunction";
var _excluded = ["label", "placeholder", "value", "options", "onBlur", "onChange", "components", "async", "dynamicValue", "className"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import hash from 'object-hash';
import AsyncSelect from 'react-select/async';
import { useTheme } from 'emotion-theming';
import { Spinner } from '../Loader';
import colourStyles from './ColorStyles';
import { formGroup, formLabel } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SelectCustom = function SelectCustom(props) {
  var label = props.label,
    placeholder = props.placeholder,
    value = props.value,
    options = props.options,
    onBlur = props.onBlur,
    onChange = props.onChange,
    _props$components = props.components,
    components = _props$components === void 0 ? {} : _props$components,
    _props$async = props.async,
    async = _props$async === void 0 ? false : _props$async,
    _props$dynamicValue = props.dynamicValue,
    dynamicValue = _props$dynamicValue === void 0 ? false : _props$dynamicValue,
    className = props.className,
    rest = _objectWithoutProperties(props, _excluded);
  var prepareValue = _isString(value) ? {
    value: value,
    label: value
  } : value;
  var _useState = useState(prepareValue !== null && prepareValue !== void 0 ? prepareValue : null),
    _useState2 = _slicedToArray(_useState, 2),
    selected = _useState2[0],
    setSelected = _useState2[1];
  var theme = useTheme();
  useEffect(function () {
    dynamicValue && hash({
      value: value
    }) !== hash({
      value: selected
    }) && setSelected(value);
  }, [hash({
    value: value
  })]);
  var handleSelect = function handleSelect(selected) {
    setSelected(selected);
    _isFunction(onChange) && onChange(selected === null || selected === void 0 ? void 0 : selected.value);
  };
  var handleSelectBlur = function handleSelectBlur() {
    return _isFunction(onBlur) && onBlur();
  };
  var SelectComponent = async ? AsyncSelect : Select;
  return ___EmotionJSX("div", {
    className: formGroup(className, theme)
  }, label && ___EmotionJSX("label", {
    className: formLabel(theme)
  }, label), ___EmotionJSX(SelectComponent, _extends({}, options && {
    options: options
  }, {
    value: selected,
    placeholder: placeholder,
    onBlur: handleSelectBlur,
    styles: colourStyles,
    onChange: function onChange(value) {
      return handleSelect(value);
    },
    defaultValue: {
      label: '',
      value: ''
    },
    classNamePrefix: "custom-select",
    components: _objectSpread({
      LoadingMessage: Spinner
    }, components)
  }, rest)));
};
SelectCustom.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.any,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  async: PropTypes.bool,
  dynamicValue: PropTypes.bool,
  components: PropTypes.object,
  className: PropTypes.string
};
export default SelectCustom;