import React from 'react';
import { DetailsLink } from '../../../../components/Shared/TableComponents';
import ColorBatch from '../../../../components/Shared/ColorBatch';
import { currencyByType } from '../../../../utils';
import { type } from '../../../../enums/wallets';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var generalInfo = [{
  label: 'Business Volume',
  value: 'businessVolume'
}, {
  label: 'Coins',
  value: 'coins'
}, {
  label: 'Max Academy Coins',
  value: 'academyCoins'
}, {
  label: 'Split',
  value: 'split'
}, {
  label: 'Max splits',
  value: 'maxSplits'
}, {
  label: 'Promo splits',
  value: 'promoSplits'
}, {
  label: 'Difficulty reduction',
  value: 'difficulty'
}, {
  label: 'Exchange reduction',
  value: 'exchangeReduction'
}, {
  label: 'GAS Amount',
  value: 'gasAmount'
}, {
  label: 'Previous versions',
  value: 'pastVersionsCount'
}, {
  label: 'All splits at once',
  value: 'allSplitsAtOnce',
  bool: true
}];
export var bonusInfo = [{
  label: ' 1st level',
  value: 'hasMatch1Bonus'
}, {
  label: ' 2nd level',
  value: 'hasMatch2Bonus'
}, {
  label: ' 3rd level',
  value: 'hasMatch3Bonus'
}, {
  label: ' 4th level',
  value: 'hasMatch4Bonus'
}];
export var packageListColumns = [{
  name: 'Name',
  value: 'name'
}, {
  name: 'Price',
  value: 'price',
  render: function render(row) {
    return currencyByType(row.price, type.CASH);
  }
}, {
  name: 'Type',
  value: 'type'
}, {
  name: 'Status',
  value: 'active',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.active ? 'success' : 'error'
    }, row.active ? 'Active' : 'Inactive');
  },
  sortable: true
}, {
  name: 'Visibility',
  value: 'visible',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.visible ? 'success' : 'error'
    }, row.visible ? 'Visible' : 'Invisible');
  },
  sortable: true
}, {
  name: 'View details',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/packages/package/".concat(row.catalogueItemId)
    });
  },
  width: 150
}];
export var detailPageBreadcrumbs = [{
  url: '/packages/all-packages',
  label: 'Packages'
}, {
  label: 'Detail Page'
}];
export var addPageBreadcrumbs = [{
  url: '/packages/all-packages',
  label: 'Packages'
}, {
  label: 'Add Package'
}];
export var editPageBreadcrumbs = function editPageBreadcrumbs(catalogueItemId) {
  return [{
    url: '/packages/all-packages',
    label: 'Packages'
  }, {
    url: "/packages/package/".concat(catalogueItemId),
    label: 'Detail Page'
  }, {
    label: 'Edit Package'
  }];
};