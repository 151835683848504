import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import ValuePercent from '../ValuePercent';
import { currentBonusRows } from './config';
import { matchingCurrentBonusBody, matchingCurrentBonusBodyListItem, matchingCurrentBonusBodyListItemValue, matchingCurrentBonusHeader, matchingCurrentBonusHeaderLeftBottomWrap, matchingCurrentBonusHeaderLeftTopWrap, matchingCurrentBonusWrap } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MatchingCurrentBonus = function MatchingCurrentBonus(props) {
  var _currentBonus$totalBv;
  var currentBonus = props.currentBonus;
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: matchingCurrentBonusWrap(theme)
  }, ___EmotionJSX("div", {
    className: matchingCurrentBonusHeader(theme)
  }, ___EmotionJSX("div", {
    className: matchingCurrentBonusHeaderLeftTopWrap
  }, ___EmotionJSX("h3", null, "Current Matching Bonus")), ___EmotionJSX("div", {
    className: matchingCurrentBonusHeaderLeftBottomWrap
  }, ___EmotionJSX(ValuePercent, {
    value: (_currentBonus$totalBv = currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.totalBv) !== null && _currentBonus$totalBv !== void 0 ? _currentBonus$totalBv : 0
  }))), ___EmotionJSX("div", {
    className: matchingCurrentBonusBody
  }, currentBonusRows.map(function (row) {
    return ___EmotionJSX("div", {
      className: matchingCurrentBonusBodyListItem(theme),
      key: row.label
    }, ___EmotionJSX("p", null, row.label), ___EmotionJSX("div", {
      className: matchingCurrentBonusBodyListItemValue(theme)
    }, row.render(currentBonus)));
  })));
};
MatchingCurrentBonus.propTypes = {
  currentBonus: PropTypes.object
};
export default MatchingCurrentBonus;