function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Login from './Login';
import { contentContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var routesDefinition = [{
  exact: true,
  path: '/login',
  component: Login
}].map(function (route) {
  return ___EmotionJSX(Route, _extends({
    key: route.path
  }, route));
});
export var PublicRoutes = function PublicRoutes(mainLink) {
  return ___EmotionJSX("section", {
    className: contentContainer
  }, ___EmotionJSX(Switch, null, routesDefinition, ___EmotionJSX(Redirect, {
    to: mainLink
  })));
};