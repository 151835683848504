import _flatten from "lodash/flatten";
import _isArray from "lodash/isArray";
import _isNumber from "lodash/isNumber";
import _startCase from "lodash/startCase";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import { jsonParse } from './lodash';
var parseTitle = function parseTitle(title) {
  return _isNumber(Number(title)) ? 'Error' : title;
};
var parseFormDataError = function parseFormDataError(err) {
  return {
    title: "Invalid ".concat(Object.keys(err.errors).map(_startCase).join(', '))
  };
};
var parseSingleTitleAndMessage = function parseSingleTitleAndMessage(err) {
  return {
    title: parseTitle(err === null || err === void 0 ? void 0 : err.code),
    message: err === null || err === void 0 ? void 0 : err.message
  };
};
var parseMultiTitleAndMessage = function parseMultiTitleAndMessage(res) {
  var result = _flatten(res.map(function (el) {
    var _el$message;
    var message = (_el$message = el === null || el === void 0 ? void 0 : el.message) !== null && _el$message !== void 0 ? _el$message : '';
    var jsonStart = message.indexOf('{') === -1 ? null : message.indexOf('{');
    var jsonMessage = message.substr(jsonStart !== null && jsonStart !== void 0 ? jsonStart : message.length);
    if (!(jsonMessage !== null && jsonMessage !== void 0 && jsonMessage.length)) return parseSingleTitleAndMessage(el);
    try {
      var _parsedMessage$errors;
      var parsedMessage = jsonParse(jsonMessage);
      if (!(parsedMessage !== null && parsedMessage !== void 0 && (_parsedMessage$errors = parsedMessage.errors) !== null && _parsedMessage$errors !== void 0 && _parsedMessage$errors.length)) return [];
      return parsedMessage === null || parsedMessage === void 0 ? void 0 : parsedMessage.errors.map(function (err) {
        return {
          title: parseTitle(err === null || err === void 0 ? void 0 : err.code),
          message: err === null || err === void 0 ? void 0 : err.description
        };
      });
    } catch (err) {
      return parseSingleTitleAndMessage(el);
    }
  }));
  return result;
};
export var parseError = function parseError(error) {
  var _error$data;
  return _isArray(error.data) ? parseMultiTitleAndMessage(error.data) : (_error$data = error.data) !== null && _error$data !== void 0 && _error$data.errors ? [parseFormDataError(error.data)] : [parseSingleTitleAndMessage(error.data)];
};