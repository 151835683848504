import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { type } from '../../../enums/wallets';
import { copyToClipboard, currencyByType } from '../../../utils';
import { Button } from '../../UI';
import { container } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PackagesPaperHeader = function PackagesPaperHeader(props) {
  var items = props.items,
    _props$itemsType = props.itemsType,
    itemsType = _props$itemsType === void 0 ? 'packages' : _props$itemsType;
  var theme = useTheme();
  var totalItems = (items === null || items === void 0 ? void 0 : items.length) || 0;
  var isPackages = itemsType === 'packages';
  var headerText = "".concat(isPackages ? 'Packages' : 'Subscriptions');
  var handleCopyToClipboard = function handleCopyToClipboard() {
    var getText = items === null || items === void 0 ? void 0 : items.map(function (el) {
      return "".concat(el.name, ": ").concat(el.giftCode, "; Price: ").concat(currencyByType(el.price, type.CASH)).concat(el.duration ? " / ".concat(el.duration, " months") : '');
    });
    return copyToClipboard(getText, 'Successfully copied order packages.');
  };
  return ___EmotionJSX("div", {
    className: container(theme)
  }, ___EmotionJSX("h2", null, headerText, " (", totalItems, ")"), isPackages && ___EmotionJSX(Button, {
    disabled: totalItems === 0,
    onClick: handleCopyToClipboard
  }, "Copy Order Packages"));
};
PackagesPaperHeader.propTypes = {
  items: PropTypes.array,
  itemsType: PropTypes.string
};
export default PackagesPaperHeader;