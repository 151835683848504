import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
/* eslint-disable global-require */

/* eslint-disable import/no-dynamic-require */
var imagePath = function imagePath(imgName) {
  return require("../assets/flags/".concat(imgName, ".svg"));
};
var countryList = [{
  label: 'Afghanistan',
  value: 'Afghanistan',
  image: imagePath('111-afghanistan'),
  phoneCode: '+93',
  code: 'AF',
  code_3_letter: 'AFG'
}, {
  label: 'Albania',
  value: 'Albania',
  image: imagePath('099-albania'),
  phoneCode: '+355',
  code: 'AL',
  code_3_letter: 'ALB'
}, {
  label: 'Algeria',
  value: 'Algeria',
  image: imagePath('144-algeria'),
  phoneCode: '+213',
  code: 'DZ',
  code_3_letter: 'DZA'
}, {
  label: 'Andorra',
  value: 'Andorra',
  image: imagePath('045-andorra'),
  phoneCode: '+376',
  code: 'AD',
  code_3_letter: 'AND'
}, {
  label: 'Angola',
  value: 'Angola',
  image: imagePath('117-angola'),
  phoneCode: '+244',
  code: 'AO',
  code_3_letter: 'AGO'
}, {
  label: 'Anguilla',
  value: 'Anguilla',
  image: imagePath('287-anguilla'),
  phoneCode: '+1-264',
  code: 'AI',
  code_3_letter: 'AIA'
}, {
  label: 'Antigua and Barbuda',
  value: 'Antigua and Barbuda',
  image: imagePath('075-antigua-and-barbuda'),
  phoneCode: '+1 268',
  code: 'AG',
  code_3_letter: 'ATG'
}, {
  label: 'Argentina',
  value: 'Argentina',
  image: imagePath('198-argentina'),
  phoneCode: '+54',
  code: 'AR',
  code_3_letter: 'ARG'
}, {
  label: 'Armenia',
  value: 'Armenia',
  image: imagePath('108-armenia'),
  phoneCode: '+374',
  code: 'AM',
  code_3_letter: 'ARM'
}, {
  label: 'Aruba',
  value: 'Aruba',
  image: imagePath('267-aruba'),
  phoneCode: '+297',
  code: 'AW',
  code_3_letter: 'ABW'
}, {
  label: 'Australia',
  value: 'Australia',
  image: imagePath('234-australia'),
  phoneCode: '+61',
  code: 'AU',
  code_3_letter: 'AUS'
}, {
  label: 'Ashmore and Cartier Islands',
  value: 'Ashmore and Cartier Islands',
  image: imagePath('234-australia'),
  phoneCode: '+61',
  code: 'AU',
  code_3_letter: 'AUS'
}, {
  label: 'Coral Sea Islands',
  value: 'Coral Sea Islands',
  image: imagePath('234-australia'),
  phoneCode: '+61',
  code: 'AU',
  code_3_letter: 'AUS'
}, {
  label: 'Austria',
  value: 'Austria',
  image: imagePath('003-austria'),
  phoneCode: '+43',
  code: 'AT',
  code_3_letter: 'AUT'
}, {
  label: 'Azerbaijan',
  value: 'Azerbaijan',
  image: imagePath('141-azerbaijan'),
  phoneCode: '+994',
  code: 'AZ',
  code_3_letter: 'AZE'
}, {
  label: 'Bahamas',
  value: 'Bahamas',
  image: imagePath('120-bahamas'),
  phoneCode: '+1 242',
  code: 'BS',
  code_3_letter: 'BHS'
}, {
  label: 'British Indian Ocean Territory',
  value: 'British Indian Ocean Territory',
  image: imagePath('308-british-indian-ocean-territory'),
  phoneCode: '+246',
  code: 'IO',
  code_3_letter: 'IOT'
}, {
  label: 'Bahrain',
  value: 'Bahrain',
  image: imagePath('138-bahrain'),
  phoneCode: '+973',
  code: 'BH',
  code_3_letter: 'BHR'
}, {
  label: 'Bangladesh',
  value: 'Bangladesh',
  image: imagePath('147-bangladesh'),
  phoneCode: '+880',
  code: 'BD',
  code_3_letter: 'BGD'
}, {
  label: 'Barbados',
  value: 'Barbados',
  image: imagePath('084-barbados'),
  phoneCode: '+1 246',
  code: 'BB',
  code_3_letter: 'BRB'
}, {
  label: 'Belarus',
  value: 'Belarus',
  image: imagePath('135-belarus'),
  phoneCode: '+375',
  code: 'BY',
  code_3_letter: 'BLR'
}, {
  label: 'Belgium',
  value: 'Belgium',
  image: imagePath('165-belgium'),
  phoneCode: '+32',
  code: 'BE',
  code_3_letter: 'BEL'
}, {
  label: 'Belize',
  value: 'Belize',
  image: imagePath('078-belize'),
  phoneCode: '+501',
  code: 'BZ',
  code_3_letter: 'BLZ'
}, {
  label: 'Bermuda',
  value: 'Bermuda',
  image: imagePath('288-bermuda'),
  phoneCode: '+1-441',
  code: 'BM',
  code_3_letter: 'BMU'
}, {
  label: 'Benin',
  value: 'Benin',
  image: imagePath('060-benin'),
  phoneCode: '+229',
  code: 'BJ',
  code_3_letter: 'BEN'
}, {
  label: 'Bhutan',
  value: 'Bhutan',
  image: imagePath('040-bhutan'),
  phoneCode: '+975',
  code: 'BT',
  code_3_letter: 'BTN'
}, {
  label: 'Bolivia',
  value: 'Bolivia',
  image: imagePath('150-bolivia'),
  phoneCode: '+591',
  code: 'BO',
  code_3_letter: 'BOL'
}, {
  label: 'Bonaire',
  value: 'Bonaire',
  image: imagePath('272-bonaire'),
  phoneCode: '+5997',
  code: 'BQ',
  code_3_letter: 'BES'
}, {
  label: 'Bosnia and Herzegovina',
  value: 'Bosnia and Herzegovina',
  image: imagePath('132-bosnia-and-herzegovina'),
  phoneCode: '+387',
  code: 'BA',
  code_3_letter: 'BIH'
}, {
  label: 'Botswana',
  value: 'Botswana',
  image: imagePath('126-botswana'),
  phoneCode: '+267',
  code: 'BW',
  code_3_letter: 'BWA'
}, {
  label: 'Bouvet Island',
  value: 'Bouvet Island',
  image: imagePath('143-norway'),
  phoneCode: '+47',
  code: 'BV',
  code_3_letter: 'BVT'
}, {
  label: 'Brazil',
  value: 'Brazil',
  image: imagePath('255-brazil'),
  phoneCode: '+55',
  code: 'BR',
  code_3_letter: 'BRA'
}, {
  label: 'Brunei',
  value: 'Brunei',
  image: imagePath('119-brunei'),
  phoneCode: '+673',
  code: 'BN',
  code_3_letter: 'BRN'
}, {
  label: 'Bulgaria',
  value: 'Bulgaria',
  image: imagePath('168-bulgaria'),
  phoneCode: '+359',
  code: 'BG',
  code_3_letter: 'BGR'
}, {
  label: 'Burkina Faso',
  value: 'Burkina Faso',
  image: imagePath('090-burkina-faso'),
  phoneCode: '+226',
  code: 'BF',
  code_3_letter: 'BFA'
}, {
  label: 'Burundi',
  value: 'Burundi',
  image: imagePath('057-burundi'),
  phoneCode: '+257',
  code: 'BI',
  code_3_letter: 'BDI'
}, {
  label: "Côte d'Ivoire",
  value: "Côte d'Ivoire",
  image: imagePath('161-ivory-coast'),
  phoneCode: '+225',
  code: 'CI',
  code_3_letter: 'CIV'
}, {
  label: 'Cabo Verde',
  value: 'Cabo Verde',
  image: imagePath('038-cape-verde'),
  phoneCode: '+238',
  code: 'CV',
  code_3_letter: 'CPV'
}, {
  label: 'Cambodia',
  value: 'Cambodia',
  image: imagePath('159-cambodia'),
  phoneCode: '+855',
  code: 'KH',
  code_3_letter: 'KHM'
}, {
  label: 'Cameroon',
  value: 'Cameroon',
  image: imagePath('105-cameroon'),
  phoneCode: '+237',
  code: 'CM',
  code_3_letter: 'CMR'
}, {
  label: 'Canada',
  value: 'Canada',
  image: imagePath('243-canada'),
  phoneCode: '+1',
  code: 'CA',
  code_3_letter: 'CAN'
}, {
  label: 'Canary Islands',
  value: 'Canary Islands',
  image: imagePath('276-canary-islands'),
  phoneCode: '+34',
  code: 'IC',
  code_3_letter: 'CAN'
}, {
  label: 'Cayman Islands',
  value: 'Cayman Islands',
  image: imagePath('289-cayman-islands'),
  phoneCode: '+1-345',
  code: 'KY',
  code_3_letter: 'CYM'
}, {
  label: 'Central African Republic',
  value: 'Central African Republic',
  image: imagePath('036-central-african-republic'),
  phoneCode: '+236',
  code: 'CF',
  code_3_letter: 'CAF'
}, {
  label: 'Ceuta',
  value: 'Ceuta',
  image: imagePath('277-ceuta'),
  phoneCode: '+34',
  code: 'EA',
  code_3_letter: 'CEU'
}, {
  label: 'Chad',
  value: 'Chad',
  image: imagePath('066-chad'),
  phoneCode: '+235',
  code: 'TD',
  code_3_letter: 'TCD'
}, {
  label: 'Chile',
  value: 'Chile',
  image: imagePath('131-chile'),
  phoneCode: '+56',
  code: 'CL',
  code_3_letter: 'CHL'
}, {
  label: 'Clipperton Island',
  value: 'Clipperton Island',
  image: imagePath('195-france'),
  phoneCode: '+262',
  code: 'CP',
  code_3_letter: 'CPT'
}, {
  label: 'China',
  value: 'China',
  image: imagePath('034-china'),
  phoneCode: '+86',
  code: 'CN',
  code_3_letter: 'CHN'
}, {
  label: 'Channel Islands',
  value: 'Channel Islands',
  image: imagePath('283-channel-islands'),
  phoneCode: '+44',
  code: 'GB',
  code_3_letter: 'CHI'
}, {
  label: 'Colombia',
  value: 'Colombia',
  image: imagePath('177-colombia'),
  phoneCode: '+57',
  code: 'CO',
  code_3_letter: 'COL'
}, {
  label: 'Comoros',
  value: 'Comoros',
  image: imagePath('029-comoros'),
  phoneCode: '+269',
  code: 'KM',
  code_3_letter: 'COM'
}, {
  label: 'Cook Islands',
  value: 'Cook Islands',
  image: imagePath('296-cook-islands'),
  phoneCode: '+682',
  code: 'CK',
  code_3_letter: 'COK'
}, {
  label: 'Republic of the Congo',
  value: 'Republic of the Congo',
  image: imagePath('157-republic-of-the-congo'),
  phoneCode: '+242',
  code: 'CG',
  code_3_letter: 'COG'
}, {
  label: 'Costa Rica',
  value: 'Costa Rica',
  image: imagePath('156-costa-rica'),
  phoneCode: '+506',
  code: 'CR',
  code_3_letter: 'CRI'
}, {
  label: 'Croatia',
  value: 'Croatia',
  image: imagePath('164-croatia'),
  phoneCode: '+385',
  code: 'HR',
  code_3_letter: 'HRV'
}, {
  label: 'Cuba',
  value: 'Cuba',
  image: imagePath('153-cuba'),
  phoneCode: '+53',
  code: 'CU',
  code_3_letter: 'CUB'
}, {
  label: 'Curacao',
  value: 'Curacao',
  image: imagePath('273-curacao'),
  phoneCode: '+5999',
  code: 'CW',
  code_3_letter: 'CUW'
}, {
  label: 'Cyprus',
  value: 'Cyprus',
  image: imagePath('101-northern-cyprus'),
  phoneCode: '+357',
  code: 'CY',
  code_3_letter: 'CYP'
}, {
  label: 'Czech Republic',
  value: 'Czech Republic',
  image: imagePath('149-czech-republic'),
  phoneCode: '+420',
  code: 'CZ',
  code_3_letter: 'CZE'
}, {
  label: 'Democratic Republic of the Congo',
  value: 'Democratic Republic of the Congo',
  image: imagePath('249-democratic-republic-of-congo'),
  phoneCode: '+243',
  code: 'CD',
  code_3_letter: 'COD'
}, {
  label: 'Denmark',
  value: 'Denmark',
  image: imagePath('174-denmark'),
  phoneCode: '+45',
  code: 'DK',
  code_3_letter: 'DNK'
}, {
  label: 'Djibouti',
  value: 'Djibouti',
  image: imagePath('068-djibouti'),
  phoneCode: '+253',
  code: 'DJ',
  code_3_letter: 'DJI'
}, {
  label: 'Dominica',
  value: 'Dominica',
  image: imagePath('186-dominica'),
  phoneCode: '+1 767',
  code: 'DM',
  code_3_letter: 'DMA'
}, {
  label: 'Dominican Republic',
  value: 'Dominican Republic',
  image: imagePath('047-dominican-republic'),
  phoneCode: '+1 809',
  code: 'DO',
  code_3_letter: 'DOM'
}, {
  label: 'Ecuador',
  value: 'Ecuador',
  image: imagePath('104-ecuador'),
  phoneCode: '+593',
  code: 'EC',
  code_3_letter: 'ECU'
}, {
  label: 'Egypt',
  value: 'Egypt',
  image: imagePath('158-egypt'),
  phoneCode: '+20',
  code: 'EG',
  code_3_letter: 'EGY'
}, {
  label: 'El Salvador',
  value: 'El Salvador',
  image: imagePath('015-el-salvador'),
  phoneCode: '+503',
  code: 'SV',
  code_3_letter: 'SLV'
}, {
  label: 'Equatorial Guinea',
  value: 'Equatorial Guinea',
  image: imagePath('189-equatorial-guinea'),
  phoneCode: '+240',
  code: 'GQ',
  code_3_letter: 'GNQ'
}, {
  label: 'Eritrea',
  value: 'Eritrea',
  image: imagePath('065-eritrea'),
  phoneCode: '+291',
  code: 'ER',
  code_3_letter: 'ERI'
}, {
  label: 'Estonia',
  value: 'Estonia',
  image: imagePath('008-estonia'),
  phoneCode: '+372',
  code: 'EE',
  code_3_letter: 'EST'
}, {
  label: 'Eswatini',
  value: 'Eswatini',
  image: imagePath('154-swaziland'),
  phoneCode: '+268',
  code: 'SZ',
  code_3_letter: 'SWZ'
}, {
  label: 'Ethiopia',
  value: 'Ethiopia',
  image: imagePath('005-ethiopia'),
  phoneCode: '+251',
  code: 'ET',
  code_3_letter: 'ETH'
}, {
  label: 'Fiji',
  value: 'Fiji',
  image: imagePath('137-fiji'),
  phoneCode: '+679',
  code: 'FJ',
  code_3_letter: 'FJI'
}, {
  label: 'Finland',
  value: 'Finland',
  image: imagePath('125-finland'),
  phoneCode: '+358',
  code: 'FI',
  code_3_letter: 'FIN'
}, {
  label: 'France',
  value: 'France',
  image: imagePath('195-france'),
  phoneCode: '+33',
  code: 'FR',
  code_3_letter: 'FRA'
}, {
  label: 'French Guiana',
  value: 'French Guiana',
  image: imagePath('269-french-guiana'),
  phoneCode: '+594',
  code: 'GF',
  code_3_letter: 'GUF'
}, {
  label: 'French Polynesia ',
  value: 'French Polynesia',
  image: imagePath('180-french-polynesia'),
  phoneCode: '+689',
  code: 'PF',
  code_3_letter: 'PYF'
}, {
  label: 'French Southern Territories',
  value: 'French Southern Territories',
  image: imagePath('275-french-southern-territories'),
  phoneCode: '+262',
  code: 'TF',
  code_3_letter: 'ATF'
}, {
  label: 'Gabon',
  value: 'Gabon',
  image: imagePath('059-gabon'),
  phoneCode: '+241',
  code: 'GA',
  code_3_letter: 'GAB'
}, {
  label: 'Gambia',
  value: 'Gambia',
  image: imagePath('146-gambia'),
  phoneCode: '+220',
  code: 'GM',
  code_3_letter: 'GMB'
}, {
  label: 'Georgia',
  value: 'Georgia',
  image: imagePath('256-georgia'),
  phoneCode: '+995',
  code: 'GE',
  code_3_letter: 'GEO'
}, {
  label: 'Germany',
  value: 'Germany',
  image: imagePath('162-germany'),
  phoneCode: '+49',
  code: 'DE',
  code_3_letter: 'DEU'
}, {
  label: 'Ghana',
  value: 'Ghana',
  image: imagePath('053-ghana'),
  phoneCode: '+233',
  code: 'GH',
  code_3_letter: 'GHA'
}, {
  label: 'Greece',
  value: 'Greece',
  image: imagePath('170-greece'),
  phoneCode: '+30',
  code: 'GR',
  code_3_letter: 'GRC'
}, {
  label: 'Grenada',
  value: 'Grenada',
  image: imagePath('210-grenada'),
  phoneCode: '+1 473',
  code: 'GD',
  code_3_letter: 'GRD'
}, {
  label: 'Greenland',
  value: 'Greenland',
  image: imagePath('290-greenland'),
  phoneCode: '+299',
  code: 'GL',
  code_3_letter: 'GRL'
}, {
  label: 'Guatemala',
  value: 'Guatemala',
  image: imagePath('098-guatemala'),
  phoneCode: '+502',
  code: 'GT',
  code_3_letter: 'GTM'
}, {
  label: 'Guadeloupe',
  value: 'Guadeloupe',
  image: imagePath('195-france'),
  phoneCode: '+590',
  code: 'GP',
  code_3_letter: 'GLP'
}, {
  label: 'Guinea',
  value: 'Guinea',
  image: imagePath('110-guinea'),
  phoneCode: '+224',
  code: 'GN',
  code_3_letter: 'GIN'
}, {
  label: 'Guinea-Bissau',
  value: 'Guinea-Bissau',
  image: imagePath('056-guinea-bissau'),
  phoneCode: '+245',
  code: 'GW',
  code_3_letter: 'GNB'
}, {
  label: 'Guyana',
  value: 'Guyana',
  image: imagePath('263-guyana'),
  phoneCode: '+592',
  code: 'GY',
  code_3_letter: 'GUY'
}, {
  label: 'Gibraltar',
  value: 'Gibraltar',
  image: imagePath('280-gibraltar'),
  phoneCode: '+350',
  code: 'GI',
  code_3_letter: 'GIB'
}, {
  label: 'Haiti',
  value: 'Haiti',
  image: imagePath('185-haiti'),
  phoneCode: '+509',
  code: 'HT',
  code_3_letter: 'HTI'
}, {
  label: 'Honduras',
  value: 'Honduras',
  image: imagePath('024-honduras'),
  phoneCode: '+504',
  code: 'HN',
  code_3_letter: 'HND'
}, {
  label: 'Hong Kong',
  value: 'Hong Kong',
  image: imagePath('266-hong-kong'),
  phoneCode: '+852',
  code: 'HK',
  code_3_letter: 'HKG'
}, {
  label: 'Hungary',
  value: 'Hungary',
  image: imagePath('115-hungary'),
  phoneCode: '+36',
  code: 'HU',
  code_3_letter: 'HUN'
}, {
  label: 'Iceland',
  value: 'Iceland',
  image: imagePath('080-iceland'),
  phoneCode: '+354',
  code: 'IS',
  code_3_letter: 'ISL'
}, {
  label: 'India',
  value: 'India',
  image: imagePath('246-india'),
  phoneCode: '+91',
  code: 'IN',
  code_3_letter: 'IND'
}, {
  label: 'Indonesia',
  value: 'Indonesia',
  image: imagePath('209-indonesia'),
  phoneCode: '+62',
  code: 'ID',
  code_3_letter: 'IDN'
}, {
  label: 'Papua (Indonesia)',
  value: 'Papua (Indonesia)',
  image: imagePath('304-papua'),
  phoneCode: '+62',
  code: 'ID',
  code_3_letter: 'IDN'
}, {
  label: 'West Papua (Indonesia)',
  value: 'West Papua (Indonesia)',
  image: imagePath('304-papua'),
  phoneCode: '+62',
  code: 'ID',
  code_3_letter: 'IDN'
}, {
  label: 'Iran',
  value: 'Iran',
  image: imagePath('136-iran'),
  phoneCode: '+98',
  code: 'IR',
  code_3_letter: 'IRN'
}, {
  label: 'Iraq',
  value: 'Iraq',
  image: imagePath('020-iraq'),
  phoneCode: '+964',
  code: 'IQ',
  code_3_letter: 'IRQ'
}, {
  label: 'Ireland',
  value: 'Ireland',
  image: imagePath('179-ireland'),
  phoneCode: '+353',
  code: 'IE',
  code_3_letter: 'IRL'
}, {
  label: 'Israel',
  value: 'Israel',
  image: imagePath('155-israel'),
  phoneCode: '+972',
  code: 'IL',
  code_3_letter: 'ISR'
}, {
  label: 'Isle of Man',
  value: 'Isle of Man',
  image: imagePath('281-isle-of-man'),
  phoneCode: '+44',
  code: 'IM',
  code_3_letter: 'IMN'
}, {
  label: 'Italy',
  value: 'Italy',
  image: imagePath('013-italy'),
  phoneCode: '+39',
  code: 'IT',
  code_3_letter: 'ITA'
}, {
  label: 'Jamaica',
  value: 'Jamaica',
  image: imagePath('037-jamaica'),
  phoneCode: '+1 876',
  code: 'JM',
  code_3_letter: 'JAM'
}, {
  label: 'Japan',
  value: 'Japan',
  image: imagePath('063-japan'),
  phoneCode: '+81',
  code: 'JP',
  code_3_letter: 'JPN'
}, {
  label: 'Jordan',
  value: 'Jordan',
  image: imagePath('077-jordan'),
  phoneCode: '+962',
  code: 'JO',
  code_3_letter: 'JOR'
}, {
  label: 'Kazakhstan',
  value: 'Kazakhstan',
  image: imagePath('074-kazakhstan'),
  phoneCode: '+7',
  code: 'KZ',
  code_3_letter: 'KAZ'
}, {
  label: 'Kenya',
  value: 'Kenya',
  image: imagePath('067-kenya'),
  phoneCode: '+254',
  code: 'KE',
  code_3_letter: 'KEN'
}, {
  label: 'Kiribati',
  value: 'Kiribati',
  image: imagePath('261-kiribati'),
  phoneCode: '+686',
  code: 'KI',
  code_3_letter: 'KIR'
}, {
  label: 'Kosovo',
  value: 'Kosovo',
  image: imagePath('270-kosovo'),
  phoneCode: '+383',
  code: 'XK',
  code_3_letter: 'XKX'
}, {
  label: 'Kuwait',
  value: 'Kuwait',
  image: imagePath('107-kwait'),
  phoneCode: '+965',
  code: 'KW',
  code_3_letter: 'KWT'
}, {
  label: 'Kyrgyzstan',
  value: 'Kyrgyzstan',
  image: imagePath('152-kyrgyzstan'),
  phoneCode: '+996',
  code: 'KG',
  code_3_letter: 'KGZ'
}, {
  label: 'Laos',
  value: 'Laos',
  image: imagePath('112-laos'),
  phoneCode: '+856',
  code: 'LA',
  code_3_letter: 'LAO'
}, {
  label: 'Latvia',
  value: 'Latvia',
  image: imagePath('044-latvia'),
  phoneCode: '+371',
  code: 'LV',
  code_3_letter: 'LVA'
}, {
  label: 'Lebanon',
  value: 'Lebanon',
  image: imagePath('018-lebanon'),
  phoneCode: '+961',
  code: 'LB',
  code_3_letter: 'LBN'
}, {
  label: 'Lesotho',
  value: 'Lesotho',
  image: imagePath('176-lesotho'),
  phoneCode: '+266',
  code: 'LS',
  code_3_letter: 'LSO'
}, {
  label: 'Liberia',
  value: 'Liberia',
  image: imagePath('169-liberia'),
  phoneCode: '+231',
  code: 'LR',
  code_3_letter: 'LBR'
}, {
  label: 'Libya',
  value: 'Libya',
  image: imagePath('231-libya'),
  phoneCode: '+218',
  code: 'LY',
  code_3_letter: 'LBY'
}, {
  label: 'Liechtenstein',
  value: 'Liechtenstein',
  image: imagePath('134-liechtenstein'),
  phoneCode: '+423',
  code: 'LI',
  code_3_letter: 'LIE'
}, {
  label: 'Lithuania',
  value: 'Lithuania',
  image: imagePath('064-lithuania'),
  phoneCode: '+370',
  code: 'LT',
  code_3_letter: 'LTU'
}, {
  label: 'Luxembourg',
  value: 'Luxembourg',
  image: imagePath('035-luxembourg'),
  phoneCode: '+352',
  code: 'LU',
  code_3_letter: 'LUX'
}, {
  label: 'Macau',
  value: 'Macau',
  image: imagePath('268-macau'),
  phoneCode: '+853',
  code: 'MO',
  code_3_letter: 'MAC'
}, {
  label: 'Madagascar',
  value: 'Madagascar',
  image: imagePath('242-madagascar'),
  phoneCode: '+261',
  code: 'MG',
  code_3_letter: 'MDG'
}, {
  label: 'Madeira',
  value: 'Madeira',
  image: imagePath('278-madeira'),
  phoneCode: '+351',
  code: 'PT',
  code_3_letter: 'MAD'
}, {
  label: 'Malawi',
  value: 'Malawi',
  image: imagePath('214-malawi'),
  phoneCode: '+265',
  code: 'MW',
  code_3_letter: 'MWI'
}, {
  label: 'Malaysia',
  value: 'Malaysia',
  image: imagePath('118-malasya'),
  phoneCode: '+60',
  code: 'MY',
  code_3_letter: 'MYS'
}, {
  label: 'Maldives',
  value: 'Maldives',
  image: imagePath('225-maldives'),
  phoneCode: '+960',
  code: 'MV',
  code_3_letter: 'MDV'
}, {
  label: 'Mali',
  value: 'Mali',
  image: imagePath('173-mali'),
  phoneCode: '+223',
  code: 'ML',
  code_3_letter: 'MLI'
}, {
  label: 'Malta',
  value: 'Malta',
  image: imagePath('194-malta'),
  phoneCode: '+356',
  code: 'MT',
  code_3_letter: 'MLT'
}, {
  label: 'Marshall Islands',
  value: 'Marshall Islands',
  image: imagePath('103-marshall-island'),
  phoneCode: '+692',
  code: 'MH',
  code_3_letter: 'MHL'
}, {
  label: 'Mauritania',
  value: 'Mauritania',
  image: imagePath('050-mauritania'),
  phoneCode: '+222',
  code: 'MR',
  code_3_letter: 'MRT'
}, {
  label: 'Mauritius',
  value: 'Mauritius',
  image: imagePath('001-mauritius'),
  phoneCode: '+230',
  code: 'MU',
  code_3_letter: 'MUS'
}, {
  label: 'Mayotte',
  value: 'Mayotte',
  image: imagePath('195-france'),
  phoneCode: '+262',
  code: 'YT',
  code_3_letter: 'MYT'
}, {
  label: 'Mexico',
  value: 'Mexico',
  image: imagePath('252-mexico'),
  phoneCode: '+52',
  code: 'MX',
  code_3_letter: 'MEX'
}, {
  label: 'Melilla',
  value: 'Melilla',
  image: imagePath('279-melilla'),
  phoneCode: '+34',
  code: 'EA',
  code_3_letter: 'MEL'
}, {
  label: 'Micronesia',
  value: 'Micronesia',
  image: imagePath('046-micronesia'),
  phoneCode: '+691',
  code: 'FM',
  code_3_letter: 'FSM'
}, {
  label: 'Moldova',
  value: 'Moldova',
  image: imagePath('212-moldova'),
  phoneCode: '+373',
  code: 'MD',
  code_3_letter: 'MDA'
}, {
  label: 'Monaco',
  value: 'Monaco',
  image: imagePath('039-monaco'),
  phoneCode: '+377',
  code: 'MC',
  code_3_letter: 'MCO'
}, {
  label: 'Mongolia',
  value: 'Mongolia',
  image: imagePath('258-mongolia'),
  phoneCode: '+976',
  code: 'MN',
  code_3_letter: 'MNG'
}, {
  label: 'Montenegro',
  value: 'Montenegro',
  image: imagePath('239-montenegro'),
  phoneCode: '+382',
  code: 'ME',
  code_3_letter: 'MNE'
}, {
  label: 'Montserrat',
  value: 'Montserrat',
  image: imagePath('271-montserrat'),
  phoneCode: '+1664',
  code: 'MS',
  code_3_letter: 'MSR'
}, {
  label: 'Morocco',
  value: 'Morocco',
  image: imagePath('166-morocco'),
  phoneCode: '+212',
  code: 'MA',
  code_3_letter: 'MAR'
}, {
  label: 'Mozambique',
  value: 'Mozambique',
  image: imagePath('096-mozambique'),
  phoneCode: '+258',
  code: 'MZ',
  code_3_letter: 'MOZ'
}, {
  label: 'Myanmar',
  value: 'Myanmar',
  image: imagePath('058-myanmar'),
  phoneCode: '+95',
  code: 'MM',
  code_3_letter: 'MMR'
}, {
  label: 'Martinique',
  value: 'Martinique',
  image: imagePath('291-martinique'),
  phoneCode: '+596',
  code: 'MQ',
  code_3_letter: 'MTQ'
}, {
  label: 'Namibia',
  value: 'Namibia',
  image: imagePath('062-namibia'),
  phoneCode: '+264',
  code: 'NA',
  code_3_letter: 'NAM'
}, {
  label: 'Nauru',
  value: 'Nauru',
  image: imagePath('228-nauru'),
  phoneCode: '+674',
  code: 'NR',
  code_3_letter: 'NRU'
}, {
  label: 'Nepal',
  value: 'Nepal',
  image: imagePath('016-nepal'),
  phoneCode: '+977',
  code: 'NP',
  code_3_letter: 'NPL'
}, {
  label: 'Netherlands',
  value: 'Netherlands',
  image: imagePath('237-netherlands'),
  phoneCode: '+31',
  code: 'NL',
  code_3_letter: 'NLD'
}, {
  label: 'New Zealand',
  value: 'New Zealand',
  image: imagePath('121-new-zealand'),
  phoneCode: '+64',
  code: 'NZ',
  code_3_letter: 'NZL'
}, {
  label: 'New Caledonia',
  value: 'New Caledonia',
  image: imagePath('265-new-caledonia'),
  phoneCode: '+687',
  code: 'NC',
  code_3_letter: 'NCL'
}, {
  label: 'Nicaragua',
  value: 'Nicaragua',
  image: imagePath('007-nicaragua'),
  phoneCode: '+505',
  code: 'NI',
  code_3_letter: 'NIC'
}, {
  label: 'Niue',
  value: 'Niue',
  image: imagePath('297-niue'),
  phoneCode: '+683',
  code: 'NU',
  code_3_letter: 'NIU'
}, {
  label: 'Niger',
  value: 'Niger',
  image: imagePath('222-niger'),
  phoneCode: '+227',
  code: 'NE',
  code_3_letter: 'NER'
}, {
  label: 'Nigeria',
  value: 'Nigeria',
  image: imagePath('086-nigeria'),
  phoneCode: '+234',
  code: 'NG',
  code_3_letter: 'NGA'
}, {
  label: 'Norfolk Island',
  value: 'Norfolk Island',
  image: imagePath('298-norfolk-island'),
  phoneCode: '+672',
  code: 'NF',
  code_3_letter: 'NFK'
}, {
  label: 'North Macedonia',
  value: 'North Macedonia',
  image: imagePath('236-republic-of-macedonia'),
  phoneCode: '+389',
  code: 'MK',
  code_3_letter: 'MKD'
}, {
  label: 'Norway',
  value: 'Norway',
  image: imagePath('143-norway'),
  phoneCode: '+47',
  code: 'NO',
  code_3_letter: 'NOR'
}, {
  label: 'Nueva Esparta',
  value: 'Nueva Esparta',
  image: imagePath('285-nueva-esparta'),
  phoneCode: '+58',
  code: 'VE',
  code_3_letter: 'VEN'
}, {
  label: 'Faroe Islands',
  value: 'Faroe Islands',
  image: imagePath('282-faroe-islands'),
  phoneCode: '+298',
  code: 'FO',
  code_3_letter: 'FRO'
}, {
  label: 'Falkland Islands',
  value: 'Falkland Islands',
  image: imagePath('284-falkland-islands'),
  phoneCode: '+500',
  code: 'FK',
  code_3_letter: 'FLK'
}, {
  label: 'Oman',
  value: 'Oman',
  image: imagePath('004-oman'),
  phoneCode: '+968',
  code: 'OM',
  code_3_letter: 'OMN'
}, {
  label: 'Pakistan',
  value: 'Pakistan',
  image: imagePath('100-pakistan'),
  phoneCode: '+92',
  code: 'PK',
  code_3_letter: 'PAK'
}, {
  label: 'Palau',
  value: 'Palau',
  image: imagePath('178-palau'),
  phoneCode: '+680',
  code: 'PW',
  code_3_letter: 'PLW'
}, {
  label: 'Palestine',
  value: 'Palestine',
  image: imagePath('208-palestine'),
  phoneCode: '+970',
  code: 'PS',
  code_3_letter: 'PSE'
}, {
  label: 'Panama',
  value: 'Panama',
  image: imagePath('106-panama'),
  phoneCode: '+507',
  code: 'PA',
  code_3_letter: 'PAN'
}, {
  label: 'Papua New Guinea',
  value: 'Papua New Guinea',
  image: imagePath('163-papua-new-guinea'),
  phoneCode: '+675',
  code: 'PG',
  code_3_letter: 'PNG'
}, {
  label: 'Paraguay',
  value: 'Paraguay',
  image: imagePath('041-paraguay'),
  phoneCode: '+595',
  code: 'PY',
  code_3_letter: 'PRY'
}, {
  label: 'Peru',
  value: 'Peru',
  image: imagePath('188-peru'),
  phoneCode: '+51',
  code: 'PE',
  code_3_letter: 'PER'
}, {
  label: 'Pelagie Islands',
  value: 'Pelagie Islands',
  image: imagePath('013-italy'),
  phoneCode: '+39',
  code: 'IT',
  code_3_letter: 'PEL'
}, {
  label: 'Philippines',
  value: 'Philippines',
  image: imagePath('192-philippines'),
  phoneCode: '+63',
  code: 'PH',
  code_3_letter: 'PHL'
}, {
  label: 'Pitcairn',
  value: 'Pitcairn',
  image: imagePath('300-pitcairn-islands'),
  phoneCode: '+64',
  code: 'PN',
  code_3_letter: 'PCN'
}, {
  label: 'Plazas de Soberania',
  value: 'Plazas de Soberania',
  image: imagePath('128-spain'),
  phoneCode: '+34',
  code: 'EA',
  code_3_letter: 'PLZ'
}, {
  label: 'Easter Island',
  value: 'Easter Island',
  image: imagePath('302-easter-island'),
  phoneCode: '+56',
  code: 'CL',
  code_3_letter: 'CHL'
}, {
  label: 'Poland',
  value: 'Poland',
  image: imagePath('211-poland'),
  phoneCode: '+48',
  code: 'PL',
  code_3_letter: 'POL'
}, {
  label: 'Portugal',
  value: 'Portugal',
  image: imagePath('224-portugal'),
  phoneCode: '+351',
  code: 'PT',
  code_3_letter: 'PRT'
}, {
  label: 'Qatar',
  value: 'Qatar',
  image: imagePath('026-qatar'),
  phoneCode: '+974',
  code: 'QA',
  code_3_letter: 'QAT'
}, {
  label: 'Reunion',
  value: 'Reunion',
  image: imagePath('195-france'),
  phoneCode: '+262',
  code: 'RE',
  code_3_letter: 'REU'
}, {
  label: 'Romania',
  value: 'Romania',
  image: imagePath('109-romania'),
  phoneCode: '+40',
  code: 'RO',
  code_3_letter: 'ROU'
}, {
  label: 'Russia',
  value: 'Russia',
  image: imagePath('248-russia'),
  phoneCode: '+7',
  code: 'RU',
  code_3_letter: 'RUS'
}, {
  label: 'Rwanda',
  value: 'Rwanda',
  image: imagePath('206-rwanda'),
  phoneCode: '+250',
  code: 'RW',
  code_3_letter: 'RWA'
}, {
  label: 'Saint Kitts and Nevis',
  value: 'Saint Kitts and Nevis',
  image: imagePath('033-saint-kitts-and-nevis'),
  phoneCode: '+1 869',
  code: 'KN',
  code_3_letter: 'KNA'
}, {
  label: 'Saint Martin',
  value: 'Saint Martin',
  image: imagePath('195-france'),
  phoneCode: '+590',
  code: 'MF',
  code_3_letter: 'MAF'
}, {
  label: 'Saint Pierre and Miquelon',
  value: 'Saint Pierre and Miquelon',
  image: imagePath('195-france'),
  phoneCode: '+508',
  code: 'PM',
  code_3_letter: 'SPM'
}, {
  label: 'Saint Barthelemy',
  value: 'Saint Barthelemy',
  image: imagePath('195-france'),
  phoneCode: '+590',
  code: 'BL',
  code_3_letter: 'BLM'
}, {
  label: 'Saint Lucia',
  value: 'Saint Lucia',
  image: imagePath('172-st-lucia'),
  phoneCode: '+1 758',
  code: 'LC',
  code_3_letter: 'LCA'
}, {
  label: 'Sint Maarten',
  value: 'Sint Maarten',
  image: imagePath('274-st-maarten'),
  phoneCode: '+1721',
  code: 'SX',
  code_3_letter: 'SXM'
}, {
  label: 'Saint Vincent and the Grenadines',
  value: 'Saint Vincent and the Grenadines',
  image: imagePath('241-st-vincent-and-the-grenadines'),
  phoneCode: '+1 784',
  code: 'VC',
  code_3_letter: 'VCT'
}, {
  label: 'Saint Helena, Ascension and Tristan da Cunha',
  value: 'Saint Helena, Ascension and Tristan da Cunha',
  image: imagePath('260-united-kingdom'),
  phoneCode: '+290',
  code: 'SH',
  code_3_letter: 'SHN'
}, {
  label: 'Saba',
  value: 'Saba',
  image: imagePath('294-saba'),
  phoneCode: '+599',
  code: 'BQ',
  code_3_letter: 'BES'
}, {
  label: 'Samoa',
  value: 'Samoa',
  image: imagePath('251-samoa'),
  phoneCode: '+685',
  code: 'WS',
  code_3_letter: 'WSM'
}, {
  label: 'San Marino',
  value: 'San Marino',
  image: imagePath('097-san-marino'),
  phoneCode: '+378',
  code: 'SM',
  code_3_letter: 'SMR'
}, {
  label: 'San Andrés and Providencia',
  value: 'San Andrés and Providencia',
  image: imagePath('306-san-andres-and-providencia'),
  phoneCode: '+57',
  code: 'CO',
  code_3_letter: 'COL'
}, {
  label: 'Sao Tome and Principe',
  value: 'Sao Tome and Principe',
  image: imagePath('012-sao-tome-and-prince'),
  phoneCode: '+239',
  code: 'ST',
  code_3_letter: 'STP'
}, {
  label: 'Saudi Arabia',
  value: 'Saudi Arabia',
  image: imagePath('133-saudi-arabia'),
  phoneCode: '+966',
  code: 'SA',
  code_3_letter: 'SAU'
}, {
  label: 'Senegal',
  value: 'Senegal',
  image: imagePath('227-senegal'),
  phoneCode: '+221',
  code: 'SN',
  code_3_letter: 'SEN'
}, {
  label: 'Serbia',
  value: 'Serbia',
  image: imagePath('071-serbia'),
  phoneCode: '+381',
  code: 'RS',
  code_3_letter: 'SRB'
}, {
  label: 'Seychelles',
  value: 'Seychelles',
  image: imagePath('253-seychelles'),
  phoneCode: '+248',
  code: 'SC',
  code_3_letter: 'SYC'
}, {
  label: 'Sierra Leone',
  value: 'Sierra Leone',
  image: imagePath('092-sierra-leone'),
  phoneCode: '+232',
  code: 'SL',
  code_3_letter: 'SLE'
}, {
  label: 'Singapore',
  value: 'Singapore',
  image: imagePath('230-singapore'),
  phoneCode: '+65',
  code: 'SG',
  code_3_letter: 'SGP'
}, {
  label: 'Sint Eustatius',
  value: 'Sint Eustatius',
  image: imagePath('295-sint-eustatius'),
  phoneCode: '+599',
  code: 'BQ',
  code_3_letter: 'BES'
}, {
  label: 'Slovakia',
  value: 'Slovakia',
  image: imagePath('091-slovakia'),
  phoneCode: '+421',
  code: 'SK',
  code_3_letter: 'SVK'
}, {
  label: 'Slovenia',
  value: 'Slovenia',
  image: imagePath('010-slovenia'),
  phoneCode: '+386',
  code: 'SI',
  code_3_letter: 'SVN'
}, {
  label: 'Solomon Islands',
  value: 'Solomon Islands',
  image: imagePath('085-solomon-islands'),
  phoneCode: '+677',
  code: 'SB',
  code_3_letter: 'SLB'
}, {
  label: 'Somalia',
  value: 'Somalia',
  image: imagePath('083-somalia'),
  phoneCode: '+252',
  code: 'SO',
  code_3_letter: 'SOM'
}, {
  label: 'South Africa',
  value: 'South Africa',
  image: imagePath('200-south-africa'),
  phoneCode: '+27',
  code: 'ZA',
  code_3_letter: 'ZAF'
}, {
  label: 'South Korea',
  value: 'South Korea',
  image: imagePath('094-south-korea'),
  phoneCode: '+82',
  code: 'KR',
  code_3_letter: 'KOR'
}, {
  label: 'South Sudan',
  value: 'South Sudan',
  image: imagePath('244-south-sudan'),
  phoneCode: '+211',
  code: 'SS',
  code_3_letter: 'SSD'
}, {
  label: 'South Georgia and the South Sandwich Islands',
  value: 'South Georgia and the South Sandwich Islands',
  image: imagePath('286-south-sandwich-islands'),
  phoneCode: '+500',
  code: 'GS',
  code_3_letter: 'SGS'
}, {
  label: 'Galapagos Islands',
  value: 'Galapagos Islands',
  image: imagePath('303-galapagos-islands'),
  phoneCode: '+593',
  code: 'EC',
  code_3_letter: 'ECU'
}, {
  label: 'Socotra Archipelago',
  value: 'Socotra Archipelago',
  image: imagePath('232-yemen'),
  phoneCode: '+967',
  code: 'YE',
  code_3_letter: 'SOC'
}, {
  label: 'Spain',
  value: 'Spain',
  image: imagePath('128-spain'),
  phoneCode: '+34',
  code: 'ES',
  code_3_letter: 'ESP'
}, {
  label: 'Sri Lanka',
  value: 'Sri Lanka',
  image: imagePath('127-sri-lanka'),
  phoneCode: '+94',
  code: 'LK',
  code_3_letter: 'LKA'
}, {
  label: 'Sudan',
  value: 'Sudan',
  image: imagePath('199-sudan'),
  phoneCode: '+249',
  code: 'SD',
  code_3_letter: 'SDN'
}, {
  label: 'Suriname',
  value: 'Suriname',
  image: imagePath('076-suriname'),
  phoneCode: '+597',
  code: 'SR',
  code_3_letter: 'SUR'
}, {
  label: 'Sweden',
  value: 'Sweden',
  image: imagePath('184-sweden'),
  phoneCode: '+46',
  code: 'SE',
  code_3_letter: 'SWE'
}, {
  label: 'Switzerland',
  value: 'Switzerland',
  image: imagePath('205-switzerland'),
  phoneCode: '+41',
  code: 'CH',
  code_3_letter: 'CHE'
}, {
  label: 'Syria',
  value: 'Syria',
  image: imagePath('022-syria'),
  phoneCode: '+963',
  code: 'SY',
  code_3_letter: 'SYR'
}, {
  label: 'Tajikistan',
  value: 'Tajikistan',
  image: imagePath('196-tajikistan'),
  phoneCode: '+992',
  code: 'TJ',
  code_3_letter: 'TJK'
}, {
  label: 'Taiwan',
  value: 'Taiwan',
  image: imagePath('264-taiwan'),
  phoneCode: '+886',
  code: 'TW',
  code_3_letter: 'TWN'
}, {
  label: 'Tanzania',
  value: 'Tanzania',
  image: imagePath('006-tanzania'),
  phoneCode: '+255',
  code: 'TZ',
  code_3_letter: 'TZA'
}, {
  label: 'Thailand',
  value: 'Thailand',
  image: imagePath('238-thailand'),
  phoneCode: '+66',
  code: 'TH',
  code_3_letter: 'THA'
}, {
  label: 'Timor-Leste',
  value: 'Timor-Leste',
  image: imagePath('140-east-timor'),
  phoneCode: '‎+670',
  code: 'TL',
  code_3_letter: 'TLS'
}, {
  label: 'Togo',
  value: 'Togo',
  image: imagePath('073-togo'),
  phoneCode: '+228',
  code: 'TG',
  code_3_letter: 'TGO'
}, {
  label: 'Tokelau',
  value: 'Tokelau',
  image: imagePath('307-tokelau'),
  phoneCode: '+690',
  code: 'TK',
  code_3_letter: 'TKL'
}, {
  label: 'Tonga',
  value: 'Tonga',
  image: imagePath('191-tonga'),
  phoneCode: '+676',
  code: 'TO',
  code_3_letter: 'TON'
}, {
  label: 'Trinidad and Tobago',
  value: 'Trinidad and Tobago',
  image: imagePath('181-trinidad-and-tobago'),
  phoneCode: '+1 868',
  code: 'TT',
  code_3_letter: 'TTO'
}, {
  label: 'Tunisia',
  value: 'Tunisia',
  image: imagePath('049-tunisia'),
  phoneCode: '+216',
  code: 'TN',
  code_3_letter: 'TUN'
}, {
  label: 'Turkey',
  value: 'Turkey',
  image: imagePath('218-turkey'),
  phoneCode: '+90',
  code: 'TR',
  code_3_letter: 'TUR'
}, {
  label: 'Turks and Caicos Islands',
  value: 'Turks and Caicos Islands',
  image: imagePath('292-turks-and-caicos-islands'),
  phoneCode: '+1-649',
  code: 'TC',
  code_3_letter: 'TCA'
}, {
  label: 'Turkmenistan',
  value: 'Turkmenistan',
  image: imagePath('229-turkmenistan'),
  phoneCode: '+993',
  code: 'TM',
  code_3_letter: 'TKM'
}, {
  label: 'Tuvalu',
  value: 'Tuvalu',
  image: imagePath('221-tuvalu'),
  phoneCode: '+688',
  code: 'TV',
  code_3_letter: 'TUV'
}, {
  label: 'Uganda',
  value: 'Uganda',
  image: imagePath('009-uganda'),
  phoneCode: '+256',
  code: 'UG',
  code_3_letter: 'UGA'
}, {
  label: 'Ukraine',
  value: 'Ukraine',
  image: imagePath('145-ukraine'),
  phoneCode: '+380',
  code: 'UA',
  code_3_letter: 'UKR'
}, {
  label: 'United Arab Emirates',
  value: 'United Arab Emirates',
  image: imagePath('151-united-arab-emirates'),
  phoneCode: '+971',
  code: 'AE',
  code_3_letter: 'ARE'
}, {
  label: 'United Kingdom',
  value: 'United Kingdom',
  image: imagePath('260-united-kingdom'),
  phoneCode: '+44',
  code: 'GB',
  code_3_letter: 'GBR'
},
// {
//   label: 'United States of America',
//   value: 'United States of America',
//   image: imagePath('226-united-states'),
//   phoneCode: '+1',
//   code: 'US',
//  code_3_letter: 'USA',
// },
{
  label: 'Uruguay',
  value: 'Uruguay',
  image: imagePath('088-uruguay'),
  phoneCode: '+598',
  code: 'UY',
  code_3_letter: 'URY'
}, {
  label: 'Uzbekistan',
  value: 'Uzbekistan',
  image: imagePath('190-uzbekistn'),
  phoneCode: '+998',
  code: 'UZ',
  code_3_letter: 'UZB'
}, {
  label: 'Vanuatu',
  value: 'Vanuatu',
  image: imagePath('187-vanuatu'),
  phoneCode: '+678',
  code: 'VU',
  code_3_letter: 'VUT'
}, {
  label: 'Venezuela',
  value: 'Venezuela',
  image: imagePath('139-venezuela'),
  phoneCode: '+58',
  code: 'VE',
  code_3_letter: 'VEN'
}, {
  label: 'Vietnam',
  value: 'Vietnam',
  image: imagePath('220-vietnam'),
  phoneCode: '+84',
  code: 'VN',
  code_3_letter: 'VNM'
}, {
  label: 'Virgin Islands',
  value: 'Virgin Islands',
  image: imagePath('293-virgin-islands'),
  phoneCode: '+1-284',
  code: 'VG',
  code_3_letter: 'VGB'
}, {
  label: 'Yemen',
  value: 'Yemen',
  image: imagePath('232-yemen'),
  phoneCode: '+967',
  code: 'YE',
  code_3_letter: 'YEM'
}, {
  label: 'Zambia',
  value: 'Zambia',
  image: imagePath('032-zambia'),
  phoneCode: '+260',
  code: 'ZM',
  code_3_letter: 'ZMB'
}, {
  label: 'Zimbabwe',
  value: 'Zimbabwe',
  image: imagePath('011-zimbabwe'),
  phoneCode: '+263',
  code: 'ZW',
  code_3_letter: 'ZWE'
}, {
  label: 'Wallis and Futuna',
  value: 'Wallis and Futuna',
  image: imagePath('301-allis-and-futuna'),
  phoneCode: '+681',
  code: 'WF',
  code_3_letter: 'WLF'
}];
export var findCountryImage = function findCountryImage(name) {
  var country = countryList.find(function (el) {
    return el.label === name;
  });
  return country === null || country === void 0 ? void 0 : country.image;
};
export var REGION_NAMES = {
  AFRICA: 'Africa Region',
  ARABIC: 'Arabic Region',
  CHINA: 'China Region',
  EUROPE: 'Europe Region',
  INDIA: 'India Region',
  'LATAM 1': 'Latin America 1 Region',
  'LATAM 2': 'Latin America 2 Region',
  OCEANIA: 'Oceania Region',
  RUSSIAN: 'Russian Region',
  'SOUTH EAST ASIA 2': 'South East Asia Region',
  'MAI LOAN': 'Mai Loan Region'
};
export var getOptionRegions = function getOptionRegions() {
  var keys = Object.keys(REGION_NAMES);
  return keys.map(function (regionValue) {
    return {
      value: regionValue,
      label: REGION_NAMES[regionValue]
    };
  });
};
export var sortedCountryList = countryList.sort(function (a, b) {
  return a.label.localeCompare(b.label);
});
export default sortedCountryList;