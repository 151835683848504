import _orderBy from "lodash/orderBy";
import _isArray from "lodash/isArray";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Button, Icon } from '../../UI';
import { buttonInfo, definition, descriptionHistory, descriptionContainer, infoArrow, buttonContainer, userReasonStyles } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TransactionDescriptions = function TransactionDescriptions(props) {
  var description = props.description,
    reason = props.reason,
    userReason = props.userReason,
    externalTopupHash = props.externalTopupHash;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showDescription = _useState2[0],
    setShowDescription = _useState2[1];
  var theme = useTheme();
  var handleReadMore = function handleReadMore() {
    return setShowDescription(true);
  };
  return ___EmotionJSX("div", {
    className: descriptionContainer
  }, externalTopupHash && ___EmotionJSX("p", {
    className: userReasonStyles
  }, "CP Hash:", ___EmotionJSX("span", null, externalTopupHash)), userReason && ___EmotionJSX("p", {
    className: userReasonStyles
  }, "User Reason:", ___EmotionJSX("span", null, userReason)), ___EmotionJSX("p", {
    className: definition
  }, "Explanation:", ___EmotionJSX("span", null, reason)), _isArray(description) && description.length > 0 && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", {
    className: definition
  }, "Description:", ___EmotionJSX("span", null, _orderBy(description, 'createdOn', 'desc')[0].description)), showDescription && ___EmotionJSX("div", {
    className: descriptionHistory
  }, ___EmotionJSX("p", null, "Description history"), ___EmotionJSX("ol", null, description.map(function (el) {
    return ___EmotionJSX("li", {
      key: el.createdOn
    }, el.description);
  }))), !showDescription && ___EmotionJSX("div", {
    className: buttonContainer
  }, ___EmotionJSX(Button, {
    small: true,
    info: true,
    className: buttonInfo(theme),
    onClick: handleReadMore
  }, "Read More"), ___EmotionJSX(Icon, {
    material: true,
    iconName: "keyboard_arrow_down",
    className: infoArrow(theme)
  }))));
};
TransactionDescriptions.propTypes = {
  description: PropTypes.any,
  reason: PropTypes.string,
  userReason: PropTypes.string,
  externalTopupHash: oneOfType([PropTypes.number, PropTypes.string])
};
export default TransactionDescriptions;