function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isString from "lodash/isString";
import _isBoolean from "lodash/isBoolean";
import _isNumber from "lodash/isNumber";
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/web.timers.js";
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import moment from 'moment';
export default (function () {
  var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'data';
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
  return function (options) {
    var pageIndex = options.pageIndex,
      pageSize = options.pageSize,
      startDate = options.startDate,
      endDate = options.endDate,
      redeemed = options.redeemed;
    var filteredData = _toConsumableArray(data);
    if (startDate && endDate) {
      filteredData = data.filter(function (el) {
        return moment(el.date).isBetween(startDate, endDate);
      });
    }
    if (_isBoolean(redeemed)) {
      filteredData = data.filter(function (el) {
        return el.redeemed === redeemed && el.status !== 'Pending';
      });
    }
    if (_isString(redeemed)) filteredData = data.filter(function (el) {
      return el.status === redeemed;
    });
    if (_isNumber(pageIndex) && _isNumber(pageSize)) {
      filteredData = filteredData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
    }
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve([_defineProperty(_defineProperty({}, key, filteredData), "total", data.length)]);
      }, timeout);
    });
  };
});