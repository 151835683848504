import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _camelCase from "lodash/camelCase";
import _mapKeys from "lodash/mapKeys";
import _pick from "lodash/pick";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import queryString from 'query-string';
import { kycStatus, kycVipStatus } from '../../enums/kyc';
import { validDate, jsonParse, endOfTheDay, startOfTheDay } from '../../utils';
export var getKycModel = function getKycModel(response) {
  var _response$state;
  return response && _objectSpread(_objectSpread(_objectSpread({}, response), {}, {
    state: kycStatus[response === null || response === void 0 || (_response$state = response.state) === null || _response$state === void 0 ? void 0 : _response$state.toLowerCase()],
    expirationDate: validDate(response.expirationDate),
    issuedAtDate: validDate(response.issuedAtDate)
  }, response.profile), {}, {
    dateOfBirth: validDate(response.profile.dateOfBirth)
  }, _mapKeys(jsonParse(response.modifiedProfileValues, {}), function (val, key) {
    return _camelCase(key);
  }));
};
export var getAllKycParams = function getAllKycParams(options) {
  var sort = options.sort,
    type = options.type,
    searchBy = options.searchBy,
    search = options.search,
    state = options.state,
    startCreatedDate = options.startCreatedDate,
    endCreatedDate = options.endCreatedDate,
    vipState = options.vipState,
    country = options.country,
    startBirthDate = options.startBirthDate,
    endBirthDate = options.endBirthDate,
    profileFirstName = options.profileFirstName,
    profileLastName = options.profileLastName;
  var defaultSort = {
    sortField: ['id'],
    sortOrder: ['desc']
  };
  var filterField = [];
  var filterValue = [];
  var createdOnDate = {};
  var statusOptionsValues = _defineProperty(_defineProperty(_defineProperty({}, kycStatus.pending, 'pending'), kycStatus.approved, 'approved'), kycStatus.rejected, 'rejected');
  var vipStateOptionsValues = _defineProperty(_defineProperty(_defineProperty({}, kycVipStatus.regular, {
    isVip: false
  }), kycVipStatus.paid, {
    isVip: true,
    vipState: kycVipStatus.paid
  }), kycVipStatus.unpaid, {
    isVip: true,
    vipState: kycVipStatus.unpaid
  });
  if (statusOptionsValues[state]) {
    filterField.push('state');
    filterValue.push(statusOptionsValues[state]);
  }
  var vipStatus = vipStateOptionsValues[vipState];
  if (vipStatus) {
    Object.keys(vipStatus).forEach(function (key) {
      filterField.push(key);
      filterValue.push(vipStatus[key]);
    });
  }
  if (type) {
    filterField.push('type');
    filterValue.push(type);
  }
  if (country) {
    filterField.push('profileCountry');
    filterValue.push(country);
  }
  if (searchBy && search !== null && search !== void 0 && search.length) {
    filterField.push(searchBy);
    filterValue.push(search);
  }
  if (startBirthDate) {
    filterField.push('profileDateOfBirthFrom');
    filterValue.push(startOfTheDay(startBirthDate).format());
  }
  if (endBirthDate) {
    filterField.push('profileDateOfBirthTo');
    filterValue.push(endOfTheDay(endBirthDate).format());
  }
  if (startCreatedDate) createdOnDate.createdFrom = startOfTheDay(startCreatedDate).format();
  if (endCreatedDate) createdOnDate.createdTo = endOfTheDay(endCreatedDate).format();
  if (profileFirstName !== null && profileFirstName !== void 0 && profileFirstName.length) {
    filterField.push('profileFirstName');
    filterValue.push(profileFirstName);
  }
  if (profileLastName !== null && profileLastName !== void 0 && profileLastName.length) {
    filterField.push('profileLastName');
    filterValue.push(profileLastName);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), sort !== null && sort !== void 0 ? sort : defaultSort), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), createdOnDate), {
    arrayFormat: 'index'
  });
};
export var idProofModel = function idProofModel(data) {
  return _objectSpread(_objectSpread({}, _pick(data, ['documentNumber', 'issuedAtDate', 'expirationDate', 'id'])), {}, {
    profile: {
      dateOfBirth: data === null || data === void 0 ? void 0 : data.dateOfBirth,
      firstName: data === null || data === void 0 ? void 0 : data.firstName,
      lastName: data === null || data === void 0 ? void 0 : data.lastName
    }
  });
};
export var addressProofModel = function addressProofModel(data) {
  return {
    id: data.id,
    profile: _pick(data, ['country', 'zip', 'address'])
  };
};