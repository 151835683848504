import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.date.to-string.js";
import { getWeek } from 'utils';
export var generateYears = function generateYears() {
  var currentYear = new Date().getFullYear();
  var yearOptions = [];
  for (var year = currentYear; year > 2016; year--) {
    yearOptions.push({
      label: year,
      value: year
    });
  }
  return yearOptions;
};
export var generateOptions = function generateOptions(year, weekNumber) {
  var lastWeekNumber = weekNumber || getWeek("12/31/".concat(year));
  var newOptions = [];
  for (var week = lastWeekNumber; week > 0; week--) {
    newOptions.push({
      label: "".concat(year, "-").concat(week),
      value: {
        year: year,
        week: week
      }
    });
  }
  return newOptions;
};