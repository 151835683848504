import _isNil from "lodash/isNil";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Paper, Skeleton } from '../../UI';
import { displayProperty } from '../../../utils/displayData';
import { idProofInfo, addressProofInfo } from './config';
import { paperContainer, overviewColKyc } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var KycDocumentOverview = function KycDocumentOverview(props) {
  var isIdProof = props.isIdProof,
    document = props.document;
  return ___EmotionJSX(Paper, {
    className: paperContainer
  }, ___EmotionJSX(Row, {
    gap: 16
  }, isIdProof && ___EmotionJSX(Col, {
    grow: 0,
    basis: "auto",
    className: overviewColKyc
  }, _isNil(document === null || document === void 0 ? void 0 : document.firstName) || _isNil(document === null || document === void 0 ? void 0 : document.lastName) ? ___EmotionJSX(Skeleton, {
    width: 150,
    height: 34
  }) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h4", null, "Full Name"), ___EmotionJSX("span", null, document === null || document === void 0 ? void 0 : document.firstName, " ", document === null || document === void 0 ? void 0 : document.lastName))), (isIdProof ? idProofInfo : addressProofInfo).map(function (el) {
    return ___EmotionJSX(Col, {
      grow: 0,
      basis: "auto",
      key: el.label,
      className: overviewColKyc
    }, _isNil(el) ? ___EmotionJSX(Skeleton, {
      width: 150,
      height: 34
    }) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h4", null, el.label), ___EmotionJSX("span", null, displayProperty(el, document))));
  })));
};
KycDocumentOverview.propTypes = {
  isIdProof: PropTypes.bool,
  document: PropTypes.object
};
export default KycDocumentOverview;