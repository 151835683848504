function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isFunction from "lodash/isFunction";
import _flattenDeep from "lodash/flattenDeep";
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var validators = {
  required: {
    regExp: /\S/,
    msg: 'Required field'
  },
  email: {
    regExp: /^$|^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/,
    msg: 'Invalid Email'
  },
  username: {
    regExp: /^$|^[A-Za-z0-9_+\-.!#$'^`~@]{1,25}$/,
    msg: 'Invalid username'
  },
  password: {
    regExp: /^$|^.{6,}$/,
    msg: 'Invalid password'
  },
  phone: {
    regExp: /^$|^[0-9]{10}$/,
    msg: 'Invalid phone number'
  },
  postCode: {
    regExp: /^$|^[0-9]{6}$/,
    msg: 'Invalid post code'
  },
  price: {
    // todo: improve this, as it leads to multiple matches and I think its copied from somewhere w/o understanding
    // I guess it should match: positive decimal with exactly TWO decimal places(verify usages)
    regExp: /^[1-9][0-9]{0,}\.[0-9]{1,2}|[1-9][0-9]{0,}$/,
    msg: 'Invalid number'
  },
  pricePositive15WithZero: {
    // positive decimal with up to 15 decimal places
    regExp: /^(0{1}|[1-9]{1}[0-9]{0,})(\.+[0-9]{0,15}){0,1}$/,
    msg: 'Invalid number'
  },
  positiveInt: {
    regExp: /^0|[1-9][0-9]{0,}$/,
    msg: 'Invalid number'
  },
  validHex: {
    regExp: /^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i,
    msg: 'Invalid HEX color'
  },
  positiveIntWithoutZero: {
    regExp: /^[1-9][0-9]{0,}$/,
    msg: 'Invalid number'
  },
  mobilePin: {
    regExp: /^[0-9]{4}$/,
    msg: 'Invalid Mobile PIN!'
  },
  httpUrl: {
    regExp: /^(https?:\/\/)([\da-z.-]+\.[a-z.]{2,6}|[\d.]+)([/:?=&#]{1}[\da-z.-]+)*[/?]?$/,
    msg: 'Invalid URL format'
  }
};
export default (function (value) {
  for (var _len = arguments.length, inputValidators = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    inputValidators[_key - 1] = arguments[_key];
  }
  var setOfValidators = _flattenDeep(inputValidators);
  var _iterator = _createForOfIteratorHelper(setOfValidators),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _validator$name;
      var validator = _step.value;
      var validatorName = (_validator$name = validator === null || validator === void 0 ? void 0 : validator.name) !== null && _validator$name !== void 0 ? _validator$name : validator;
      var render = validator === null || validator === void 0 ? void 0 : validator.render;
      var _validators$validator = validators[validatorName],
        regExp = _validators$validator.regExp,
        msg = _validators$validator.msg;

      // Bypass null/undefined because the regExp api is stupid and if you pass it null/undefined it will convert them to string
      if (!regExp.test(value !== null && value !== void 0 ? value : '')) {
        return _objectSpread({
          msg: msg
        }, _isFunction(render) && {
          render: render
        });
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return null;
});