function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _omit from "lodash/omit";
import _isArray from "lodash/isArray";
import _isNil from "lodash/isNil";
import _omitBy from "lodash/omitBy";
import _isPlainObject from "lodash/isPlainObject";
import _mapValues from "lodash/mapValues";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-json.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import { jsonParse } from '../../../utils';
var dateTimeFormat = 'MM-DD-YYYY';
export var useUrlParams = function useUrlParams() {
  var history = useHistory();
  var _history$location = history.location,
    pathname = _history$location.pathname,
    search = _history$location.search,
    state = _history$location.state;
  var urlQueryParams = _mapValues(qs.parse(search, {
    arrayFormat: 'index'
  }), function (el) {
    if ((/\d{4}-\d{2}-\d{2}/g.test(el) || /\d{4}\/\d{2}\/\d{2}/g.test(el)) && moment(el, dateTimeFormat).isValid()) return moment(el, dateTimeFormat);
    return jsonParse(el, el);
  });
  var setUrlQueryParams = function setUrlQueryParams(params) {
    var mappedParams = _mapValues(_objectSpread(_objectSpread({}, urlQueryParams), params), function (el) {
      switch (true) {
        case _isPlainObject(el):
          return JSON.stringify(el);
        case moment.isMoment(el):
          return el.format(dateTimeFormat);
        case moment.isDate(el):
          return moment(el).format(dateTimeFormat);
        default:
          return el;
      }
    });
    var filteredUrlParams = qs.stringify(_omitBy(mappedParams, _isNil), {
      arrayFormat: 'index'
    });
    history.replace({
      to: pathname,
      state: state,
      search: filteredUrlParams ? "?".concat(filteredUrlParams) : search
    });
  };
  var clearUrlQueryParams = function clearUrlQueryParams(paramsToClear) {
    var search = '';
    if (_isArray(paramsToClear)) search = "?".concat(qs.stringify(_omit(urlQueryParams, paramsToClear)));
    history.replace({
      to: pathname,
      state: state,
      search: search
    });
  };
  return {
    urlQueryParams: urlQueryParams,
    setUrlQueryParams: setUrlQueryParams,
    clearUrlQueryParams: clearUrlQueryParams
  };
};