import _startCase from "lodash/startCase";
import _isString from "lodash/isString";
import _isPlainObject from "lodash/isPlainObject";
import _isFunction from "lodash/isFunction";
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import moment from 'moment';
import { CheckButton } from '../Button';
import { getNestedProperty, validDate } from '../../../utils';
import { tableRow, tableCell, tableCellEmpty, tableCellCheckButton } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NullItem = function NullItem() {
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: tableCellEmpty(theme)
  });
};
export var displayValue = function displayValue(column, row) {
  var value = getNestedProperty(row, column.value.split('.'));
  switch (true) {
    case _isPlainObject(column.mapValue):
      return column.mapValue[value.toLowerCase()];
    case _isString(column.dateTimeFormat):
      return validDate(value) ? moment(value).format(column.dateTimeFormat) : ___EmotionJSX(NullItem, null);
    case column.startCase:
      return _startCase(value);
    default:
      return value !== null && value !== void 0 ? value : ___EmotionJSX(NullItem, null);
  }
};
var TableBody = function TableBody(props) {
  var data = props.data,
    columns = props.columns,
    selectRow = props.selectRow,
    hasSelection = props.hasSelection,
    renderRow = props.renderRow,
    onRowClick = props.onRowClick,
    rowLink = props.rowLink;
  var theme = useTheme();
  var RowComp = _isFunction(rowLink) ? Link : 'div';
  return data.map(function (row, i) {
    var _row$render, _row$_table;
    return _isFunction(row.render) || _isFunction(renderRow) ? ((_row$render = row.render) !== null && _row$render !== void 0 ? _row$render : renderRow)({
      row: row,
      NullItem: NullItem,
      columns: columns,
      rowClassName: tableRow(theme),
      columnClassName: tableCell,
      displayValue: displayValue
    }) : ___EmotionJSX(RowComp, _extends({
      key: "table-row-".concat(i),
      className: tableRow(theme, _isFunction(rowLink) || _isFunction(onRowClick))
    }, _isFunction(rowLink) && rowLink(row), _isFunction(onRowClick) && {
      onClick: function onClick() {
        return onRowClick(row);
      }
    }), hasSelection && ___EmotionJSX("div", {
      role: "presentation",
      className: tableCell({
        flex: '0 0 40px',
        render: true
      }),
      onClick: function onClick(e) {
        return e.stopPropagation();
      }
    }, ___EmotionJSX(CheckButton, {
      onChange: function onChange(value) {
        return selectRow(value, row);
      },
      checked: row === null || row === void 0 || (_row$_table = row._table) === null || _row$_table === void 0 ? void 0 : _row$_table.isSelected,
      className: tableCellCheckButton
    })), columns.map(function (column, j) {
      return ___EmotionJSX("div", {
        key: "table-column".concat(i, "-").concat(j),
        className: tableCell(column)
      }, _isFunction(column.render) ? column === null || column === void 0 ? void 0 : column.render(row, NullItem) : displayValue(column, row));
    }));
  });
};
TableBody.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  selectRow: PropTypes.func,
  hasSelection: PropTypes.bool,
  renderRow: PropTypes.func
};
export default TableBody;