import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import StatusChange from '../../Shared/StatusChange';
import { Paper, Modal, Button } from '../../UI';
import { orderPaymentStatus, orderPaymentLabels, ORDER_PAYMENT_STATUS } from '../../../enums/orders';
import { buttonContainer, descriptionModal } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var OrderStatusChange = function OrderStatusChange(props) {
  var orderId = props.orderId,
    payments = props.payments,
    isLoading = props.isLoading,
    onBankPaymentStatusChange = props.onBankPaymentStatusChange,
    onOrderStatusChange = props.onOrderStatusChange;
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    bankPaymentStatus = _useState2[0],
    setBankPaymentStatus = _useState2[1];
  var _useState3 = useState(null),
    _useState4 = _slicedToArray(_useState3, 2),
    changeStatusOptions = _useState4[0],
    setChangeStatusOptions = _useState4[1];
  var modalRef = useRef();
  useEffect(function () {
    if (payments && payments.length > 0) {
      var bankPayment = payments.find(function (payment) {
        return payment.origin.toLowerCase().includes('bank');
      });
      bankPayment && setBankPaymentStatus(bankPayment.status);
    }
  }, [orderId, payments]);
  var handleOpenConfirmationModal = function handleOpenConfirmationModal(options) {
    setChangeStatusOptions(options);
    modalRef.current.open();
  };
  var handleCloseConfirmationModal = function handleCloseConfirmationModal() {
    return modalRef.current.close();
  };
  var handleOrderStatusChange = function handleOrderStatusChange() {
    handleCloseConfirmationModal();
    onOrderStatusChange(changeStatusOptions);
  };
  var getWarningMessage = function getWarningMessage() {
    var warningMessage = "Are you sure you want to ".concat(((changeStatusOptions === null || changeStatusOptions === void 0 ? void 0 : changeStatusOptions.status) || '').toLowerCase(), " the order?");
    var pendingPayments = (payments || []).filter(function (payment) {
      return payment.status === orderPaymentStatus.Pending;
    });
    if (pendingPayments && pendingPayments.length > 0) {
      var pendingPaymentsTypes = pendingPayments.map(function (payment) {
        return orderPaymentLabels[payment.origin];
      });
      warningMessage = pendingPaymentsTypes.length > 1 ? "".concat(warningMessage, " There are still pending transactions for ").concat(pendingPaymentsTypes.join(' and '), " payments.") : "".concat(warningMessage, " There is still a pending transaction for ").concat(pendingPaymentsTypes[0], " payment.");
    }
    return warningMessage;
  };
  var getOrderStatusChangeDescription = function getOrderStatusChangeDescription() {
    var pending = [];
    var confirmed = [];
    var denied = [];
    payments === null || payments === void 0 || payments.forEach(function (payment) {
      if (payment.status === ORDER_PAYMENT_STATUS.PENDING) {
        pending.push(orderPaymentLabels[payment.origin]);
      } else if (payment.status === ORDER_PAYMENT_STATUS.CONFIRMED) {
        confirmed.push(orderPaymentLabels[payment.origin]);
      } else {
        denied.push(orderPaymentLabels[payment.origin]);
      }
    });
    var descriptionParts = [];
    confirmed.length > 0 && descriptionParts.push("the ".concat(confirmed.join(' and the'), " ").concat(confirmed.length > 1 ? 'Payments are' : 'Payment is', " confirmed"));
    pending.length > 0 && descriptionParts.push("the ".concat(pending.join(' and the'), " ").concat(pending.length > 1 ? 'Payments are' : 'Payment is', " still in progress"));
    denied.length > 0 && descriptionParts.push("the ".concat(denied.join(' and the'), " ").concat(denied.length > 1 ? 'Payments are' : 'Payment is', " denied"));
    var description = pending.length > 0 || denied.length > 0 ? "For this order ".concat(descriptionParts.join(', but '), "!") : 'All payments are confirmed!';
    return description;
  };
  return ___EmotionJSX(React.Fragment, null, bankPaymentStatus === orderPaymentStatus.Pending ? ___EmotionJSX(Paper, {
    header: "Approve/Reject Bank Payment"
  }, ___EmotionJSX(StatusChange, {
    id: orderId,
    onChange: onBankPaymentStatusChange,
    isLoading: isLoading,
    description: "You should update the status of the Bank Payment for this order."
  })) : ___EmotionJSX(Paper, {
    header: "Approve/Reject Order"
  }, ___EmotionJSX(StatusChange, {
    id: orderId,
    onChange: handleOpenConfirmationModal,
    isLoading: isLoading,
    description: getOrderStatusChangeDescription()
  })), ___EmotionJSX(Modal, {
    ref: modalRef,
    modalTitle: "".concat(changeStatusOptions === null || changeStatusOptions === void 0 ? void 0 : changeStatusOptions.status, " Order Confirmation")
  }, ___EmotionJSX("p", {
    className: descriptionModal
  }, getWarningMessage()), ___EmotionJSX("div", {
    className: buttonContainer
  }, ___EmotionJSX(Button, {
    onClick: handleOrderStatusChange
  }, changeStatusOptions === null || changeStatusOptions === void 0 ? void 0 : changeStatusOptions.status, " Order"), ___EmotionJSX(Button, {
    onClick: handleCloseConfirmationModal,
    secondary: true
  }, "Cancel"))));
};
OrderStatusChange.propTypes = {
  orderId: PropTypes.number,
  payments: PropTypes.array,
  isLoading: PropTypes.bool,
  onBankPaymentStatusChange: PropTypes.func,
  onOrderStatusChange: PropTypes.func
};
export default OrderStatusChange;