import _isNil from "lodash/isNil";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Paper, Row, Col, Breadcrumbs, Skeleton, Button } from '../../../../components/UI';
import BackButton from '../../../../components/Shared/BackButton';
import ColorBatch from '../../../../components/Shared/ColorBatch';
import { displayProperty } from '../../../../utils/displayData';
import { generalInfo, detailPageBreadcrumbs } from './config';
import { tabContainer, containerBack } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DetailPagePaymentMethod = function DetailPagePaymentMethod() {
  var location = useLocation();
  var paymentMethod = location.state;
  return ___EmotionJSX("div", {
    className: tabContainer
  }, ___EmotionJSX(Breadcrumbs, {
    items: detailPageBreadcrumbs
  }), ___EmotionJSX("div", {
    className: containerBack
  }, ___EmotionJSX(BackButton, {
    title: "Payment Method Information"
  }), ___EmotionJSX(Button, {
    large: true,
    linkTo: {
      pathname: "/settings/payment-methods/".concat(paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.id, "/edit"),
      state: paymentMethod
    }
  }, "Edit Payment Method")), ___EmotionJSX(Row, {
    gap: 16
  }, ___EmotionJSX(Col, {
    xl: 6,
    md: 12
  }, ___EmotionJSX(Paper, {
    header: "General info"
  }, generalInfo.map(function (el) {
    return ___EmotionJSX(Row, {
      key: el.label,
      md: 6,
      margin: "0 0 16px"
    }, ___EmotionJSX(Col, null, ___EmotionJSX("h4", null, el.label)), ___EmotionJSX(Col, null, _isNil(paymentMethod) ? ___EmotionJSX(Skeleton, {
      width: 120,
      height: 18
    }) : ___EmotionJSX("span", null, displayProperty(el, paymentMethod))));
  }), ___EmotionJSX(Row, {
    md: 6,
    align: "center",
    margin: "0 0 16px"
  }, ___EmotionJSX(Col, null, ___EmotionJSX("h4", null, "Status")), ___EmotionJSX(Col, null, _isNil(paymentMethod) ? ___EmotionJSX(Skeleton, {
    width: 120,
    height: 18
  }) : ___EmotionJSX(ColorBatch, {
    type: paymentMethod.active ? 'success' : 'error'
  }, paymentMethod.active ? 'Active' : 'Inactive'))), ___EmotionJSX(Row, {
    md: 6,
    align: "center",
    margin: "0 0 16px"
  }, ___EmotionJSX(Col, null, ___EmotionJSX("h4", null, "Visibility")), ___EmotionJSX(Col, null, _isNil(paymentMethod) ? ___EmotionJSX(Skeleton, {
    width: 120,
    height: 18
  }) : ___EmotionJSX(ColorBatch, {
    type: paymentMethod.visible ? 'success' : 'error'
  }, paymentMethod.visible ? 'Visible' : 'Invisible'))), ___EmotionJSX(Row, {
    md: 6,
    align: "center",
    margin: "0 0 16px"
  }, ___EmotionJSX(Col, null, ___EmotionJSX("h4", null, "Is in Promo")), ___EmotionJSX(Col, null, _isNil(paymentMethod) ? ___EmotionJSX(Skeleton, {
    width: 120,
    height: 18
  }) : ___EmotionJSX(ColorBatch, {
    type: paymentMethod.isInPromo ? 'success' : 'error'
  }, paymentMethod.isInPromo ? 'Yes' : 'No')))))));
};
export default DetailPagePaymentMethod;