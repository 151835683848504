import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _omit from "lodash/omit";
import _isNil from "lodash/isNil";
import _pick from "lodash/pick";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import queryString from 'query-string';
import avatar from '../../assets/images/package.png';
export var getPackageModel = function getPackageModel(response) {
  var _response$pictureUrl, _response$picThumbnai;
  return response && _objectSpread(_objectSpread({}, response), {}, {
    pictureUrl: (_response$pictureUrl = response.pictureUrl) !== null && _response$pictureUrl !== void 0 ? _response$pictureUrl : avatar,
    picThumbnailUrl: (_response$picThumbnai = response.picThumbnailUrl) !== null && _response$picThumbnai !== void 0 ? _response$picThumbnai : avatar
  });
};
export var editPackageBody = function editPackageBody(body) {
  var keysToOmit = ['lastModified', 'modifiedBy', 'modifiedOn', 'catalogueItemId', 'createdBy', 'createdOn', 'rowVersion', 'systemName'];
  return _omit(body, keysToOmit);
};
export var getAllPackagesParams = function getAllPackagesParams(options) {
  var _options$name;
  var defaultSort = {
    sortField: 'id',
    sortOrder: 'desc'
  };
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField === 'catalogueItemId' ? 'id' : sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  var filterField = [];
  var filterValue = [];
  if (!_isNil(options.active)) {
    filterField.push('active');
    filterValue.push(options.active);
  }
  if (!_isNil(options.visible)) {
    filterField.push('visible');
    filterValue.push(options.visible);
  }
  if (!_isNil(options.type)) {
    filterField.push('type');
    filterValue.push(options.type);
  }
  if ((_options$name = options.name) !== null && _options$name !== void 0 && _options$name.length) {
    filterField.push('name');
    filterValue.push(options.name);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), options.sort ? mapSort(options.sort) : defaultSort), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};