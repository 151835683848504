import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
/* eslint-disable no-bitwise */
export default (function () {
  var pattern = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
  return pattern.replace(/x/g, function () {
    return (Math.random() * 16 | 0).toString(16);
  });
});