function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Paper, Icon, Button } from '../../UI';
import GroupOfButtons from '../GroupOfButtons';
import { type as walletType } from '../../../enums/wallets';
import { walletContainer, headerWallet, walletIcon, balanceContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var walletTypeView = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, walletType.CASH, {
  title: 'Cash Wallet',
  icon: 'fas fa-euro-sign'
}), walletType.RESERVECASH, {
  title: 'Reserve Cash Wallet',
  icon: 'fas fa-euro-sign'
}), walletType.ONE, {
  title: 'ONE Wallet',
  icon: 'fas fa-coins'
}), walletType.COINLEGACY, {
  title: 'ONE (deprecated) Wallet',
  icon: 'fas fa-coins'
}), walletType.GAS, {
  title: 'Gas Wallet',
  icon: 'fab fa-gripfire'
}), walletType.OESP, {
  title: 'OESP Wallet',
  icon: 'fas fa-coins'
}), walletType.TRADING, {
  title: 'Trading Wallet',
  icon: 'fas fa-exchange-alt'
}), walletType.TOKEN, {
  title: 'Token Wallet',
  icon: 'fab fa-gg'
});
var HeaderWallet = function HeaderWallet(props) {
  var _walletTypeView$walle, _walletTypeView$walle2, _wallet$availableSpli;
  var wallet = props.wallet,
    onChange = props.onChange,
    userId = props.userId;
  var theme = useTheme();
  return ___EmotionJSX(Paper, {
    className: walletContainer(theme),
    noPadding: true
  }, ___EmotionJSX("div", {
    className: headerWallet(theme)
  }, ___EmotionJSX("div", null, ___EmotionJSX(Icon, {
    iconName: (_walletTypeView$walle = walletTypeView[wallet.walletType]) === null || _walletTypeView$walle === void 0 ? void 0 : _walletTypeView$walle.icon,
    className: walletIcon(theme)
  }), ___EmotionJSX("h3", null, (_walletTypeView$walle2 = walletTypeView[wallet.walletType]) === null || _walletTypeView$walle2 === void 0 ? void 0 : _walletTypeView$walle2.title), wallet.id && wallet.walletType !== walletType.COINLEGACY && ___EmotionJSX(GroupOfButtons, {
    wallet: wallet,
    onChange: onChange
  })), ___EmotionJSX("div", {
    className: balanceContainer(theme)
  }, ___EmotionJSX("h4", null, wallet.balance === undefined ? 'Balance: N/A' : "Balance: ".concat(wallet.balance)), ___EmotionJSX("h4", null, wallet.blockedBalance === undefined ? 'Blocked Balance: N/A' : "Blocked Balance: ".concat(wallet.blockedBalance)), wallet.walletType === walletType.TOKEN && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h4", null, wallet.maxSplits ? "Splits: ".concat(wallet.availableSplits, " (").concat(wallet.maxSplits, ")") : "Splits: ".concat((_wallet$availableSpli = wallet.availableSplits) !== null && _wallet$availableSpli !== void 0 ? _wallet$availableSpli : 0, " ")), ___EmotionJSX(Button, {
    linkTo: "/profiles/profile/".concat(userId, "/accounts/").concat(wallet.accountId, "/split-balance-history"),
    small: true,
    outlineInverse: true
  }, "Split History")))));
};
HeaderWallet.propTypes = {
  wallet: PropTypes.object,
  onChange: PropTypes.func,
  userId: PropTypes.string
};
export default HeaderWallet;