import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import { DetailsLink } from '../../../../components/Shared/TableComponents';
import ColorBatch from '../../../../components/Shared/ColorBatch';
import { currencyByType } from '../../../../utils';
import { type } from '../../../../enums/wallets';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var generalInfo = [{
  label: 'Price',
  value: 'price'
}, {
  label: 'Currency',
  value: 'currency'
}, {
  label: 'Duration',
  value: 'duration'
}, {
  label: 'Business Volume',
  value: 'businessVolume'
}, {
  label: 'Plan active',
  value: 'active'
}];
export var groupedSubscriptionListColumns = [{
  name: 'Catalogue Item Id',
  value: 'id',
  width: 150
}, {
  name: 'Vendor',
  render: function render(row) {
    return row.subscriptionPlans && row.subscriptionPlans.length > 0 && row.subscriptionPlans[0].vendor;
  }
}, {
  name: 'Catalogue Item Status',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.active ? 'success' : 'error'
    }, row.active ? 'Active' : 'Inactive');
  },
  width: 150
}, {
  name: 'Catalogue Item Visible',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.visible ? 'success' : 'error'
    }, row.visible ? 'Visible' : 'Invisible');
  },
  width: 150
}, {
  name: 'Subscription plans',
  render: function render(row) {
    return (row.subscriptionPlans || []).map(function (p) {
      return p.name;
    }).join(', ');
  }
}, {
  name: 'View details',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/subscriptions/subscription/".concat(row.subscriptionCatalogueItemId)
    });
  },
  width: 150
}];
export var subscriptionsListColumns = [{
  name: 'Vendor',
  value: 'vendor'
}, {
  name: 'Name',
  value: 'name'
}, {
  name: 'Price',
  value: 'price',
  render: function render(row) {
    return currencyByType(row.price, type.CASH);
  },
  sortable: true
}, {
  name: 'Duration',
  value: 'duration',
  render: function render(row) {
    return "".concat(row.duration, " months");
  }
}, {
  name: 'Plan Status',
  value: 'active',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.planActive ? 'success' : 'error'
    }, row.planActive ? 'Plan Active' : 'Plan Inactive');
  }
}, {
  name: 'Catalogue Item Status',
  value: 'active',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.active ? 'success' : 'error'
    }, row.active ? 'Active' : 'Inactive');
  }
}, {
  name: 'Catalogue Item Visible',
  value: 'visible',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.visible ? 'success' : 'error'
    }, row.visible ? 'Visible' : 'Invisible');
  }
}, {
  name: 'View details',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/subscriptions/subscription/".concat(row.subscriptionCatalogueItemId)
    });
  },
  width: 150
}];
export var detailPageBreadcrumbs = [{
  url: '/subscriptions/all-subscriptions',
  label: 'Subscriptions'
}, {
  label: 'Detail Page'
}];
export var addPageBreadcrumbs = [{
  url: '/subscriptions/all-subscriptionss',
  label: 'Subscriptions'
}, {
  label: 'Add Subscription'
}];
export var editPageBreadcrumbs = function editPageBreadcrumbs(subscriptionCatalogueItemId) {
  return [{
    url: '/subscriptions/all-subscriptions',
    label: 'Subscriptions'
  }, {
    url: "/subscriptions/subscription/".concat(subscriptionCatalogueItemId),
    label: 'Detail Page'
  }, {
    label: 'Edit Subscription Plans'
  }];
};