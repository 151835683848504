import "core-js/modules/es.array.every.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { containerClass, linkItem, separator, text } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Breadcrumbs = function Breadcrumbs(props) {
  var items = props.items,
    className = props.className;
  var theme = useTheme();
  if (!items.every(function (el) {
    return el.label;
  })) return null;
  return ___EmotionJSX("ul", {
    className: containerClass(className)
  }, items.map(function (el, index, arr) {
    var isNotLastElement = index !== arr.length - 1;
    return ___EmotionJSX("li", {
      key: index
    }, el.url && isNotLastElement ? ___EmotionJSX(Link, {
      to: el.url,
      className: linkItem(theme, isNotLastElement)
    }, el.label) : ___EmotionJSX("span", {
      className: text(theme)
    }, el.label), isNotLastElement ? ___EmotionJSX("span", {
      className: separator(theme)
    }, "/") : '');
  }));
};
Breadcrumbs.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string
};
export default Breadcrumbs;