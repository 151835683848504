import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import React from 'react';
import moment from 'moment';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { kycStatus } from '../../../enums/kyc';
import { validDate } from '../../../utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
var statusMap = _defineProperty(_defineProperty(_defineProperty({}, kycStatus.pending, {
  text: 'Pending',
  color: 'info'
}), kycStatus.approved, {
  text: 'Approved',
  color: 'success'
}), kycStatus.rejected, {
  text: 'Rejected',
  color: 'error'
});
export var kycColumns = [{
  name: 'Created Date',
  value: 'createdOn',
  dateTimeFormat: 'DD/MM/YYYY',
  width: 90
}, {
  name: 'Expiration Date',
  value: 'expirationDate',
  dateTimeFormat: 'DD/MM/YYYY',
  width: 90
}, {
  name: 'User',
  render: function render(row) {
    return ___EmotionJSX("div", {
      style: {
        padding: '12px 0'
      }
    }, ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Name: "), ___EmotionJSX("span", null, "".concat(row.profile.fullName))), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Username: "), ___EmotionJSX("span", null, row.profile.userName)), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "User ID: "), ___EmotionJSX("span", null, row.profile.userId)), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Date of Birth: "), ___EmotionJSX("span", null, validDate(row.dateOfBirth) ? moment(validDate(row.dateOfBirth)).format('DD/MM/YYYY') : 'N/A')));
  }
}, {
  name: 'Country',
  value: 'profile.country',
  width: 70
}, {
  name: 'Type',
  value: 'type',
  width: 100
}, {
  name: 'Sub Type',
  value: 'subType',
  width: 120
}, {
  name: 'State',
  render: function render(row) {
    var _statusMap$row$state, _statusMap$row$state2;
    return ___EmotionJSX(ColorBatch, {
      type: (_statusMap$row$state = statusMap[row.state]) === null || _statusMap$row$state === void 0 ? void 0 : _statusMap$row$state.color
    }, (_statusMap$row$state2 = statusMap[row.state]) === null || _statusMap$row$state2 === void 0 ? void 0 : _statusMap$row$state2.text);
  },
  width: 90
}, {
  name: 'View details',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/kyc/".concat(row.id)
    });
  },
  width: 95
}];
export var pagesDetailsKyc = [{
  url: '/kyc',
  label: 'KYC'
}, {
  label: 'Detail Page'
}];
export var CAN_MARK_VIP = false;