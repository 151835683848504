function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable react/prop-types */
import ColorBatch from 'components/Shared/ColorBatch';
import React from 'react';
import { Link } from 'react-router-dom';
import { CheckButton, currencyByType, Theme } from '../../../../../../components/UI';
import { accountStatus } from '../../../../../../enums/account';
import { migrationStatus } from '../../../../../../enums/transferCoinWallet';
import { navLinkDetails } from '../../../styles';
import { type } from '../../../../../../enums/wallets';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var profileAccountBreadcrumbsPages = [{
  url: '/profiles',
  label: 'Profiles'
}, {
  label: 'Accounts'
}];
export var mapAccountStatus = _defineProperty(_defineProperty(_defineProperty({}, accountStatus.Active, {
  text: 'Active',
  color: 'success'
}), accountStatus.Frozen, {
  text: 'Frozen',
  color: 'warning'
}), accountStatus.Inactive, {
  text: 'Inactive',
  color: 'info'
});
export var mapMigrationStatus = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, migrationStatus.None, {
  text: 'Open for Transfer',
  color: 'info'
}), migrationStatus.TransferInitiationRetriable, {
  text: 'Transfer Payment Failed',
  color: 'error'
}), migrationStatus.TransferInitiated, {
  text: 'Transfer Initiated',
  color: 'warning'
}), migrationStatus.TransferPaid, {
  text: 'Transfer Paid',
  color: 'info'
}), migrationStatus.TransferInProgress, {
  text: 'Transfer in Progress',
  color: 'warning'
}), migrationStatus.Transferred, {
  text: 'Transferred',
  color: 'success'
}), migrationStatus.TransferError, {
  text: 'Transfer Error',
  color: 'error'
});
export var migrationStatusOptions = [{
  label: 'Open for Transfer / Transfer Payment Failed',
  value: migrationStatus.None
}, {
  label: 'Transfer Initiated',
  value: migrationStatus.TransferInitiated
}, {
  label: 'Transfer Paid',
  value: migrationStatus.TransferPaid
}, {
  label: 'Transfer in Progress',
  value: migrationStatus.TransferInProgress
}, {
  label: 'Transferred',
  value: migrationStatus.Transferred
}, {
  label: 'Transfer Error',
  value: migrationStatus.TransferError
}];
export var coinWalletVersionOptions = [{
  label: 'Coin Wallet V1',
  value: 1
}, {
  label: 'Coin Wallet V2',
  value: 2
}];
export var getColumns = function getColumns(changeAccount, userId, onChangeAccount, disableRowClick) {
  return [{
    name: 'Change owner',
    render: function render(row) {
      return ___EmotionJSX(CheckButton, {
        disabled: disableRowClick,
        checked: !!changeAccount.find(function (el) {
          return el === row.id;
        }),
        onChange: function onChange(value) {
          return onChangeAccount(row.id, value);
        }
      });
    }
  }, {
    name: 'Date of create',
    value: 'createdOn',
    dateTimeFormat: 'DD.MM.YYYY, HH:mm:ss'
  }, {
    name: 'Account Number',
    value: 'id'
  }, {
    name: 'Nickname',
    value: 'nickName'
  }, {
    name: 'Package',
    value: 'package.name'
  }, {
    name: 'Cash',
    render: function render(row) {
      return currencyByType(row.cashWalletBalance, type.CASH);
    }
  }, {
    name: 'Coins',
    render: function render(row) {
      return currencyByType(row.coinWalletBalance, type.ONE);
    }
  }, {
    name: 'Status',
    sortable: true,
    render: function render(row) {
      var _mapAccountStatus$row, _mapAccountStatus$row2;
      return ___EmotionJSX(ColorBatch, {
        type: (_mapAccountStatus$row = mapAccountStatus[row === null || row === void 0 ? void 0 : row.status]) === null || _mapAccountStatus$row === void 0 ? void 0 : _mapAccountStatus$row.color
      }, (_mapAccountStatus$row2 = mapAccountStatus[row === null || row === void 0 ? void 0 : row.status]) === null || _mapAccountStatus$row2 === void 0 ? void 0 : _mapAccountStatus$row2.text);
    }
  }, {
    name: 'Current Account',
    render: function render(row) {
      return ___EmotionJSX(ColorBatch, {
        type: row.isCurrent ? 'success' : 'error'
      }, row.isCurrent ? 'Current' : 'Not current');
    }
  }, {
    name: 'Migration status',
    render: function render(row) {
      var _mapMigrationStatus$r, _mapMigrationStatus$r2;
      return ___EmotionJSX(ColorBatch, {
        type: (_mapMigrationStatus$r = mapMigrationStatus[row === null || row === void 0 ? void 0 : row.coinWalletV1ToV2TransferStatus]) === null || _mapMigrationStatus$r === void 0 ? void 0 : _mapMigrationStatus$r.color
      }, (_mapMigrationStatus$r2 = mapMigrationStatus[row === null || row === void 0 ? void 0 : row.coinWalletV1ToV2TransferStatus]) === null || _mapMigrationStatus$r2 === void 0 ? void 0 : _mapMigrationStatus$r2.text);
    }
  }, {
    name: 'Details',
    render: function render(row) {
      return ___EmotionJSX(Link, {
        to: "/profiles/profile/".concat(userId, "/accounts/").concat(row.id),
        className: navLinkDetails(Theme)
      }, "Details");
    }
  }];
};