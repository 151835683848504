import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isFunction from "lodash/isFunction";
import "core-js/modules/es.array.is-array.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Icon } from '../Icon';
import { Timer } from '../../../utils';
import { useConstant } from '../hooks';
import { ReactComponent as infoIcon } from '../../../assets/images/icn-alert-notice-2.svg';
import { ReactComponent as successIcon } from '../../../assets/images/icn-alert-success.svg';
import { ReactComponent as warningIcon } from '../../../assets/images/icn-alert-warning.svg';
import { ReactComponent as errorIcon } from '../../../assets/images/icn-alert-error.svg';
import { notification, notifContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var getIcon = {
  success: successIcon,
  error: errorIcon,
  warning: warningIcon,
  info: infoIcon
};
var Notification = function Notification(props) {
  var id = props.id,
    onRemoval = props.onRemoval,
    title = props.title,
    message = props.message,
    content = props.content,
    _props$duration = props.duration,
    duration = _props$duration === void 0 ? 7000 : _props$duration,
    toggleRemoval = props.toggleRemoval,
    onClick = props.onClick,
    type = props.type,
    iconName = props.iconName,
    _props$alwaysShow = props.alwaysShow,
    alwaysShow = _props$alwaysShow === void 0 ? false : _props$alwaysShow,
    _props$pauseOnHover = props.pauseOnHover,
    pauseOnHover = _props$pauseOnHover === void 0 ? true : _props$pauseOnHover;
  var _useConstant = useConstant(),
    _useConstant2 = _slicedToArray(_useConstant, 3),
    setTimer = _useConstant2[1],
    timer = _useConstant2[2];
  var _useConstant3 = useConstant(onRemoval),
    _useConstant4 = _slicedToArray(_useConstant3, 2),
    callback = _useConstant4[0],
    setCallback = _useConstant4[1];
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    hide = _useState2[0],
    setHide = _useState2[1];
  var theme = useTheme();
  var onTransitionStart = function onTransitionStart() {
    if (!duration || alwaysShow) return;
    var notifTimer = new Timer(function () {
      return setHide(true);
    }, duration);
    setTimer(notifTimer);
    notifTimer.start();
  };
  useEffect(function () {
    pauseOnHover && onTransitionStart();
    return function () {
      var _timer$current;
      pauseOnHover && ((_timer$current = timer.current) === null || _timer$current === void 0 ? void 0 : _timer$current.clear());
    };
  }, []);
  var onTransitionEnd = function onTransitionEnd() {
    toggleRemoval(id);
    _isFunction(callback) && callback(props);
  };
  var onMouseEnter = function onMouseEnter() {
    var _timer$current2;
    return (_timer$current2 = timer.current) === null || _timer$current2 === void 0 ? void 0 : _timer$current2.pause();
  };
  var onMouseLeave = function onMouseLeave() {
    var _timer$current3;
    return (_timer$current3 = timer.current) === null || _timer$current3 === void 0 ? void 0 : _timer$current3.start();
  };
  var handleClick = function handleClick() {
    setCallback(_isFunction(onClick) ? onClick : onRemoval);
    setHide(true);
  };
  var NotificationIcon = getIcon[type];
  return ___EmotionJSX("div", {
    role: "presentation",
    className: notification(type, hide, theme),
    onMouseEnter: pauseOnHover ? onMouseEnter : null,
    onMouseLeave: pauseOnHover ? onMouseLeave : null,
    onClick: handleClick,
    onTransitionEnd: hide ? onTransitionEnd : onTransitionStart
  }, content !== null && content !== void 0 ? content : ___EmotionJSX("div", {
    className: notifContainer(type, theme)
  }, iconName ? ___EmotionJSX(Icon, {
    material: true,
    iconName: iconName
  }) : ___EmotionJSX(NotificationIcon, null), ___EmotionJSX("span", null, title && ___EmotionJSX("h4", null, title), message && ___EmotionJSX("span", null, message))));
};
Notification.propTypes = {
  id: PropTypes.string,
  onRemoval: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  content: PropTypes.node,
  duration: PropTypes.number,
  type: PropTypes.string,
  toggleRemoval: PropTypes.func,
  onClick: PropTypes.func,
  alwaysShow: PropTypes.bool,
  iconName: PropTypes.string,
  pauseOnHover: PropTypes.bool
};
export default Notification;