import _isFunction from "lodash/isFunction";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.object.assign.js";
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import moment from 'moment';
import { Image, Icon } from '../../UI';
import { pluralizeMonths, validDate } from '../../../utils';
import avatar from '../../../assets/images/package.png';
import { containerShop, containerPackageShop, shopPackageCardTop, pictureContainer, calendarIcon, dateContainer, subscriptionCardContainer, shopPackageCardUnder } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SubscriptionCard = function SubscriptionCard(props) {
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onClick = props.onClick;
  var totalDuration = item.totalDuration,
    picThumbnailUrl = item.picThumbnailUrl,
    subscriptionCatalogueItemId = item.subscriptionCatalogueItemId,
    vendor = item.vendor,
    validFrom = item.validFrom,
    totalValidUntil = item.totalValidUntil,
    activeSubscriptionPlans = item.activeSubscriptionPlans;
  var theme = useTheme();
  var handleLinkClick = function handleLinkClick(e) {
    return e.preventDefault();
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: containerShop(theme),
    onClick: onClick
  }, ___EmotionJSX(Link, _extends({
    to: "/subscriptions/subscription/".concat(subscriptionCatalogueItemId),
    className: shopPackageCardTop(theme)
  }, _isFunction(onClick) && {
    onClick: handleLinkClick
  }), ___EmotionJSX("div", {
    className: subscriptionCardContainer
  }, ___EmotionJSX("div", {
    className: containerPackageShop
  }, ___EmotionJSX(Image, {
    size: "contain",
    src: picThumbnailUrl !== null && picThumbnailUrl !== void 0 ? picThumbnailUrl : avatar,
    width: 60,
    height: 60,
    className: pictureContainer
  }), ___EmotionJSX("h3", null, ___EmotionJSX("span", {
    style: {
      color: theme.grayLight
    }
  }, vendor)), ___EmotionJSX("p", null, totalDuration, " ", pluralizeMonths(totalDuration))), validDate(validFrom) && ___EmotionJSX("div", {
    className: dateContainer(theme)
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "calendar_today",
    className: calendarIcon(theme)
  }), ___EmotionJSX("span", null, "Start Date:", ' ', ___EmotionJSX("span", null, moment(validFrom).format('DD/MM/YYYY')))), validDate(totalValidUntil) && ___EmotionJSX("div", {
    className: dateContainer(theme)
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "calendar_today",
    className: calendarIcon(theme)
  }), ___EmotionJSX("span", null, "End Date:", ' ', ___EmotionJSX("span", null, moment(totalValidUntil).format('DD/MM/YYYY')))))), activeSubscriptionPlans.length > 1 && ___EmotionJSX("div", {
    className: shopPackageCardUnder(theme)
  }, "\xA0"));
};
SubscriptionCard.propTypes = {
  item: PropTypes.any,
  onClick: PropTypes.func
};
export default SubscriptionCard;