import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isFunction from "lodash/isFunction";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Dropdown, Spinner, ReactSelectDropdown } from '../../UI';
import useAccounts from '../../../hooks/useAccounts';
import { accountsOptions } from '../../../enums/account';
import { shoppingCartProfileContainerAccount, dropdownFilterPayment, dropdownFilter, inputFilter } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var FilterByAccount = function FilterByAccount(props) {
  var onSelect = props.onSelect,
    paymentMethods = props.paymentMethods;
  var _useAccounts = useAccounts(),
    searchAccountRef = _useAccounts.searchAccountRef,
    hasMore = _useAccounts.hasMore,
    fetchAccounts = _useAccounts.fetchAccounts;
  var filtersRef = useRef({});
  useEffect(function () {
    onSelect(null);
  }, []);
  var handleChangeAccount = function handleChangeAccount(value) {
    filtersRef.current = _objectSpread(_objectSpread({}, filtersRef.current), {}, {
      account: value
    });
    _isFunction(onSelect) && onSelect(filtersRef.current);
  };
  var handlePaymentChange = function handlePaymentChange(_ref) {
    var value = _ref.value;
    filtersRef.current = _objectSpread(_objectSpread({}, filtersRef.current), {}, {
      paymentMethod: value
    });
    _isFunction(onSelect) && onSelect(filtersRef.current);
  };

  // eslint-disable-next-line react/prop-types
  var MenuList = function MenuList(_ref2) {
    var children = _ref2.children;
    return ___EmotionJSX("div", {
      style: {
        maxHeight: '300px',
        overflow: 'auto'
      }
    }, ___EmotionJSX(InfiniteScroll, {
      loadMore: function loadMore() {
        fetchAccounts({
          page: searchAccountRef.current.pageIndex + 1
        });
      },
      hasMore: hasMore,
      loader: ___EmotionJSX(Spinner, {
        key: 0
      }),
      initialLoad: false,
      useWindow: false
    }, children));
  };
  return ___EmotionJSX("div", {
    className: shoppingCartProfileContainerAccount
  }, ___EmotionJSX(Dropdown, {
    noClear: true,
    value: accountsOptions[0],
    options: accountsOptions,
    onChange: function onChange(_ref3) {
      var value = _ref3.value;
      searchAccountRef.current.searchCriteria = value;
    },
    className: dropdownFilter
  }), ___EmotionJSX(ReactSelectDropdown, {
    async: true,
    cacheOptions: true,
    defaultOptions: true,
    placeholder: "Search profile",
    loadOptions: function loadOptions(inputValue, callback) {
      fetchAccounts({
        inputValue: inputValue,
        callback: callback
      });
    },
    onMenuOpen: function onMenuOpen() {
      var _searchAccountRef$cur;
      !((_searchAccountRef$cur = searchAccountRef.current.results) !== null && _searchAccountRef$cur !== void 0 && _searchAccountRef$cur.length) && fetchAccounts({
        inputValue: ''
      });
    },
    components: {
      MenuList: MenuList
    },
    onChange: handleChangeAccount,
    className: inputFilter
  }), ___EmotionJSX(Dropdown, {
    noClear: true,
    options: paymentMethods,
    onChange: handlePaymentChange,
    placeholder: "Select payment method",
    className: dropdownFilterPayment
  }));
};
FilterByAccount.propTypes = {
  onSelect: PropTypes.func,
  paymentMethods: PropTypes.array
};
export default FilterByAccount;