import _isString from "lodash/isString";
import _isNil from "lodash/isNil";
import _uniq from "lodash/uniq";
import _isFunction from "lodash/isFunction";
import _isArray from "lodash/isArray";
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Icon } from '../Icon';
import { getFormChilds } from './utils';
import { getNestedProperty } from '../../../utils';
import { successBar, successBarColorEffect, successBarContent } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var _SuccessBar = function SuccessBar(_ref) {
  var onHide = _ref.onHide,
    message = _ref.message;
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: successBar
  }, ___EmotionJSX("div", {
    className: successBarColorEffect(theme)
  }), ___EmotionJSX("div", {
    className: successBarContent
  }, _isArray(message) ? "Successfully edited: ".concat(message.join(', ')) : message, ___EmotionJSX(Icon, {
    material: true,
    color: "secondary",
    iconName: "close",
    onClick: onHide
  })));
};
_SuccessBar.propTypes = {
  onHide: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};
export var useSuccessBar = function useSuccessBar() {
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    isVisible = _useState2[0],
    setIsVisible = _useState2[1];
  var formRef = useRef({});
  var messageToShow = useRef([]);
  useEffect(function () {
    if (!isVisible) return function () {};
    var timer = setTimeout(function () {
      setIsVisible(false);
    }, 5000);
    return function () {
      return clearTimeout(timer);
    };
  }, [isVisible]);
  var hideSuccessBar = function hideSuccessBar() {
    return setIsVisible(false);
  };
  var setUpMessageAndShow = function setUpMessageAndShow(val) {
    messageToShow.current = val;
    setIsVisible(true);
  };
  var showSuccessBar = function showSuccessBar(values, mapFunction) {
    if (_isString(values)) {
      setUpMessageAndShow(values);
      return;
    }
    var children = formRef.current.children;
    var childrens = getFormChilds(children);
    var editedChildrens = childrens
    // Filter those children which do not have a representative value inside the form object
    .filter(function (el) {
      return el.props.id && !_isNil(getNestedProperty(values, el.props.id.split('.')));
    })
    // Map the fields that have values to their labels or pass the child.id and child to map function
    .map(function (child) {
      return _isFunction(mapFunction) ? mapFunction(child.props.id, child) : child.props.label;
    });

    // Save edited fields by removing the duplicates
    setUpMessageAndShow(_uniq(editedChildrens));
  };
  return {
    isVisible: isVisible,
    hideSuccessBar: hideSuccessBar,
    showSuccessBar: showSuccessBar,
    formRef: formRef,
    SuccessBar: function SuccessBar(formProps) {
      return ___EmotionJSX(_SuccessBar, _extends({
        onHide: hideSuccessBar,
        message: messageToShow.current
      }, formProps));
    }
  };
};
export default _SuccessBar;