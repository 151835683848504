import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isArray from "lodash/isArray";
import _pick from "lodash/pick";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.for-each.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import queryString from 'query-string';
import { orderPaymentStatus, orderStatus } from '../../enums/orders';
import avatar from '../../assets/images/package.png';
import { validDate, endOfTheDay, startOfTheDay } from '../../utils';
export var getOrderModel = function getOrderModel(response) {
  var _response$packages, _response$subscriptio, _response$payments;
  var mappedOrder = response && _objectSpread(_objectSpread({}, response), {}, {
    totalAmount: "\u20AC ".concat(response.totalAmount),
    status: orderStatus[response === null || response === void 0 ? void 0 : response.status]
  });
  mappedOrder.packages = response === null || response === void 0 || (_response$packages = response.packages) === null || _response$packages === void 0 ? void 0 : _response$packages.map(function (el) {
    var _el$pictureUrl, _el$picThumbnailUrl;
    return _objectSpread(_objectSpread({}, el), {}, {
      pictureUrl: (_el$pictureUrl = el.pictureUrl) !== null && _el$pictureUrl !== void 0 ? _el$pictureUrl : avatar,
      picThumbnailUrl: (_el$picThumbnailUrl = el.picThumbnailUrl) !== null && _el$picThumbnailUrl !== void 0 ? _el$picThumbnailUrl : avatar
    });
  });
  mappedOrder.subscriptions = response === null || response === void 0 || (_response$subscriptio = response.subscriptions) === null || _response$subscriptio === void 0 ? void 0 : _response$subscriptio.map(function (el) {
    var _el$pictureUrl2, _el$picThumbnailUrl2;
    return _objectSpread(_objectSpread({}, el), {}, {
      pictureUrl: (_el$pictureUrl2 = el.pictureUrl) !== null && _el$pictureUrl2 !== void 0 ? _el$pictureUrl2 : avatar,
      picThumbnailUrl: (_el$picThumbnailUrl2 = el.picThumbnailUrl) !== null && _el$picThumbnailUrl2 !== void 0 ? _el$picThumbnailUrl2 : avatar
    });
  });
  mappedOrder.payments = response === null || response === void 0 || (_response$payments = response.payments) === null || _response$payments === void 0 ? void 0 : _response$payments.map(function (el) {
    return _objectSpread(_objectSpread({}, el), {}, {
      status: orderPaymentStatus[el.status]
    });
  });
  return mappedOrder;
};
export var getGiftCodesModel = function getGiftCodesModel(response) {
  return response && _objectSpread(_objectSpread({}, response), {}, {
    dateOfOrder: validDate(response.dateOfOrder),
    dateOfRedeeming: validDate(response.dateOfRedeeming),
    expireDate: validDate(response.expireDate),
    academyCoinsRewardedOn: validDate(response.academyCoinsRewardedOn)
  });
};
export var getAllOrdersParams = function getAllOrdersParams(options) {
  var _options$status, _options$searchInput, _options$searchInput2;
  var defaultSort = {
    sortField: 'id',
    sortOrder: 'desc'
  };
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  var filterField = [];
  var filterValue = [];
  if ((options === null || options === void 0 || (_options$status = options.status) === null || _options$status === void 0 ? void 0 : _options$status.length) > 0) {
    options.status.forEach(function (status) {
      filterField.push('status');
      filterValue.push(status);
    });
  }

  // If we search by order ID, API will through error if we pass more than 1 search params
  // So in this case we erase all search filters and pass only search by order id
  if ((options === null || options === void 0 ? void 0 : options.searchCriteria) === 'id' && (_options$searchInput = options.searchInput) !== null && _options$searchInput !== void 0 && _options$searchInput.length) {
    filterField = [];
    filterValue = [];
  }
  if (options.searchCriteria && (_options$searchInput2 = options.searchInput) !== null && _options$searchInput2 !== void 0 && _options$searchInput2.length) {
    filterField.push(options.searchCriteria);
    filterValue.push(options.searchInput);
  }
  if (options.paymentMethod) {
    filterField.push('paymentMethod');
    filterValue.push(options.paymentMethod);
  }
  var params = queryString.stringify(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize', 'orderItemType'])), options.sort ? mapSort(options.sort) : defaultSort), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
  return params;
};
export var getAllGiftCodesParams = function getAllGiftCodesParams(options) {
  var buyerSearchBy = options.buyerSearchBy,
    buyerSearchFor = options.buyerSearchFor,
    redeemerSearchBy = options.redeemerSearchBy,
    redeemerSearchFor = options.redeemerSearchFor,
    purchaseStartDate = options.purchaseStartDate,
    purchaseEndDate = options.purchaseEndDate,
    redeemStartDate = options.redeemStartDate,
    redeemEndDate = options.redeemEndDate,
    paymentMethod = options.paymentMethod,
    packageName = options.packageName,
    status = options.status,
    searchCriteria = options.searchCriteria,
    searchInput = options.searchInput,
    giftCode = options.giftCode;
  var filterField = [];
  var filterValue = [];
  var buyer = {};
  var redeemer = {};
  var purchaseDate = {};
  if (_isArray(giftCode) && giftCode.length) {
    giftCode.forEach(function (el) {
      filterField.push('giftCode');
      filterValue.push([el.trim()]);
    });
    return queryString.stringify(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), filterField.length && filterValue.length && {
      filterField: filterField,
      filterValue: filterValue
    }), {
      arrayFormat: 'index'
    });
  }
  if (searchCriteria && searchInput !== null && searchInput !== void 0 && searchInput.length) {
    filterField.push(searchCriteria);
    filterValue.push(searchInput);
  }
  if (paymentMethod) {
    filterField.push('paymentMethod');
    filterValue.push(paymentMethod);
  }
  if (packageName) {
    filterField.push('packageName');
    filterValue.push(packageName);
  }
  if (status) {
    filterField.push('giftCodeStatus');
    filterValue.push(status);
  }
  if (buyerSearchBy && buyerSearchFor !== null && buyerSearchFor !== void 0 && buyerSearchFor.length) {
    buyer.buyerSearchField = "".concat(buyerSearchBy);
    buyer.buyerSearchValue = "".concat(buyerSearchFor);
  }
  if (redeemerSearchBy && redeemerSearchFor !== null && redeemerSearchFor !== void 0 && redeemerSearchFor.length) {
    redeemer.redeemerSearchField = "".concat(redeemerSearchBy);
    redeemer.redeemerSearchValue = "".concat(redeemerSearchFor);
  }
  if (purchaseStartDate && purchaseEndDate) {
    purchaseDate.OrderDateFrom = startOfTheDay(purchaseStartDate).format();
    purchaseDate.OrderDateTo = endOfTheDay(purchaseEndDate).format();
  }
  if (redeemStartDate && redeemEndDate) {
    purchaseDate.RedeemDateFrom = startOfTheDay(redeemStartDate).format();
    purchaseDate.RedeemDateTo = endOfTheDay(redeemEndDate).format();
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), purchaseDate), buyer), redeemer), {
    arrayFormat: 'index'
  });
};
export var createNewOrderModel = function createNewOrderModel(_ref) {
  var accountId = _ref.accountId,
    paymentTypeId = _ref.paymentTypeId,
    items = _ref.items;
  var packages = [];
  var subscriptionPlans = [];
  items.forEach(function (item) {
    if (item.subscriptionCatalogueItemId !== undefined) subscriptionPlans.push(item.id);else {
      var packageItems = Array(item.amount).fill({
        catalogueItemId: item.catalogueItemId
      });
      packages = packages.concat(packageItems);
    }
  });
  var requestBody = {
    accountId: accountId,
    paymentTypeId: paymentTypeId,
    packages: packages,
    subscriptionPlans: subscriptionPlans
  };
  return requestBody;
};