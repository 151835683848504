import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNil from "lodash/isNil";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import { Skeleton } from '../../UI';
import ColorBatch from '../../Shared/ColorBatch';
import Amount from '../../Shared/Amount';
import { transactionStatus } from '../../../enums/transactions';
import { rowTransactions, internalContainer, arrowTrans, descTransactions, skeletonContainer, marginBottom6, statusContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var mapTransactionStatus = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, transactionStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), transactionStatus.Completed, {
  text: 'Completed',
  color: 'success'
}), transactionStatus.Rejected, {
  text: 'Rejected',
  color: 'error'
}), transactionStatus.Blocked, {
  text: 'Blocked',
  color: 'warning'
});
var LatestTransactions = function LatestTransactions(props) {
  var _mapTransactionStatus2, _mapTransactionStatus3, _item$description;
  var wallet = props.wallet,
    item = props.item;
  var theme = useTheme();
  return _isNil(wallet) || _isNil(item) ? ___EmotionJSX("div", {
    className: rowTransactions(theme)
  }, ___EmotionJSX(Skeleton, {
    width: 80,
    height: 25
  }), ___EmotionJSX("div", {
    className: skeletonContainer
  }, ___EmotionJSX(Skeleton, {
    width: 150,
    height: 25,
    className: marginBottom6
  }), ___EmotionJSX(Skeleton, {
    width: 250,
    height: 40
  })), ___EmotionJSX(Skeleton, {
    width: 80,
    height: 35
  })) : ___EmotionJSX("div", {
    className: rowTransactions(theme)
  }, ___EmotionJSX("div", null, moment(item.createdOn).format('DD/MM/YYYY'), ___EmotionJSX("br", null), moment(item.createdOn).format('HH:mm:ss')), ___EmotionJSX("div", {
    className: internalContainer
  }, ___EmotionJSX("p", null, "Platform ", ___EmotionJSX("span", {
    className: arrowTrans
  }, "\u2192"), wallet.accountId), ___EmotionJSX("div", {
    className: descTransactions
  }, ___EmotionJSX(ColorBatch, {
    type: (_mapTransactionStatus2 = mapTransactionStatus[item.completionStatus]) === null || _mapTransactionStatus2 === void 0 ? void 0 : _mapTransactionStatus2.color,
    className: statusContainer
  }, (_mapTransactionStatus3 = mapTransactionStatus[item.completionStatus]) === null || _mapTransactionStatus3 === void 0 ? void 0 : _mapTransactionStatus3.text), ___EmotionJSX("div", null, item.createdByEmail && ___EmotionJSX("p", null, "Created By: ", item.createdByEmail), item.userReason && ___EmotionJSX("p", null, "User Reason: ", item.userReason), item.reason && ___EmotionJSX("p", null, "System Reason: ", item.reason), item.description && ((_item$description = item.description) === null || _item$description === void 0 ? void 0 : _item$description.map(function (item, index) {
    return item.description && ___EmotionJSX("p", {
      key: index
    }, "Description: ", item.description);
  }))))), ___EmotionJSX("div", null, ___EmotionJSX(Amount, {
    amount: item.amount,
    currency: wallet.walletType,
    transactionType: item.type
  })));
};
LatestTransactions.propTypes = {
  wallet: PropTypes.object,
  item: PropTypes.object
};
export default LatestTransactions;