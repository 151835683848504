export var optionsType = [{
  value: 'Outbound',
  label: 'Outbound'
}, {
  value: 'Inbound',
  label: 'Inbound'
}];
export var optionsWalletType = [{
  label: 'Cash',
  value: 'cash'
}, {
  label: 'Coin',
  value: 'coin'
}, {
  label: 'Gas',
  value: 'gas'
}, {
  label: 'OESP',
  value: 'oesp'
}, {
  label: 'Trading',
  value: 'trading'
}, {
  label: 'Token',
  value: 'token'
}];
export var transactionOptions = [{
  label: 'Transactions',
  value: false
}, {
  label: 'Archive',
  value: true
}];