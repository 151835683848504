import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var colourStyles = {
  control: function control(styles, _ref) {
    var isDisabled = _ref.isDisabled,
      isFocused = _ref.isFocused,
      isSelected = _ref.isSelected;
    return _objectSpread(_objectSpread({}, styles), {}, {
      cursor: 'pointer',
      backgroundColor: isDisabled ? null : isSelected ? '#ffffff' : isFocused ? 'rgba(247,248,252,1)' : 'rgba(247,248,252,1)',
      borderRadius: 3,
      borderColor: isDisabled ? null : isSelected ? '#ed4a49' : isFocused ? '#ed4a49' : '#e9eaf0b3',
      outline: 0,
      boxShadow: 'none',
      minHeight: 44,
      '&:hover': {
        borderColor: 'none'
      },
      '&.custom-select__control--menu-is-open .custom-select__dropdown-indicator': {
        transform: 'rotate(-180deg)'
      }
    });
  },
  menu: function menu(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      marginTop: 0,
      borderRadius: 'none'
    });
  },
  placeholder: function placeholder(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      color: '#5f636f',
      fontSize: 14,
      fontWeight: 600
    });
  },
  singleValue: function singleValue(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      color: '#5f636f',
      fontSize: 14,
      fontWeight: 600,
      paddingLeft: 7
    });
  },
  indicatorsContainer: function indicatorsContainer(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      '& > .custom-select__dropdown-indicator': {
        cursor: 'pointer',
        display: 'inline-block',
        color: 'rgba(55,57,63,.6)',
        transition: 'transform 0.35s ease-out',
        '&:hover': {
          color: 'rgba(55,57,63,.6)'
        },
        '& > svg': {
          width: 18,
          height: 18
        }
      }
    });
  },
  indicatorSeparator: function indicatorSeparator(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      backgroundColor: 'none'
    });
  },
  option: function option(styles, _ref2) {
    var isDisabled = _ref2.isDisabled,
      isFocused = _ref2.isFocused,
      isSelected = _ref2.isSelected;
    return _objectSpread(_objectSpread({}, styles), {}, {
      backgroundColor: isDisabled ? null : isSelected ? '#ed4a49' : isFocused ? '#f7f8fa' : '#ffffff',
      color: isDisabled ? null : isSelected ? '#ffffff' : '#5f636f',
      fontSize: 13,
      fontWeight: 600,
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 15,
      paddingBottom: 15,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': _objectSpread(_objectSpread({}, styles[':active']), {}, {
        backgroundColor: !isDisabled && (isSelected ? '#ffffff' : '#ffffff')
      })
    });
  }
};
export default colourStyles;