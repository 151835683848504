function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import React from 'react';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import { mapProfileStatus } from '../../../enums/profiles';
import { kycStatus } from '../../../enums/kyc';
import { Image } from '../../../components/UI';
import defaultAvatar from '../../../assets/images/default-avatar.png';
import { image } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var mapKycStatus = _defineProperty(_defineProperty(_defineProperty({}, kycStatus.pending, {
  text: 'Pending',
  color: 'info'
}), kycStatus.approved, {
  text: 'Approved',
  color: 'success'
}), kycStatus.rejected, {
  text: 'Rejected',
  color: 'error'
});
export var userInfo = [{
  label: 'Email Address',
  value: 'email'
}, {
  label: 'Phone number',
  value: 'phone'
}, {
  label: 'Sponsor Account',
  value: 'sponsorAccountId'
}, {
  label: 'Date of birth',
  value: 'dateOfBirth',
  date: true
}, {
  label: 'Id Expiration',
  value: 'idExpiration',
  date: true
}, {
  label: 'Id Number',
  value: 'idNumber'
}, {
  label: 'Id Type',
  value: 'idType'
}, {
  label: 'Ima Enabled',
  value: 'imaEnabled',
  bool: true
}, {
  label: 'Newsletter Enabled',
  value: 'newsletterEnabled',
  bool: true
}, {
  label: 'Age Compliant',
  value: 'ageCompliant',
  bool: true
}, {
  label: 'Mobile PIN',
  value: 'mobilePin'
}, {
  label: 'Mobile Access',
  value: 'hasMobileAccess',
  bool: true
}, {
  label: 'Eagle Rank Stars',
  value: 'eagleRankStars'
}];
export var contactInfo = [{
  label: 'Country',
  value: 'country'
}, {
  label: 'Country of birth',
  value: 'countryOfBirth'
}, {
  label: 'City',
  value: 'city'
}, {
  label: 'City of birth',
  value: 'cityOfBirth'
}, {
  label: 'Address',
  value: 'address'
}, {
  label: 'Postal code',
  value: 'zip'
}];
export var statusChange = [{
  value: 'Pending',
  label: 'Pending'
}, {
  value: 'Active',
  label: 'Active'
}, {
  value: 'Frozen',
  label: 'Frozen'
}, {
  value: 'Terminated',
  label: 'Terminated'
}];
export var breadcrumbsOverview = [{
  url: '/profiles',
  label: 'Profiles'
}, {
  label: 'Detail Page'
}];
export var profileColumns = [{
  name: 'First Name',
  value: 'firstName',
  width: 120
}, {
  name: 'Last Name',
  value: 'lastName',
  width: 120
}, {
  name: 'Username',
  value: 'userName',
  width: 150
}, {
  name: 'UserID',
  value: 'userId',
  width: 280
}, {
  name: 'Email address',
  value: 'email',
  width: 220
}, {
  name: 'Phone',
  value: 'phone',
  width: 140
}, {
  name: 'Status',
  sortable: true,
  render: function render(row) {
    var _mapProfileStatus$row, _mapProfileStatus$row2;
    return ___EmotionJSX(ColorBatch, {
      type: (_mapProfileStatus$row = mapProfileStatus[row === null || row === void 0 ? void 0 : row.status]) === null || _mapProfileStatus$row === void 0 ? void 0 : _mapProfileStatus$row.color
    }, (_mapProfileStatus$row2 = mapProfileStatus[row === null || row === void 0 ? void 0 : row.status]) === null || _mapProfileStatus$row2 === void 0 ? void 0 : _mapProfileStatus$row2.text);
  },
  width: 100
}, {
  name: 'Sponsor',
  value: 'sponsorAccountId',
  width: 70
}, {
  name: 'Is Migrated',
  render: function render(row) {
    return row !== null && row !== void 0 && row.isMigrated ? 'Yes' : 'No';
  },
  width: 90
}, {
  name: 'View details',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/profiles/profile/".concat(row.userId)
    });
  },
  width: 100
}];
export var leadersColumns = [{
  name: 'Rank',
  value: 'rank',
  width: 50
}, {
  name: 'Candidate',
  render: function render(row) {
    var _row$candidatePicThum;
    return ___EmotionJSX(Image, {
      className: image,
      src: (_row$candidatePicThum = row === null || row === void 0 ? void 0 : row.candidatePicThumbnailUrl) !== null && _row$candidatePicThum !== void 0 ? _row$candidatePicThum : defaultAvatar,
      width: 40,
      height: 40
    });
  },
  width: 70
}, {
  name: '',
  render: function render(row) {
    return "".concat(row.candidateFirstName, " ").concat(row.candidateLastName);
  }
}, {
  name: 'Total Votes',
  value: 'totalVotes',
  width: 100
}];