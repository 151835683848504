function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _pick from "lodash/pick";
import _isUndefined from "lodash/isUndefined";
import _mapValues from "lodash/mapValues";
import _isPlainObject from "lodash/isPlainObject";
import _isNil from "lodash/isNil";
import _isArray from "lodash/isArray";
import _isFunction from "lodash/isFunction";
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { isStrNum } from '../../../utils';
export var performAction = function performAction(value, action) {
  if (_isFunction(action)) return _isArray(value) ? value.map(action) : action(value);
  return null;
};
export var removeNodes = function removeNodes(options) {
  var displayKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label';
  var uniqueKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'value';
  var remove = function remove(element) {
    return isStrNum(element) ? element : _mapValues(element, function (val) {
      return _pick(val, [displayKey, uniqueKey]);
    });
  };
  return performAction(options, remove);
};
export var prepareValue = function prepareValue(_ref) {
  var value = _ref.value,
    options = _ref.options,
    multiSelect = _ref.multiSelect,
    uniqueKey = _ref.uniqueKey;
  var setValue = function setValue(val) {
    var match = options === null || options === void 0 ? void 0 : options.find(function (el) {
      return _isPlainObject(val) ? el[uniqueKey] === val[uniqueKey] : el[uniqueKey] === val;
    });
    return match !== null && match !== void 0 ? match : val;
  };
  return _isNil(value) ? multiSelect ? [] : null : performAction(value, setValue);
};
export var outputValue = function outputValue(value, displayKey, mappingFunction, shouldSimplify) {
  if (!shouldSimplify) return value;
  var getSimplify = function getSimplify(val) {
    return val !== null && val !== void 0 && val.simple ? val[displayKey] : val;
  };
  var simpleValue = performAction(value, getSimplify);
  return _isFunction(mappingFunction) ? mappingFunction(simpleValue) : simpleValue;
};
export var prepareOptions = function prepareOptions(options, displayKey, uniqueKey) {
  return _isUndefined(options) ? options : _isArray(options) && options.map(function (el) {
    return isStrNum(el) ? _defineProperty(_defineProperty(_defineProperty({}, displayKey, el), uniqueKey, el), "simple", true) : el;
  });
};