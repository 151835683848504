function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _isPlainObject from "lodash/isPlainObject";
import _isEmpty from "lodash/isEmpty";
import _isObject from "lodash/isObject";
import _isUndefined from "lodash/isUndefined";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.every.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var getNestedProperty = function getNestedProperty(object, properties) {
  return properties === null || properties === void 0 ? void 0 : properties.reduce(function (xs, x) {
    return xs && xs[x];
  }, object);
};
var _setNestedValue = function setNestedValue(object, properties, value) {
  var newObj = _objectSpread({}, object);
  if (properties.length === 1) {
    newObj[properties.pop()] = value;
    return newObj;
  }
  var currentProperty = properties.shift();
  if (_isUndefined(object[currentProperty]) || !_isObject(object[currentProperty])) newObj[currentProperty] = {};
  newObj[currentProperty] = _setNestedValue(newObj[currentProperty], properties, value);
  return newObj;
};
export { _setNestedValue as setNestedValue };
var _deleteNestedValue = function deleteNestedValue(obj, properties) {
  var newObj = _objectSpread({}, obj);
  if (properties.length === 1) {
    delete newObj[properties.pop()];
    return newObj;
  }
  var curProperty = properties.shift();
  newObj[curProperty] = _deleteNestedValue(newObj[curProperty], properties);
  if (_isEmpty(newObj[curProperty])) delete newObj[curProperty];
  return newObj;
};
export { _deleteNestedValue as deleteNestedValue };
export var flatObjectDeep = function flatObjectDeep(obj, deepLevel) {
  if (Object.keys(obj).every(function (el) {
    return !_isPlainObject(obj[el]);
  })) return obj;
  var _flat = function flat(res, key, val, pre, depth) {
    var prefix = [pre, key].filter(Boolean).join('.');
    return _isPlainObject(val) && depth !== 1 ? Object.keys(val).reduce(function (prev, curr) {
      return _flat(prev, curr, val[curr], prefix, depth - 1);
    }, res) : Object.assign(res, _defineProperty({}, prefix, val));
  };
  return Object.keys(obj).reduce(function (prev, curr) {
    return _flat(prev, curr, obj[curr], '', deepLevel);
  }, {});
};