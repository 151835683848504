import { withdrawalStatus } from '../../../enums/transactions';
import { withdrawalMethodType } from '../../../enums/wallets';
export var optionsStatus = [{
  label: 'Pending',
  value: withdrawalStatus.Pending
}, {
  label: 'Approved',
  value: withdrawalStatus.Approved
}, {
  label: 'Rejected',
  value: withdrawalStatus.Rejected
}, {
  label: 'Cancelled',
  value: withdrawalStatus.Cancelled
}];
export var searchUserOptions = [{
  label: 'Email',
  value: 'profileEmail'
}, {
  label: 'Username',
  value: 'profileUserName'
}, {
  label: 'Nickname',
  value: 'accountNickName'
}, {
  label: 'Phone',
  value: 'profilePhone'
}
// {
//   label: 'AccountID',
//   value: 'accountId',
// },
];
export var optionsWithdrawalMethodType = [{
  label: 'Bitcoin',
  value: withdrawalMethodType.BTC
}, {
  label: 'USDt',
  value: withdrawalMethodType.USDT
}];