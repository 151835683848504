import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNil from "lodash/isNil";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import { Paper, Skeleton, Icon } from '../../UI';
import ColorBatch from '../../Shared/ColorBatch';
import { kycStatus, kycVipStatus } from '../../../enums/kyc';
import { otherDocumentsElement, containerDocuments, headlineKycDoc, documentsKyc, statusVip, dateDoc } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var mapStatus = _defineProperty(_defineProperty(_defineProperty({}, kycStatus.pending, {
  text: 'Pending',
  color: 'info'
}), kycStatus.approved, {
  text: 'Approved',
  color: 'success'
}), kycStatus.rejected, {
  text: 'Rejected',
  color: 'error'
});
var KycAnotherUploadedDocuments = function KycAnotherUploadedDocuments(props) {
  var theme = useTheme();
  var documents = props.documents,
    navigateToOtherDocument = props.navigateToOtherDocument;
  var anotherDocuments = {
    pendingKyc: {
      label: 'Pending',
      items: documents.filter(function (el) {
        return el.state === kycStatus.pending;
      })
    },
    approvedKyc: {
      label: 'Approved',
      items: documents.filter(function (el) {
        return el.state === kycStatus.approved;
      })
    },
    rejectedKyc: {
      label: 'Rejected',
      items: documents.filter(function (el) {
        return el.state === kycStatus.rejected;
      })
    }
  };
  return documents.length > 0 && ___EmotionJSX(Paper, {
    header: "Other Documents",
    noPadding: true,
    className: containerDocuments
  }, _isNil(documents) ? Array(documents === null || documents === void 0 ? void 0 : documents.length).fill().map(function (el, ind, arr) {
    return ___EmotionJSX("div", {
      key: ind
    }, ___EmotionJSX(Skeleton, {
      width: 150,
      height: 70
    }), arr.length - 1 !== ind && ___EmotionJSX("hr", null));
  }) : Object.values(anotherDocuments).map(function (el) {
    return !!el.items.length && ___EmotionJSX(Fragment, {
      key: el.label
    }, ___EmotionJSX("h3", {
      className: headlineKycDoc
    }, el.label), el.items.map(function (item, ind, arr) {
      var _item$id, _item$reviewedOn, _mapStatus$item$state, _mapStatus$item$state2, _mapStatus$item$state3;
      return ___EmotionJSX(Fragment, {
        key: (_item$id = item.id) !== null && _item$id !== void 0 ? _item$id : ind
      }, ___EmotionJSX("div", {
        tabIndex: -1,
        role: "button",
        onClick: function onClick() {
          return navigateToOtherDocument(item);
        },
        className: otherDocumentsElement(theme)
      }, ___EmotionJSX("div", {
        className: documentsKyc(theme)
      }, item.isVip && ___EmotionJSX("p", {
        title: "VIP Documents"
      }, ___EmotionJSX(Icon, {
        iconName: "fas fa-star",
        size: 20
      })), ___EmotionJSX("div", null, ___EmotionJSX("h5", null, "Date:", ___EmotionJSX("span", {
        className: dateDoc
      }, moment((_item$reviewedOn = item.reviewedOn) !== null && _item$reviewedOn !== void 0 ? _item$reviewedOn : item.createdOn).format('DD/MM/YYYY'))), ___EmotionJSX("h4", null, "Type: ", item.type), ___EmotionJSX("p", null, "Sub-type: ", item.subType), ___EmotionJSX("p", null, "State: ", (_mapStatus$item$state = mapStatus[item.state]) === null || _mapStatus$item$state === void 0 ? void 0 : _mapStatus$item$state.text))), ___EmotionJSX("div", {
        className: statusVip
      }, ___EmotionJSX(ColorBatch, {
        type: (_mapStatus$item$state2 = mapStatus[item.state]) === null || _mapStatus$item$state2 === void 0 ? void 0 : _mapStatus$item$state2.color
      }, (_mapStatus$item$state3 = mapStatus[item.state]) === null || _mapStatus$item$state3 === void 0 ? void 0 : _mapStatus$item$state3.text), item.isVip && ___EmotionJSX(ColorBatch, {
        type: item.vipState === kycVipStatus.paid ? 'success' : 'error'
      }, item.vipState))), arr.length - 1 !== ind && ___EmotionJSX("hr", null));
    }));
  }));
};
KycAnotherUploadedDocuments.propTypes = {
  documents: PropTypes.array,
  navigateToOtherDocument: PropTypes.func
};
export default KycAnotherUploadedDocuments;