import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Skeleton } from '../../UI';
import ColorBatch from '../../Shared/ColorBatch';
import { type } from '../../../enums/wallets';
import { currencyByType } from '../../../utils/displayData';
import { container, pictureContainer, packageInfo, infoContainer, statusContainer, marginBottom4 } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PackageInfo = function PackageInfo(props) {
  var data = props.data,
    className = props.className;
  return _isEmpty(data) ? ___EmotionJSX("article", {
    className: container(className)
  }, ___EmotionJSX(Skeleton, {
    width: 70,
    height: 70,
    circle: true,
    className: pictureContainer
  }), ___EmotionJSX("section", null, ___EmotionJSX(Skeleton, {
    width: 200,
    height: 20,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 150,
    height: 20,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 150,
    height: 20,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 100,
    height: 20
  }))) : ___EmotionJSX("article", {
    className: container(className)
  }, ___EmotionJSX(Image, {
    size: "contain",
    src: data.picThumbnailUrl,
    width: 70,
    height: 70,
    className: pictureContainer
  }), ___EmotionJSX("section", null, !_isNil(data.name) && ___EmotionJSX("h2", {
    className: packageInfo
  }, data.name), ___EmotionJSX(ColorBatch, {
    type: data.active ? 'success' : 'error',
    className: statusContainer
  }, data.active ? 'Active' : 'Inactive'), ___EmotionJSX(ColorBatch, {
    type: data.visible ? 'success' : 'error',
    className: statusContainer
  }, data.visible ? 'Visible' : 'Invisible'), !_isNil(data.type) && ___EmotionJSX("p", {
    className: infoContainer
  }, "Type:", ___EmotionJSX("span", null, data.type), ' '), ___EmotionJSX("p", {
    className: infoContainer
  }, "Description: ", ___EmotionJSX("span", null, data.description)), !_isNil(data.price) && ___EmotionJSX("p", {
    className: infoContainer
  }, "Price: ", ___EmotionJSX("span", null, currencyByType(data.price, type.CASH)))));
};
PackageInfo.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string
};
export default PackageInfo;