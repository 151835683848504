import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isFunction from "lodash/isFunction";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.is-array.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { checkButtonContainer, iconContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var checkboxFilter = 'invert(63%) sepia(89%) saturate(2820%) hue-rotate(115deg) brightness(91%) contrast(101%)';
var Checkbox = function Checkbox(_ref) {
  var checked = _ref.checked,
    disabled = _ref.disabled,
    onChange = _ref.onChange,
    radio = _ref.radio,
    children = _ref.children,
    className = _ref.className;
  var _useState = useState(checked),
    _useState2 = _slicedToArray(_useState, 2),
    check = _useState2[0],
    setCheck = _useState2[1];
  useEffect(function () {
    setCheck(checked);
  }, [checked]);
  var handleChange = function handleChange() {
    if (disabled) return;
    setCheck(function (prev) {
      _isFunction(onChange) && onChange(!prev);
      return !prev;
    });
  };
  var iconName = radio ? check ? 'radio_button_checked' : 'radio_button_unchecked' : check ? 'check_box' : 'check_box_outline_blank';
  var colorClassName = radio && check ? 'primaryLight' : 'grayLight';
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: "".concat(checkButtonContainer(className, disabled), " ").concat(disabled ? 'disabled' : ''),
    onClick: handleChange
  }, ___EmotionJSX(Icon, {
    iconName: iconName,
    material: true,
    outline: true,
    twoTone: !radio && check,
    color: colorClassName,
    filter: !radio && check && checkboxFilter,
    className: iconContainer
  }), children);
};
Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  radio: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
export default Checkbox;