import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.object.assign.js";
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withTheme } from 'emotion-theming';
import Ripple from '../Ripple';
import { Icon } from '../Icon';
import { menuItem, menuItemText, menuItemContent } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Menu = function Menu(props) {
  var label = props.label,
    icon = props.icon,
    url = props.url,
    theme = props.theme,
    _props$iconType = props.iconType,
    iconType = _props$iconType === void 0 ? {
      material: true
    } : _props$iconType;
  return ___EmotionJSX(NavLink, {
    to: url,
    className: menuItem(theme)
  }, ___EmotionJSX(Ripple, {
    backColor: "secondaryPressed",
    className: menuItemContent
  }, icon && ___EmotionJSX(Icon, _extends({
    size: 27,
    iconName: icon
  }, iconType)), ___EmotionJSX("span", {
    className: menuItemText
  }, label)));
};
Menu.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  theme: PropTypes.object,
  iconType: PropTypes.object
};
export default withTheme(Menu);