import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isFunction from "lodash/isFunction";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { useState, useRef, useEffect } from 'react';
import hash from 'object-hash';
import { outputValue, removeNodes, prepareValue } from './utils';
export var useDropDown = function useDropDown(_ref) {
  var initValue = _ref.value,
    options = _ref.options,
    onChange = _ref.onChange,
    onBlur = _ref.onBlur,
    open = _ref.open,
    alwaysFocused = _ref.alwaysFocused,
    multiSelect = _ref.multiSelect,
    disabled = _ref.disabled,
    mapValue = _ref.mapValue,
    _ref$displayKey = _ref.displayKey,
    displayKey = _ref$displayKey === void 0 ? 'label' : _ref$displayKey,
    _ref$uniqueKey = _ref.uniqueKey,
    uniqueKey = _ref$uniqueKey === void 0 ? 'value' : _ref$uniqueKey,
    _ref$placeholder = _ref.placeholder,
    placeholder = _ref$placeholder === void 0 ? '' : _ref$placeholder,
    noSimplify = _ref.noSimplify,
    renderSelected = _ref.renderSelected;
  var selectedValue = prepareValue({
    value: initValue,
    options: options,
    multiSelect: multiSelect,
    uniqueKey: uniqueKey
  });
  var _useState = useState(selectedValue),
    _useState2 = _slicedToArray(_useState, 2),
    selected = _useState2[0],
    setSelected = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    isOpen = _useState4[0],
    setIsOpen = _useState4[1];
  var selectRef = useRef({
    parentElement: null,
    offsetWidth: 0
  });
  var isDisabled = !(options !== null && options !== void 0 && options.length) || disabled;
  useEffect(function () {
    open && setIsOpen(true);
  }, []);
  useEffect(function () {
    setTimeout(function () {
      var _selectRef$current;
      alwaysFocused && isOpen && ((_selectRef$current = selectRef.current) === null || _selectRef$current === void 0 || (_selectRef$current = _selectRef$current.parentElement) === null || _selectRef$current === void 0 ? void 0 : _selectRef$current.focus());
    });
  });
  useEffect(function () {
    setSelected(selectedValue);
  }, [hash({
    selectedValue: removeNodes(selectedValue, displayKey, uniqueKey)
  })]);
  var openClose = function openClose() {
    return !isDisabled && setIsOpen(function (prev) {
      return !prev;
    });
  };
  var areEquals = function areEquals(source, value) {
    // Check if the values are defined because isEqual with 2 undefined returns true
    var areDef = !_isNil(source[uniqueKey]) && !_isNil(value[uniqueKey]);
    var areSame = _isEqual(source[uniqueKey], value[uniqueKey]);
    return areDef && areSame;
  };
  var handleBlur = function handleBlur(_ref2) {
    var currentTarget = _ref2.currentTarget;
    return setTimeout(function () {
      if (!currentTarget.contains(document.activeElement) && isOpen) {
        setIsOpen(false);
        onBlur && onBlur();
      }
    });
  };
  var handleSelect = function handleSelect(newValue) {
    if (multiSelect) {
      if (_isNil(newValue)) return changeValue([]);
      var selectedUnique = selected === null || selected === void 0 ? void 0 : selected.filter(function (el) {
        return !_isNil(el);
      });
      var valueIndex = selectedUnique.findIndex(function (e) {
        return areEquals(e, newValue);
      });
      if (valueIndex === -1) return changeValue([].concat(_toConsumableArray(selectedUnique), [newValue]));
      selectedUnique.splice(valueIndex, 1);
      return changeValue(selectedUnique);
    }
    setIsOpen(false);
    return changeValue(newValue);
  };
  var handleClear = function handleClear(e) {
    e === null || e === void 0 || e.stopPropagation();
    handleSelect(null);
  };
  var changeValue = function changeValue(newValue) {
    var _selectRef$current2;
    if (_isEqual(newValue, selected)) return;
    setSelected(newValue);
    setTimeout(function () {
      return onChange(outputValue(newValue, displayKey, mapValue, !noSimplify));
    });

    // Focus the dropdown so it can trigger onBlur function which will check for error
    // This is for the case where the user open the dropdown and do not select nothing (if the field is required is should show error)
    isOpen && ((_selectRef$current2 = selectRef.current) === null || _selectRef$current2 === void 0 || (_selectRef$current2 = _selectRef$current2.parentElement) === null || _selectRef$current2 === void 0 ? void 0 : _selectRef$current2.focus());
  };
  var getOptionValue = function getOptionValue(val) {
    return val && val[displayKey];
  };
  var hasSelection = multiSelect ? selected === null || selected === void 0 ? void 0 : selected.length : !_isEmpty(selected);
  var displayValue = _isFunction(renderSelected) ? renderSelected(selected) : multiSelect ? selected === null || selected === void 0 ? void 0 : selected.map(getOptionValue).join(', ') : getOptionValue(selected);
  var calculateDisplayValue = function calculateDisplayValue() {
    return hasSelection ? displayValue : placeholder;
  };
  return {
    openClose: openClose,
    handleBlur: handleBlur,
    handleSelect: handleSelect,
    handleClear: handleClear,
    isOpen: isOpen,
    selected: selected,
    displayValue: displayValue,
    selectRef: selectRef,
    calculateDisplayValue: calculateDisplayValue
  };
};