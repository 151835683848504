import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { setAuthConfig, AUTH_EVENTS_TYPES } from '@oneecosystem/authenticate';
import BaseApp from './screens/App/App';
import Logout from './screens/Public/Logout';
import RedirectCallback from './screens/Public/RedirectCallback';
import { Theme, AuthProvider, LoadFonts } from './components/UI';
import initGlobalStyles from './assets/css/globalCSS';
import { trackGeoLocation } from './services/settings';
import { jsx as ___EmotionJSX } from "@emotion/react";
setAuthConfig(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
  identity: {
    domain: keycloakUrl,
    clientId: 'OneLife'
  },
  oneLifeDomain: apiUrl
}, AUTH_EVENTS_TYPES.SET_TOKEN, function (token) {
  axios.defaults.headers.common.Authorization = "Bearer ".concat(token.access_token);
}), AUTH_EVENTS_TYPES.LOGIN, trackGeoLocation), AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD, function () {
  trackGeoLocation();
  window.location = '/dashboard';
}), AUTH_EVENTS_TYPES.RENEW_SESSION, trackGeoLocation), AUTH_EVENTS_TYPES.LOGOUT, function () {
  window.location = '/';
}));
var App = function App() {
  return ___EmotionJSX(Theme, {
    initGlobalStyles: initGlobalStyles
  }, ___EmotionJSX(Router, null, ___EmotionJSX(AuthProvider, {
    renderLogout: Logout,
    renderRedirect: RedirectCallback
  }, ___EmotionJSX(BaseApp, null), ___EmotionJSX(LoadFonts, null))));
};
render(___EmotionJSX(App, null), document.getElementById('administration-root'));