function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _isNil from "lodash/isNil";
var _excluded = ["label"];
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Input, Button, Row, Col, Skeleton, CheckButton, Icon, Tooltip } from '../../UI';
import DatePicker from '../../UI/DatePicker';
import { stringToBoolean } from '../../../utils';
import { inputContainer, booleanContainer, questionImage, imageContainer, tooltipStyle } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TYPE = {
  BOOL: 'boolean',
  DATE: 'date',
  DATE_RANGE: 'date-range',
  INPUT: 'input'
};
export var InputField = function InputField(_ref) {
  var _mapComponents$type;
  var onClick = _ref.onClick,
    valueProp = _ref.value,
    label = _ref.label,
    type = _ref.type,
    _ref$componentProps = _ref.componentProps,
    componentProps = _ref$componentProps === void 0 ? {} : _ref$componentProps,
    rowProps = _ref.rowProps,
    description = _ref.description;
  var _useState = useState(valueProp),
    _useState2 = _slicedToArray(_useState, 2),
    value = _useState2[0],
    setValue = _useState2[1];
  var internalLabel = componentProps.label,
    compProps = _objectWithoutProperties(componentProps, _excluded);
  var theme = useTheme();
  useEffect(function () {
    setValue(valueProp);
  }, [valueProp]);
  var BooleanComponent = ___EmotionJSX("div", {
    className: booleanContainer
  }, ___EmotionJSX(CheckButton, _extends({
    checked: stringToBoolean(value),
    onChange: function onChange(val) {
      return setValue("".concat(val));
    }
  }, compProps), internalLabel));
  var DateRangeComponent = ___EmotionJSX("div", {
    className: booleanContainer
  }, ___EmotionJSX(DatePicker, _extends({
    onChange: setValue
  }, compProps)));
  var DateComponent = ___EmotionJSX("div", {
    className: booleanContainer
  }, ___EmotionJSX(Input, _extends({
    value: value,
    onChange: function onChange(_ref2) {
      var target = _ref2.target;
      return setValue(target.value);
    },
    className: inputContainer
  }, compProps)));
  var InputComponent = ___EmotionJSX(Input, _extends({
    value: value,
    onChange: function onChange(_ref3) {
      var target = _ref3.target;
      return setValue(target.value);
    },
    className: inputContainer
  }, compProps));
  var mapFunctions = _defineProperty(_defineProperty(_defineProperty({}, TYPE.BOOL, function () {
    return onClick(value);
  }), TYPE.DATE, function () {
    return value.length && onClick(value);
  }), TYPE.INPUT, function () {
    return value.length && onClick(value);
  });
  var mapComponents = _defineProperty(_defineProperty(_defineProperty({}, TYPE.BOOL, BooleanComponent), TYPE.DATE, DateComponent), TYPE.DATE_RANGE, DateRangeComponent);
  return ___EmotionJSX(Row, _extends({
    align: "center"
  }, rowProps), ___EmotionJSX(Col, {
    md: 4
  }, ___EmotionJSX("h4", null, label)), ___EmotionJSX(Col, {
    md: 7,
    dFlex: true
  }, _isNil(value) ? ___EmotionJSX(Skeleton, {
    width: "100%",
    height: 50
  }) : (_mapComponents$type = mapComponents[type]) !== null && _mapComponents$type !== void 0 ? _mapComponents$type : InputComponent, ___EmotionJSX(Button, {
    onClick: mapFunctions[type]
  }, "Save")), ___EmotionJSX(Col, {
    md: 1
  }, ___EmotionJSX("div", {
    className: imageContainer
  }, ___EmotionJSX(Tooltip, {
    content: ___EmotionJSX("div", {
      className: tooltipStyle
    }, description),
    position: "right"
  }, ___EmotionJSX(Icon, {
    iconName: "fa fa-question-circle",
    className: questionImage,
    color: theme.grayLight
  })))));
};
InputField.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string,
  componentProps: PropTypes.object,
  rowProps: PropTypes.object,
  description: PropTypes.string
};