import _isArray from "lodash/isArray";
import _invert from "lodash/invert";
/* eslint-disable no-bitwise */
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
export var extractBinary = function extractBinary(value, possibleValues) {
  return Object.values(possibleValues).filter(function (el) {
    return (el & value) === Number(el);
  }).map(function (el) {
    return _invert(possibleValues)[el];
  });
};
export var accumulateBinary = function accumulateBinary(value, possibleValues) {
  if (!_isArray(value)) return possibleValues[value];
  return value.reduce(function (acc, val) {
    return acc | possibleValues[val];
  }, 0);
};