import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../UI';
import { hashStyles, hashRow, copyIcon } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TransactionHash = function TransactionHash(props) {
  var transactionHash = props.transactionHash,
    handleCopyHash = props.handleCopyHash;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showHash = _useState2[0],
    setShowHash = _useState2[1];
  return ___EmotionJSX("div", {
    className: hashRow
  }, ___EmotionJSX("div", {
    role: "button",
    tabIndex: -1,
    className: hashStyles,
    onClick: function onClick() {
      return setShowHash(true);
    }
  }, showHash ? transactionHash : "".concat(transactionHash === null || transactionHash === void 0 ? void 0 : transactionHash.slice(0, 10), "...")), ___EmotionJSX(Icon, {
    material: true,
    iconName: "content_copy",
    className: copyIcon,
    onClick: function onClick() {
      return handleCopyHash(transactionHash);
    }
  }));
};
TransactionHash.propTypes = {
  transactionHash: PropTypes.string,
  handleCopyHash: PropTypes.func
};
export default TransactionHash;