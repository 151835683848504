function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _isFunction from "lodash/isFunction";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Row, Col, Button, Image } from '../../UI';
import avatar from '../../../assets/images/package.png';
import CounterInput from '../../Orders/CounterInput';
import { containerShop, containerPackageShop, shopPackageCardTop, pictureContainer, shopPackageCardAdd, buttonAddToCard, titlePackage } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Package = function Package(props) {
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onAddToCard = props.onAddToCard,
    onClick = props.onClick,
    className = props.className;
  var name = item.name,
    price = item.price,
    picThumbnailUrl = item.picThumbnailUrl,
    catalogueItemId = item.catalogueItemId;
  var theme = useTheme();
  var _useState = useState(1),
    _useState2 = _slicedToArray(_useState, 2),
    amount = _useState2[0],
    setAmount = _useState2[1];
  var handleLinkClick = function handleLinkClick(e) {
    return e.preventDefault();
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: containerShop(className),
    onClick: onClick
  }, ___EmotionJSX(Link, _extends({
    to: "/packages/package/".concat(catalogueItemId),
    className: "package-card-info ".concat(shopPackageCardTop(theme))
  }, _isFunction(onClick) && {
    onClick: handleLinkClick
  }), ___EmotionJSX("div", {
    className: containerPackageShop
  }, ___EmotionJSX(Image, {
    size: "contain",
    src: picThumbnailUrl !== null && picThumbnailUrl !== void 0 ? picThumbnailUrl : avatar,
    width: 60,
    height: 60,
    className: pictureContainer
  }), ___EmotionJSX("h4", {
    className: titlePackage
  }, name, ___EmotionJSX("br", null), "\u20AC", price.toFixed(2)))), onAddToCard && ___EmotionJSX("div", {
    className: "package-card-add ".concat(shopPackageCardAdd(theme))
  }, ___EmotionJSX(Row, {
    align: "center",
    horizontalGap: 4,
    verticalGap: 4,
    justify: "space-between"
  }, ___EmotionJSX(Col, {
    basis: "auto",
    grow: "0"
  }, ___EmotionJSX(CounterInput, {
    count: 1,
    min: 1,
    max: 200,
    onCountChange: function onCountChange(count) {
      return setAmount(count);
    }
  })), ___EmotionJSX(Col, {
    basis: "auto",
    grow: "0"
  }, ___EmotionJSX(Button, {
    className: buttonAddToCard(theme),
    secondary: true,
    onClick: function onClick() {
      return onAddToCard(_objectSpread(_objectSpread({}, item), {}, {
        amount: amount
      }));
    }
  }, "Add to cart")))));
};
Package.propTypes = {
  item: PropTypes.any,
  onAddToCard: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string
};
export default Package;