import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { css } from 'emotion';
export var tabs = css({
  width: '100%'
});
export var tabsListContainerWrapper = function tabsListContainerWrapper(showArrows) {
  return css({
    position: 'relative',
    overflow: 'hidden'
  }, showArrows && {
    paddingRight: 90
  });
};
export var tabsListContainer = function tabsListContainer(theme, tabListStyle) {
  return css(_objectSpread(_objectSpread({
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: -50,
    paddingBottom: 50,
    display: 'flex',
    width: '100%',
    backgroundColor: theme.white,
    paddingLeft: 25
  }, tabListStyle), {}, {
    '& > *': {
      display: 'inline-block'
    }
  }) // isDragging && {
  //   '& *': {
  //     cursor: 'grabbing',
  //     '&:hover': {
  //       backgroundColor: 'transparent',
  //     },
  //   },
  // }
  );
};
var activeTab = function activeTab(theme) {
  return {
    fontWeight: 600,
    color: theme.textLightPrimary,
    borderBottom: "2px solid ".concat(theme.primary)
  };
};
export var tabContainer = function tabContainer(active, theme) {
  return css({
    cursor: 'pointer',
    padding: '24px 24px',
    fontSize: 14,
    fontWeight: 400,
    color: theme.textLightSecondary,
    backgroundColor: theme.white
  }, active && activeTab(theme));
};
export var tabLinkContainer = function tabLinkContainer(theme) {
  return css({
    '&.active > *': activeTab(theme)
  });
};
var arrowStyle = function arrowStyle(theme, isDisabled) {
  return {
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 26,
    color: isDisabled ? theme.textLightDisabled : theme.textLightPrimary,
    borderRadius: '50%',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  };
};
export var scrollLeftIcon = function scrollLeftIcon(theme, isDisabled) {
  return css(_objectSpread({
    position: 'absolute',
    top: 0,
    right: 44
  }, arrowStyle(theme, isDisabled)));
};
export var scrollRightIcon = function scrollRightIcon(theme, isDisabled) {
  return css(_objectSpread({
    position: 'absolute',
    top: 0,
    right: 0
  }, arrowStyle(theme, isDisabled)));
};
export var tabsContentClass = css({});