function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Col, Dropdown, Modal, ReactSelectDropdown, Row, Spinner } from '../../UI';
import CounterInput from '../../Orders/CounterInput';
import useAccounts from '../../../hooks/useAccounts';
import { accountsOptions } from '../../../enums/account';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AddVotesModal = function AddVotesModal(props) {
  var _filterState$count, _filterState$account;
  var modalRef = props.modalRef,
    onSubmit = props.onSubmit;
  var _useAccounts = useAccounts(),
    searchAccountRef = _useAccounts.searchAccountRef,
    hasMore = _useAccounts.hasMore,
    fetchAccounts = _useAccounts.fetchAccounts;
  var _useState = useState({
      count: 1,
      account: null
    }),
    _useState2 = _slicedToArray(_useState, 2),
    filterState = _useState2[0],
    setFilterState = _useState2[1];
  var handleSubmit = function handleSubmit() {
    onSubmit(filterState);
    setFilterState({
      count: 1,
      account: null
    });
  };
  var handleChangeAccount = function handleChangeAccount(value) {
    setFilterState(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, {
        account: value
      });
    });
  };
  var handleChangeCount = function handleChangeCount(value) {
    setFilterState(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, {
        count: value
      });
    });
  };
  var MenuList = function MenuList(_ref) {
    var children = _ref.children;
    return ___EmotionJSX("div", {
      style: {
        maxHeight: '300px',
        overflow: 'auto'
      }
    }, ___EmotionJSX(InfiniteScroll, {
      loadMore: function loadMore() {
        fetchAccounts({
          page: searchAccountRef.current.pageIndex + 1
        });
      },
      hasMore: hasMore,
      loader: ___EmotionJSX(Spinner, {
        key: 0
      }),
      initialLoad: false,
      useWindow: false
    }, children));
  };
  var handleModalClose = function handleModalClose() {
    setFilterState({
      count: 1,
      account: null
    });
  };
  return ___EmotionJSX(Modal, {
    ref: modalRef,
    large: true,
    modalTitle: "Add Votes",
    onClose: handleModalClose
  }, ___EmotionJSX(Row, {
    gap: 8,
    align: "center"
  }, ___EmotionJSX(Col, {
    xl: 3,
    lg: 3,
    md: 12,
    sm: 12,
    xs: 12
  }, ___EmotionJSX(Dropdown, {
    noClear: true,
    value: accountsOptions[0],
    options: accountsOptions,
    onChange: function onChange(_ref2) {
      var value = _ref2.value;
      searchAccountRef.current.searchCriteria = value;
    }
  })), ___EmotionJSX(Col, {
    xl: 5,
    lg: 5,
    md: 12,
    sm: 12,
    xs: 12
  }, ___EmotionJSX(ReactSelectDropdown, {
    async: true,
    cachOptions: true,
    defaultOptions: true,
    placeholder: "Search account",
    loadOptions: function loadOptions(inputValue, callback) {
      fetchAccounts({
        inputValue: inputValue,
        callback: callback
      });
    },
    components: {
      MenuList: MenuList
    },
    onChange: handleChangeAccount
  })), ___EmotionJSX(Col, {
    xl: 2,
    lg: 2,
    md: 12,
    sm: 12,
    xs: 12
  }, ___EmotionJSX(CounterInput, {
    count: (_filterState$count = filterState.count) !== null && _filterState$count !== void 0 ? _filterState$count : 1,
    min: 1,
    onCountChange: handleChangeCount,
    width: 80
  })), ___EmotionJSX(Col, {
    xl: 2,
    lg: 2,
    md: 12,
    sm: 12,
    xs: 12
  }, ___EmotionJSX(Button, {
    onClick: handleSubmit,
    disabled: !((_filterState$account = filterState.account) !== null && _filterState$account !== void 0 && _filterState$account.id)
  }, "Submit"))));
};
AddVotesModal.propTypes = {
  modalRef: PropTypes.object,
  onSubmit: PropTypes.func
};
export default AddVotesModal;