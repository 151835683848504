import _isFunction from "lodash/isFunction";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Row, Col, Image, Icon, pluralizeMonths } from '../../UI';
import CounterInput from '../CounterInput';
import avatar from '../../../assets/images/package.png';
import { currencyByType } from '../../../utils/displayData';
import { type } from '../../../enums/wallets';
import { pictureContainer, shoppingCartItem, shoppingCartPackageName, shoppingCartCheckboxPercent, shoppingCartPackageWrap, shoppingCartPackagePrice } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CartItem = function CartItem(props) {
  var _item$picThumbnailUrl, _item$price;
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    supportMultiple = props.supportMultiple,
    onDelete = props.onDelete,
    onHandleCounter = props.onHandleCounter;
  var theme = useTheme();
  var getDurationLabel = function getDurationLabel(duration) {
    return pluralizeMonths(duration, true);
  };
  return ___EmotionJSX("div", {
    className: shoppingCartItem(theme)
  }, ___EmotionJSX(Row, {
    align: "center",
    horizontalGap: 16,
    verticalGap: 16
  }, ___EmotionJSX(Image, {
    size: "contain",
    src: (_item$picThumbnailUrl = item.picThumbnailUrl) !== null && _item$picThumbnailUrl !== void 0 ? _item$picThumbnailUrl : avatar,
    width: 110,
    height: 100,
    className: pictureContainer
  }), ___EmotionJSX(Col, {
    xl: 4,
    lg: 4,
    sm: 5,
    xs: 6
  }, item.vendor && ___EmotionJSX("h4", null, item.vendor), ___EmotionJSX("h2", {
    className: shoppingCartPackageName(theme)
  }, item.name), item.duration && ___EmotionJSX("p", null, item.duration, " ", "".concat(getDurationLabel(item.duration)))), supportMultiple && ___EmotionJSX(Col, {
    xl: 2,
    lg: 2,
    sm: 2,
    xs: 3
  }, _isFunction(onHandleCounter) ? ___EmotionJSX(CounterInput, {
    count: item.amount,
    min: 1,
    max: 200,
    onCountChange: function onCountChange(val) {
      return onHandleCounter(item.uuid, val);
    }
  }) : ___EmotionJSX("div", {
    className: shoppingCartCheckboxPercent(theme)
  }, "x", item.amount)), ___EmotionJSX(Col, {
    xl: 3,
    lg: 3,
    sm: 5,
    xs: 6
  }, ___EmotionJSX("div", {
    className: shoppingCartPackageWrap
  }, ___EmotionJSX("div", {
    className: shoppingCartPackagePrice(theme)
  }, currencyByType((_item$price = item.price) === null || _item$price === void 0 ? void 0 : _item$price.toFixed(2), type.CASH)), item.amount && ___EmotionJSX("div", {
    className: shoppingCartCheckboxPercent(theme)
  }, "total: \u20AC", item.amount * item.price))), ___EmotionJSX(Col, {
    xl: 1,
    lg: 1,
    sm: 1,
    xs: 2
  }, _isFunction(onDelete) && ___EmotionJSX(Icon, {
    size: "1.78rem",
    iconName: "far fa-trash-alt",
    color: theme.grayLight,
    onClick: function onClick() {
      return onDelete(item.uuid);
    }
  }))));
};
CartItem.propTypes = {
  item: PropTypes.any,
  supportMultiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onCheck: PropTypes.func,
  onHandleCounter: PropTypes.func
};
export default CartItem;