import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { switchButtonContainer, switchButtonInput, switchButtonLabel, switchButtonInner, switchButtonCircle } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SwitchButton = function SwitchButton(props) {
  var id = props.id,
    _props$style = props.style,
    style = _props$style === void 0 ? {} : _props$style,
    value = props.value,
    _props$onChange = props.onChange,
    onChange = _props$onChange === void 0 ? function () {} : _props$onChange,
    width = props.width,
    activeText = props.activeText,
    inactiveText = props.inactiveText;
  var theme = useTheme();
  var _useState = useState(!!value),
    _useState2 = _slicedToArray(_useState, 2),
    isChecked = _useState2[0],
    setIsChecked = _useState2[1];
  var inputId = id || Math.random();
  useEffect(function () {
    setIsChecked(value);
  }, [value]);
  var handleChange = function handleChange() {
    return setIsChecked(function (prev) {
      onChange(!prev);
      return !prev;
    });
  };
  return ___EmotionJSX("div", {
    className: switchButtonContainer(width)
  }, ___EmotionJSX("input", {
    className: switchButtonInput,
    type: "checkbox",
    name: "onOffSwitch",
    onChange: handleChange,
    id: inputId,
    checked: isChecked
  }), ___EmotionJSX("label", {
    className: switchButtonLabel,
    htmlFor: inputId
  }, ___EmotionJSX("span", {
    className: switchButtonInner(isChecked, activeText, inactiveText, style, theme)
  }), ___EmotionJSX("span", {
    className: switchButtonCircle(isChecked, width, style, theme)
  })));
};
SwitchButton.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  activeText: PropTypes.string,
  inactiveText: PropTypes.string
};
export default SwitchButton;