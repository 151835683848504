import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _sortBy from "lodash/sortBy";
import _isNil from "lodash/isNil";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { Image, Button, Skeleton } from '../../UI';
import { copyToClipboard } from '../../../utils';
import { type } from '../../../enums/wallets';
import { currencyByType } from '../../../utils/displayData';
import { container, pictureContainer, packageInfo, infoContainer, marginBottom4, buttonPackagesInfo, infoButtonContainer, detailsContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var OrderPackage = function OrderPackage(props) {
  var packagesProp = props.packages,
    className = props.className;
  var packages = _sortBy(packagesProp, 'price');
  var _useState = useState(packages !== null && packages !== void 0 && packages.length ? packages.slice(0, 10) : null),
    _useState2 = _slicedToArray(_useState, 2),
    packagesList = _useState2[0],
    setPackagesList = _useState2[1];
  useEffect(function () {
    setPackagesList(packages !== null && packages !== void 0 && packages.length ? packages.slice(0, 10) : []);
  }, [hash({
    packages: packages
  })]);
  var handleShowMore = function handleShowMore() {
    return setPackagesList(function (prev) {
      return prev.concat(packages.slice(prev.length, prev.length + 10));
    });
  };
  return _isNil(packagesList) ? Array(4).fill().map(function (el, ind, arr) {
    return ___EmotionJSX("div", {
      key: ind
    }, ___EmotionJSX("article", {
      className: container(className)
    }, ___EmotionJSX(Skeleton, {
      width: 70,
      height: 70,
      circle: true,
      className: pictureContainer
    }), ___EmotionJSX("section", null, ___EmotionJSX(Skeleton, {
      width: 150,
      height: 30,
      className: marginBottom4
    }), ___EmotionJSX(Skeleton, {
      width: 220,
      height: 20,
      className: marginBottom4
    }), ___EmotionJSX(Skeleton, {
      width: 250,
      height: 20,
      className: marginBottom4
    }), ___EmotionJSX(Skeleton, {
      width: 270,
      height: 20
    }))), arr.length - 1 !== ind && ___EmotionJSX("hr", null));
  }) : ___EmotionJSX(React.Fragment, null, packagesList === null || packagesList === void 0 ? void 0 : packagesList.map(function (item, ind, arr) {
    return ___EmotionJSX("div", {
      key: ind
    }, ___EmotionJSX("article", {
      className: container(className)
    }, ___EmotionJSX(Image, {
      size: "contain",
      src: item.picThumbnailUrl,
      width: 70,
      height: 70,
      className: pictureContainer
    }), ___EmotionJSX("section", {
      className: detailsContainer
    }, ___EmotionJSX("div", {
      className: infoButtonContainer
    }, ___EmotionJSX("h2", {
      className: packageInfo
    }, item.name)), ___EmotionJSX("div", {
      className: infoContainer
    }, ___EmotionJSX("p", null, "Price:"), ___EmotionJSX("p", null, "".concat(currencyByType(item.price, type.CASH)))), ___EmotionJSX("div", {
      className: infoContainer
    }, ___EmotionJSX("p", null, "Gift code:"), " ", ___EmotionJSX("p", null, item.giftCode))), ___EmotionJSX(Button, {
      className: buttonPackagesInfo,
      onClick: function onClick() {
        return copyToClipboard(item.giftCode, 'Successfully copied gift code.');
      }
    }, "Copy code")), arr.length - 1 !== ind && ___EmotionJSX("hr", null));
  }), packages.length > 10 && (packagesList === null || packagesList === void 0 ? void 0 : packagesList.length) < packages.length && ___EmotionJSX(Button, {
    onClick: handleShowMore
  }, "Show More"));
};
OrderPackage.propTypes = {
  packages: PropTypes.array,
  className: PropTypes.string
};
export default OrderPackage;