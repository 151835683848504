import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { css } from 'emotion';
export var networkTree = css({
  padding: '40px'
});
export var networkTreePortlet = function networkTreePortlet(theme) {
  return css({
    border: "1px solid ".concat(theme.lightGray),
    borderRadius: '3px'
  });
};
export var networkTreePortletHeader = function networkTreePortletHeader(theme) {
  return css({
    backgroundColor: theme.white,
    padding: '32px 30px 24px',
    borderBottom: "1px solid ".concat(theme.borderLight),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'start'
    }
  });
};
export var networkTreeFilter = css({
  width: '100%'
});
export var networkTreePortletBody = function networkTreePortletBody(theme) {
  return css({
    backgroundColor: theme.white,
    padding: '32px',
    position: 'relative'
  });
};
export var networkTreePortletBodyButton = function networkTreePortletBodyButton(position) {
  return css(_defineProperty({
    position: 'absolute',
    top: '32px'
  }, position, '32px'));
};
export var networkTreeSectionHeadingSecondaryLight = function networkTreeSectionHeadingSecondaryLight(theme) {
  return css({
    fontSize: '18px',
    letterSpacing: '-0.2px',
    color: theme.grayDark,
    fontWeight: 400,
    width: '260px',
    paddingRight: '20px',
    '@media (max-width: 767px)': {
      marginBottom: '8px'
    }
  });
};