function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import ValuePercent from '../ValuePercent';
import NetworkBonusBvPointsTable from '../NetworkBonusBVPointsTable';
import { bonusLastRoundBox, bonusLastRoundBoxBody, bonusLastRoundBoxHeader, bonusLastRoundBoxHeaderAmount, bonusLastRoundBoxHeaderDate, bonusLastRoundContainer, sectionHeadingLight, sectionHeadingSecondary } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkBonusLastRound = function NetworkBonusLastRound(props) {
  var _currentBonus$package, _currentBonus$network;
  var _props$currentBonus = props.currentBonus,
    currentBonus = _props$currentBonus === void 0 ? {} : _props$currentBonus,
    label = props.label,
    _props$isCurrentRound = props.isCurrentRound,
    isCurrentRound = _props$isCurrentRound === void 0 ? false : _props$isCurrentRound,
    detailsRootPath = props.detailsRootPath;
  var theme = useTheme();
  var getTargetDateTo = function getTargetDateTo() {
    if (!isCurrentRound) return moment(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.targetDateTo).format('DD/MM/YYYY');
    var currentBonusTargetDateTo = new Date(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.targetDateTo);
    currentBonusTargetDateTo.setDate(currentBonusTargetDateTo.getDate() + (7 - currentBonusTargetDateTo.getDay() + 1));
    return moment(currentBonusTargetDateTo).format('DD/MM/YYYY');
  };
  return currentBonus !== null && currentBonus !== void 0 && currentBonus.id ? ___EmotionJSX("div", {
    className: bonusLastRoundContainer
  }, ___EmotionJSX("h3", {
    className: sectionHeadingSecondary
  }, label), ___EmotionJSX("div", {
    className: bonusLastRoundBox(theme)
  }, ___EmotionJSX("div", {
    className: bonusLastRoundBoxHeader(theme)
  }, ___EmotionJSX("p", {
    className: bonusLastRoundBoxHeaderDate
  }, getTargetDateTo()), ___EmotionJSX("div", {
    className: bonusLastRoundBoxHeaderAmount
  }, currentBonus !== null && currentBonus !== void 0 && (_currentBonus$package = currentBonus.packageInfoForRound) !== null && _currentBonus$package !== void 0 && _currentBonus$package.hasNetworkBonus ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h3", {
    className: sectionHeadingLight
  }, "Bonuses earned"), ___EmotionJSX(ValuePercent, {
    value: currentBonus === null || currentBonus === void 0 || (_currentBonus$network = currentBonus.networkBonus) === null || _currentBonus$network === void 0 ? void 0 : _currentBonus$network.amount
  })) : ___EmotionJSX("h3", {
    className: sectionHeadingLight
  }, "No bonus for this round"))), ___EmotionJSX("div", {
    className: bonusLastRoundBoxBody
  }, ___EmotionJSX(NetworkBonusBvPointsTable, {
    content: _objectSpread(_objectSpread({}, currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.networkBonus), {}, {
      id: currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.id
    }),
    isCurrentRound: isCurrentRound,
    detailsRootPath: detailsRootPath
  })))) : null;
};
NetworkBonusLastRound.propTypes = {
  currentBonus: PropTypes.object,
  label: PropTypes.string,
  isCurrentRound: PropTypes.bool,
  detailsRootPath: PropTypes.string
};
export default NetworkBonusLastRound;