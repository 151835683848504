import _isNil from "lodash/isNil";
import _isFunction from "lodash/isFunction";
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.assign.js";
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import moment from 'moment';
import { Image, Icon } from '../../UI';
import { copyToClipboard, validDate, currencyByType } from '../../../utils';
import { type } from '../../../enums/wallets';
import avatar from '../../../assets/images/package.png';
import { containerShop, containerPackageShop, shopPackageCardTop, pictureContainer, titlePackage, packageCardContainer, actionInputContainer, copyIcon, copyContainer, calendarIcon, dateContainer, academyContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PackageCard = function PackageCard(props) {
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onClick = props.onClick;
  var name = item.name,
    price = item.price,
    picThumbnailUrl = item.picThumbnailUrl,
    catalogueItemId = item.catalogueItemId,
    giftCode = item.giftCode,
    redeemedOn = item.redeemedOn,
    refundedOn = item.refundedOn,
    academyCoinsRewardedOn = item.academyCoinsRewardedOn,
    academyCoinsReward = item.academyCoinsReward;
  var theme = useTheme();
  var handleLinkClick = function handleLinkClick(e) {
    return e.preventDefault();
  };
  var handleCopy = function handleCopy(e) {
    e.preventDefault();
    copyToClipboard(giftCode, 'Successfully copied gift codes.');
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: containerShop(theme),
    onClick: onClick
  }, ___EmotionJSX(Link, _extends({
    to: "/packages/package/".concat(catalogueItemId),
    className: shopPackageCardTop(theme)
  }, _isFunction(onClick) && {
    onClick: handleLinkClick
  }), ___EmotionJSX("div", {
    className: packageCardContainer
  }, ___EmotionJSX("div", {
    className: containerPackageShop
  }, ___EmotionJSX(Image, {
    size: "contain",
    src: picThumbnailUrl !== null && picThumbnailUrl !== void 0 ? picThumbnailUrl : avatar,
    width: 60,
    height: 60,
    className: pictureContainer
  }), ___EmotionJSX("h4", {
    className: titlePackage
  }, name, ___EmotionJSX("br", null), ___EmotionJSX("p", null, "\u20AC", price.toFixed(2)))), ___EmotionJSX("div", {
    className: academyContainer
  }, !_isNil(academyCoinsReward) && ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Academy Coins Reward:"), currencyByType(academyCoinsReward, type.ONE))), ___EmotionJSX("div", {
    className: actionInputContainer
  }, ___EmotionJSX("p", {
    className: copyContainer
  }, giftCode), ___EmotionJSX(Icon, {
    material: true,
    iconName: "content_copy",
    className: copyIcon(theme),
    onClick: handleCopy
  })), validDate(redeemedOn) && ___EmotionJSX("div", {
    className: dateContainer(theme)
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "calendar_today",
    className: calendarIcon(theme)
  }), ___EmotionJSX("span", null, "Used Date:", ' ', ___EmotionJSX("span", null, moment(redeemedOn).format('DD/MM/YYYY, HH:mm:ss')))), validDate(refundedOn) && ___EmotionJSX("div", {
    className: dateContainer(theme)
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "calendar_today",
    className: calendarIcon(theme)
  }), ___EmotionJSX("span", null, "Refund Date:", ' ', ___EmotionJSX("span", null, moment(refundedOn).format('DD/MM/YYYY, HH:mm:ss')))), validDate(academyCoinsRewardedOn) && ___EmotionJSX("div", {
    className: dateContainer(theme)
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "calendar_today",
    className: calendarIcon(theme)
  }), ___EmotionJSX("span", null, "Academy Coins Date:", ' ', ___EmotionJSX("span", null, moment(academyCoinsRewardedOn).format('DD/MM/YYYY, HH:mm:ss')))))));
};
PackageCard.propTypes = {
  item: PropTypes.any,
  onClick: PropTypes.func
};
export default PackageCard;