import _startCase from "lodash/startCase";
import _isNil from "lodash/isNil";
import React from 'react';
import moment from 'moment';
import { afterChange, ipAddressColumn, locationColumn, marginBottom } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var profileHistoryColumns = function profileHistoryColumns(theme) {
  return [{
    name: 'Date',
    value: 'changeDate',
    dateTimeFormat: 'DD/MM/YYYY, HH:mm:ss'
  }, {
    name: 'Type Of Change',
    render: function render(_ref) {
      var type = _ref.type;
      return _startCase(type);
    }
  }, {
    name: 'Before',
    render: function render(row) {
      return ___EmotionJSX("b", null, row.type === 'dateOfBirth' ? moment(row.before).format('DD/MM/YYYY') : row.before);
    }
  }, {
    name: 'Now',
    render: function render(row) {
      return ___EmotionJSX("p", {
        className: afterChange(theme)
      }, row.type === 'dateOfBirth' ? moment(row.after).format('DD/MM/YYYY') : row.after);
    }
  }, {
    name: 'Changed by',
    render: function render(row) {
      return ___EmotionJSX("div", null, ___EmotionJSX("p", null, row.changedByName), ___EmotionJSX("p", null, row.changedByEmail));
    }
  }];
};
export var loginHistoryColumns = function loginHistoryColumns() {
  return [{
    name: 'Date',
    value: 'loginDate',
    dateTimeFormat: 'DD/MM/YYYY, HH:mm:ss'
  }, {
    name: 'Country',
    value: 'locationData.country'
  }, {
    name: 'City',
    value: 'locationData.city'
  }, {
    name: 'Post code',
    value: 'locationData.postcode'
  }, {
    name: 'User ID',
    value: 'userID'
  }, {
    name: 'IP Address',
    render: function render(row, NullItem) {
      var _row$locationData, _row$locationData2, _row$locationData3, _row$locationData4;
      return ___EmotionJSX("div", {
        className: ipAddressColumn
      }, !_isNil(row === null || row === void 0 || (_row$locationData = row.locationData) === null || _row$locationData === void 0 ? void 0 : _row$locationData.firstIpAddress) ? ___EmotionJSX("div", {
        className: marginBottom(12)
      }, ___EmotionJSX("b", null, "First IP address: "), row === null || row === void 0 || (_row$locationData2 = row.locationData) === null || _row$locationData2 === void 0 ? void 0 : _row$locationData2.firstIpAddress) : ___EmotionJSX(NullItem, null), !_isNil(row === null || row === void 0 || (_row$locationData3 = row.locationData) === null || _row$locationData3 === void 0 ? void 0 : _row$locationData3.lastIpAddress) ? ___EmotionJSX("div", {
        className: marginBottom(12)
      }, ___EmotionJSX("b", null, "Last IP address: "), row === null || row === void 0 || (_row$locationData4 = row.locationData) === null || _row$locationData4 === void 0 ? void 0 : _row$locationData4.lastIpAddress) : ___EmotionJSX(NullItem, null));
    }
  }, {
    name: 'Location',
    render: function render(row, NullItem) {
      var _row$locationData5, _row$locationData6, _row$locationData7, _row$locationData8;
      return ___EmotionJSX("div", {
        className: locationColumn
      }, ___EmotionJSX("div", {
        className: marginBottom(12)
      }, !_isNil(row === null || row === void 0 || (_row$locationData5 = row.locationData) === null || _row$locationData5 === void 0 ? void 0 : _row$locationData5.latitude) ? ___EmotionJSX("b", null, "Latitude: ")(row === null || row === void 0 || (_row$locationData6 = row.locationData) === null || _row$locationData6 === void 0 ? void 0 : _row$locationData6.latitude) : ___EmotionJSX(NullItem, null)), ___EmotionJSX("div", {
        className: marginBottom(12)
      }, !_isNil(row === null || row === void 0 || (_row$locationData7 = row.locationData) === null || _row$locationData7 === void 0 ? void 0 : _row$locationData7.longitude) ? ___EmotionJSX("b", null, "Longitude: ")(row === null || row === void 0 || (_row$locationData8 = row.locationData) === null || _row$locationData8 === void 0 ? void 0 : _row$locationData8.longitude) : ___EmotionJSX(NullItem, null)));
    }
  }];
};
export var breadcrumbsProfileHistory = function breadcrumbsProfileHistory(userId) {
  return [{
    url: '/profiles',
    label: 'Profiles'
  }, {
    url: "/profiles/profile/".concat(userId, "/profile-info"),
    label: 'Overview'
  }, {
    label: 'Profile'
  }];
};
export var breadcrumbsLoginHistory = function breadcrumbsLoginHistory(userId) {
  return [{
    url: '/profiles',
    label: 'Profiles'
  }, {
    url: "/profiles/profile/".concat(userId, "/profile-info"),
    label: 'Overview'
  }, {
    label: 'Login'
  }];
};