import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _pick from "lodash/pick";
import _omit from "lodash/omit";
import _isNil from "lodash/isNil";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import moment from 'moment';
import queryString from 'query-string';
export var getSubscriptionPlanModel = function getSubscriptionPlanModel(responseData) {
  return _objectSpread(_objectSpread({}, responseData), {}, {
    planActive: responseData.active
  });
};
export var getSubscriptionListItemsModel = function getSubscriptionListItemsModel(catalogueItem) {
  var subscriptionListItems = [];
  (catalogueItem.subscriptionPlans || []).forEach(function (plan) {
    subscriptionListItems = [].concat(_toConsumableArray(subscriptionListItems), [_objectSpread(_objectSpread({
      subscriptionCatalogueItemId: catalogueItem.id
    }, getSubscriptionPlanModel(plan)), {}, {
      visible: catalogueItem.visible,
      active: catalogueItem.active,
      picThumbnailUrl: catalogueItem.picThumbnailUrl,
      color: catalogueItem.color
    })]);
  });
  return subscriptionListItems;
};
export var getFlattedSubscriptionsListByPlans = function getFlattedSubscriptionsListByPlans(subscriptionsCatalogueItems) {
  var subscriptionItems = [];
  subscriptionsCatalogueItems === null || subscriptionsCatalogueItems === void 0 || subscriptionsCatalogueItems.forEach(function (item) {
    subscriptionItems = [].concat(_toConsumableArray(subscriptionItems), _toConsumableArray(getSubscriptionListItemsModel(item)));
  });
  return subscriptionItems;
};
export var getSubscriptionModel = function getSubscriptionModel(response) {
  return _objectSpread({
    subscriptionCatalogueItemId: response.id
  }, response);
};
export var getAccountGroupedSubscriptions = function getAccountGroupedSubscriptions(accountSubscriptions) {
  var groupedByCatItemAndVendor = [];
  var processedVendorNames = [];
  var processedSubscriptionCatItemsIds = [];
  var addToProcessedSubscriptions = function addToProcessedSubscriptions(subscription) {
    processedVendorNames.push(subscription.vendor);
    processedSubscriptionCatItemsIds.push(subscription.subscriptionCatalogueItemId);
  };
  var getMappedSubscription = function getMappedSubscription(subscription) {
    var mappedSubscription = mapAccountSubscriptionToGroupedItem(subscription);
    mappedSubscription.totalPrice = subscription.price;
    mappedSubscription.activeSubscriptionPlans.push(subscription);
    return mappedSubscription;
  };
  accountSubscriptions.forEach(function (s) {
    var isFirsVendor = processedVendorNames.indexOf(s.vendor) === -1;
    var isFirstCatItem = processedSubscriptionCatItemsIds.indexOf(s.subscriptionCatalogueItemId) === -1;
    if (isFirsVendor) {
      addToProcessedSubscriptions(s);
      var mappedSubscription = getMappedSubscription(s);
      groupedByCatItemAndVendor.push(mappedSubscription);
    } else if (isFirstCatItem) {
      processedSubscriptionCatItemsIds.push(s.subscriptionCatalogueItemId);
      var _mappedSubscription = getMappedSubscription(s);
      groupedByCatItemAndVendor.push(_mappedSubscription);
    } else {
      var processedSubscription = groupedByCatItemAndVendor.find(function (processed) {
        return processed.subscriptionCatalogueItemId === s.subscriptionCatalogueItemId;
      });
      processedSubscription && processedSubscription.activeSubscriptionPlans.push(s);
      processedSubscription.totalPrice++;
    }
  });
  var result = groupedByCatItemAndVendor.map(addTotalsToAccountSubscription);
  return result;
};
export var getAllSubscriptionsParams = function getAllSubscriptionsParams(options) {
  var _options$searchInput;
  var defaultSort = {
    sortField: 'id',
    sortOrder: 'desc'
  };
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField === 'catalogueItemId' ? 'id' : sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  var filterField = [];
  var filterValue = [];
  if (!_isNil(options.active)) {
    filterField.push('active');
    filterValue.push(options.active);
  }
  if (!_isNil(options.catItemActive)) {
    filterField.push('catItemActive');
    filterValue.push(options.catItemActive);
  }
  if (!_isNil(options.catItemVisible)) {
    filterField.push('catItemVisible');
    filterValue.push(options.catItemVisible);
  }
  if (!_isNil(options.vendor)) {
    filterField.push('vendor');
    filterValue.push(options.vendor);
  }
  if (options.searchCriteria && (_options$searchInput = options.searchInput) !== null && _options$searchInput !== void 0 && _options$searchInput.length) {
    filterField.push(options.searchCriteria);
    filterValue.push(options.searchInput);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), options.sort ? mapSort(options.sort) : defaultSort), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};
export var editSubscriptionBody = function editSubscriptionBody(body) {
  var keysToOmit = ['lastModified', 'modifiedBy', 'modifiedOn', 'catalogueItemId', 'createdBy', 'createdOn', 'rowVersion', 'systemName'];
  return _omit(body, keysToOmit);
};

// PRIVATE
var mapAccountSubscriptionToGroupedItem = function mapAccountSubscriptionToGroupedItem(subscription) {
  var subscriptionCatalogueItemId = subscription.subscriptionCatalogueItemId,
    vendor = subscription.vendor,
    picThumbnailUrl = subscription.picThumbnailUrl,
    pictureUrl = subscription.pictureUrl;
  var mappedResult = {
    subscriptionCatalogueItemId: subscriptionCatalogueItemId,
    vendor: vendor,
    picThumbnailUrl: picThumbnailUrl,
    pictureUrl: pictureUrl,
    totalValidUntil: null,
    validFrom: null,
    totalPrice: null,
    totalDuration: null,
    activeSubscriptionPlans: []
  };
  return mappedResult;
};
var getValidUntil = function getValidUntil(fromDate, duration) {
  var validUntil = moment(fromDate).add(duration, 'months');
  return validUntil;
};
var addTotalsToAccountSubscription = function addTotalsToAccountSubscription(s) {
  var mappedSubscription = _objectSpread({}, s);
  var totalDuration = s.activeSubscriptionPlans.length === 1 ? s.activeSubscriptionPlans[0].duration : s.activeSubscriptionPlans.map(function (p) {
    return p.duration;
  }).reduce(function (prev, curr) {
    return prev + curr;
  }, 0);
  mappedSubscription.totalDuration = totalDuration;
  var lastPlan = s.activeSubscriptionPlans[s.activeSubscriptionPlans.length - 1];
  mappedSubscription.totalValidUntil = getValidUntil(lastPlan.validFrom, lastPlan.duration);
  mappedSubscription.validFrom = new Date(s.activeSubscriptionPlans[0].validFrom);
  return mappedSubscription;
};