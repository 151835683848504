import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import { DetailsLink } from '../../../../components/Shared/TableComponents';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var bundlesListColumns = [{
  name: 'ID',
  value: 'id',
  width: 40
}, {
  name: 'Name',
  value: 'name'
}, {
  name: 'Description',
  value: 'description'
}, {
  name: 'Packages',
  render: function render(row) {
    return row.packages.map(function (el) {
      return el.systemName;
    }).join(', ');
  }
}, {
  name: 'Max Splits',
  value: 'maxSplits',
  width: 80
}, {
  name: 'View details',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/packages/bundle/".concat(row.id)
    });
  },
  width: 100
}];
export var bundleInfo = [{
  label: 'Name',
  value: 'name'
}, {
  label: 'Description',
  value: 'description'
}, {
  label: 'Max Split',
  value: 'maxSplits'
}];
export var detailsBreadcrumbs = [{
  url: '/packages/all-bundles',
  label: 'Bundles'
}, {
  label: 'Detail Page'
}];
export var addPageBreadcrumbs = [{
  url: '/packages/all-bundles',
  label: 'Bundles'
}, {
  label: 'Create Bundle'
}];
export var editPageBreadcrumbs = function editPageBreadcrumbs(bundleId) {
  return [{
    url: '/packages/all-bundles',
    label: 'Bundles'
  }, {
    url: "/packages/bundle/".concat(bundleId),
    label: 'Detail Page'
  }, {
    label: 'Edit Bundles'
  }];
};