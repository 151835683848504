function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _pick from "lodash/pick";
import _isPlainObject from "lodash/isPlainObject";
import _isNil from "lodash/isNil";
import _isEqual from "lodash/isEqual";
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";
import _isArray from "lodash/isArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.some.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { Children } from 'react';
import hash from 'object-hash';
import { removeNodes } from '../Dropdown/utils';
import { noValue } from '../../../utils';
export var getFormChilds = function getFormChilds(children) {
  var newChildrens = [];
  var _getFlatList = function getFlatList(childs) {
    var childChildren = Children.toArray(childs).filter(Boolean);
    childChildren.forEach(function (child) {
      if (child.type.displayName === 'FormParagraph') _getFlatList(child.props.children);else newChildrens.push(child);
    });
  };
  _getFlatList(children);
  return newChildrens;
};
export var mapPreFilledValues = function mapPreFilledValues(preFilledValues, children) {
  var newMappedValues = {};
  Object.keys(preFilledValues || {}).forEach(function (id) {
    var correspondingChild = getFormChilds(children).find(function (el) {
      return el.props.id === id;
    });
    if (correspondingChild) {
      newMappedValues[id] = correspondingChild.props.mapValue ? correspondingChild.props.mapValue(preFilledValues[id]) : preFilledValues[id];
    } else newMappedValues[id] = preFilledValues[id];
  });
  return newMappedValues;
};

// Check if the values are defined because lodash isEqual with 2 undefined returns true
var compareValues = function compareValues(source, value, keys) {
  var isStrNum = function isStrNum(val) {
    return _isString(val) || _isNumber(val);
  };

  // Compare string with object if they are related to the same thing
  // Example: code as a string and object with code property if the codes are same they are equal
  if (isStrNum(source) && _isPlainObject(value)) return keys.some(function (key) {
    return source === value[key];
  });
  if (_isPlainObject(source) && isStrNum(value)) return keys.some(function (key) {
    return value === source[key];
  });

  // Compare two objects, if they have match
  var areDef = function areDef(key) {
    return !_isNil(source[key]) && !_isNil(value[key]);
  };
  var areSame = function areSame(key) {
    return _isEqual(source[key], value[key]);
  };
  var compare = function compare(key) {
    return areDef(key) && areSame(key);
  };
  if (_isPlainObject(source) && _isPlainObject(value)) return keys.some(compare);
  var hasSort = function hasSort(item) {
    return _isArray(item) ? _toConsumableArray(item).sort() : item;
  };
  return _isEqual(hasSort(source), hasSort(value));
};
export var areEqual = function areEqual(val, id, initValue, children) {
  if (_isNil(initValue) && noValue(val)) return true;
  var correspondChild = getFormChilds(children).find(function (e) {
    return e.props.id === id;
  });
  if (!correspondChild) return null;
  var _correspondChild$prop = correspondChild.props,
    _correspondChild$prop2 = _correspondChild$prop.displayKey,
    displayKey = _correspondChild$prop2 === void 0 ? 'label' : _correspondChild$prop2,
    _correspondChild$prop3 = _correspondChild$prop.uniqueKey,
    uniqueKey = _correspondChild$prop3 === void 0 ? 'value' : _correspondChild$prop3;
  var keys = [displayKey, uniqueKey];
  return compareValues(initValue, val, keys);
};
var baseDependenciesList = ['disabled', 'className', 'readOnly', 'value', 'memoDependencies', 'isTouched'];
export var compareInputProps = function compareInputProps(props) {
  return [hash({
    props: _pick(props, baseDependenciesList)
  })];
};
export var compareDropdownProps = function compareDropdownProps(props) {
  return [hash({
    props: _objectSpread(_objectSpread({}, _pick(props, baseDependenciesList)), {}, {
      options: removeNodes(props.options, props.displayKey, props.uniqueKey)
    })
  })];
};