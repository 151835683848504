import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isFunction from "lodash/isFunction";
import _isString from "lodash/isString";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import uuid from '../../../utils/uuid';
import { parseError } from '../../../utils/parseRequestError';
export var Types = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};
var prepare = function prepare(notif) {
  return _isString(notif) ? {
    message: notif,
    id: uuid()
  } : _objectSpread(_objectSpread({}, notif), {}, {
    id: uuid()
  });
};
var store = {
  show: function show() {},
  hide: function hide() {}
};
export var showSuccess = function showSuccess(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.SUCCESS
  }));
};
export var showError = function showError(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.ERROR
  }));
};
export var showApiError = function showApiError(error) {
  return parseError(error).map(function (notif) {
    return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
      type: Types.ERROR
    }));
  });
};
export var showIdentityError = function showIdentityError(error) {
  return parseError(error).map(function (notif) {
    return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
      type: Types.ERROR,
      duration: 10000
    }));
  });
};
export var showInfo = function showInfo(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.INFO
  }));
};
export var showWarning = function showWarning(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.WARNING
  }));
};
export var hide = function hide(notif) {
  return store.hide(notif === null || notif === void 0 ? void 0 : notif.id);
};
export var notificationsHandler = function notificationsHandler(_ref) {
  var addNotification = _ref.addNotification,
    removeNotification = _ref.removeNotification;
  if (_isFunction(addNotification)) store.show = addNotification;
  if (_isFunction(removeNotification)) store.hide = removeNotification;
};