function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _omit from "lodash/omit";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.object.to-string.js";
export var trimPath = function trimPath(path) {
  return (path === null || path === void 0 ? void 0 : path.slice(-1)) === '/' ? path.slice(0, -1) : path;
};
var emptyNode = function emptyNode(parent, childPath) {
  return {
    parent: parent,
    path: "".concat(trimPath(parent.path), "/").concat(childPath),
    children: []
  };
};
var _fillEmptyChilds = function fillEmptyChilds(node, depth) {
  // If the node is empty (if there is only 1 child on this depth the other will be empty)
  if (node.profileId === 0 && node.parentId === 0 && node.uplinkId === 0) return node;

  // Reach out the final depth
  if (depth === 0) return _objectSpread(_objectSpread({}, node), {}, {
    children: []
  });
  if (!node.children.length) {
    return _objectSpread(_objectSpread({}, node), {}, {
      children: [emptyNode(node, 1), emptyNode(node, 2)]
    });
  }
  var newNode = _objectSpread(_objectSpread(_objectSpread({}, node), !node.parent && {
    path: trimPath(node === null || node === void 0 ? void 0 : node.path)
  }), {}, {
    children: node.children.map(function (el, ind) {
      var _trimPath;
      return _fillEmptyChilds(_objectSpread(_objectSpread({}, el), {}, {
        parent: _omit(_objectSpread(_objectSpread({}, node), {}, {
          path: trimPath(node === null || node === void 0 ? void 0 : node.path)
        }), 'children'),
        path: (_trimPath = trimPath(el === null || el === void 0 ? void 0 : el.path)) !== null && _trimPath !== void 0 ? _trimPath : "".concat(trimPath(node.path), "/").concat(ind + 1)
      }), depth - 1);
    })
  });
  return newNode;
};
export { _fillEmptyChilds as fillEmptyChilds };