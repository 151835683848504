function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import { currencyByType } from '../../../utils';
import { type as walletType } from '../../../enums/wallets';
import MatchingLevelBvRow from './MatchingLevelBvRow';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var matchingBonusColumnsDefinition = [{
  name: 'Date',
  value: 'date',
  width: 120,
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: '1st Gen',
  render: function render(row) {
    var _row$packageInfoForRo;
    return ___EmotionJSX(MatchingLevelBvRow, {
      levelBv: row.firstLevelBv,
      hasBonusLevel: row === null || row === void 0 || (_row$packageInfoForRo = row.packageInfoForRound) === null || _row$packageInfoForRo === void 0 ? void 0 : _row$packageInfoForRo.hasMatch1Bonus,
      percents: 10
    });
  }
}, {
  name: '2nd Gen',
  render: function render(row) {
    var _row$packageInfoForRo2;
    return ___EmotionJSX(MatchingLevelBvRow, {
      levelBv: row.secondLevelBv,
      hasBonusLevel: row === null || row === void 0 || (_row$packageInfoForRo2 = row.packageInfoForRound) === null || _row$packageInfoForRo2 === void 0 ? void 0 : _row$packageInfoForRo2.hasMatch2Bonus,
      percents: 10
    });
  }
}, {
  name: '3rd Gen',
  render: function render(row) {
    var _row$packageInfoForRo3;
    return ___EmotionJSX(MatchingLevelBvRow, {
      levelBv: row.thirdLevelBv,
      hasBonusLevel: row === null || row === void 0 || (_row$packageInfoForRo3 = row.packageInfoForRound) === null || _row$packageInfoForRo3 === void 0 ? void 0 : _row$packageInfoForRo3.hasMatch3Bonus,
      percents: 20
    });
  }
}, {
  name: '4th Gen',
  render: function render(row) {
    var _row$packageInfoForRo4;
    return ___EmotionJSX(MatchingLevelBvRow, {
      levelBv: row.fourthLevelBv,
      hasBonusLevel: row === null || row === void 0 || (_row$packageInfoForRo4 = row.packageInfoForRound) === null || _row$packageInfoForRo4 === void 0 ? void 0 : _row$packageInfoForRo4.hasMatch4Bonus,
      percents: 25
    });
  }
}, {
  name: 'Sum',
  render: function render(row) {
    var _row$totalBv;
    return currencyByType((_row$totalBv = row === null || row === void 0 ? void 0 : row.totalBv) !== null && _row$totalBv !== void 0 ? _row$totalBv : 0, walletType.BV);
  }
}, {
  name: 'Bonus Cap',
  render: function render(row) {
    var _row$bonusCap;
    return currencyByType((_row$bonusCap = row === null || row === void 0 ? void 0 : row.bonusCap) !== null && _row$bonusCap !== void 0 ? _row$bonusCap : 0, walletType.CASH);
  }
}, {
  name: 'Total Bonus',
  width: 100,
  render: function render(row) {
    var _row$amount;
    return currencyByType((_row$amount = row === null || row === void 0 ? void 0 : row.amount) !== null && _row$amount !== void 0 ? _row$amount : 0, walletType.CASH);
  }
}, {
  name: 'Paid Bonus',
  width: 100,
  render: function render(row) {
    var _Math$min;
    return currencyByType((_Math$min = Math.min(row === null || row === void 0 ? void 0 : row.amount, row === null || row === void 0 ? void 0 : row.bonusCap)) !== null && _Math$min !== void 0 ? _Math$min : 0, walletType.CASH);
  }
}];
var amount = function amount(row, NAItem) {
  var cashAmount = row.amount / 10;
  if (!String(cashAmount).length) return NAItem;
  var amountVolume = currencyByType(cashAmount, walletType.CASH);
  return ___EmotionJSX("b", null, amountVolume);
};
export var directBonusColumnsDefinition = [{
  name: 'Date',
  value: 'date',
  width: 150,
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: 'Name',
  value: 'name'
}, {
  name: 'Nickname',
  value: 'nickName'
}, {
  name: 'Phone number',
  value: 'phone'
}, {
  name: 'Account number',
  value: 'downlinkId'
}, {
  name: 'Module',
  value: 'accountPackage'
}, {
  name: 'Total Bonus',
  render: amount
}].filter(function (el) {
  return el;
});
export var networkBonusColumnsDefinition = [{
  name: 'Date',
  value: 'createdOn',
  dateTimeFormat: 'DD/MM/YYYY',
  width: 100
}, {
  name: 'Nickname',
  value: 'nickName'
}, {
  name: 'Left leg',
  render: function render(row) {
    return currencyByType(row.leftBV, walletType.BV);
  }
}, {
  name: 'Right leg',
  render: function render(row) {
    return currencyByType(row.rightBV, walletType.BV);
  }
}, {
  name: 'Total Bonus',
  render: function render(row) {
    return currencyByType(row.amount / 10, walletType.CASH);
  }
}];
var accountSubBreadcrumbsPart = function accountSubBreadcrumbsPart(userId, accountId) {
  return [{
    url: '/profiles',
    label: 'Profiles'
  }, {
    url: "/profiles/profile/".concat(userId, "/accounts"),
    label: 'Accounts'
  }, {
    url: "/profiles/profile/".concat(userId, "/accounts/").concat(accountId),
    label: 'Account Details'
  }];
};
export var networkBonusBreadcrumbs = function networkBonusBreadcrumbs(userId, accountId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId, accountId)), [{
    label: 'Network Bonus'
  }]);
};
export var directSalesBonusBreadcrumbs = function directSalesBonusBreadcrumbs(userId, accountId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId, accountId)), [{
    label: 'Direct Sales Bonus'
  }]);
};
export var matchingBonusBreadcrumbs = function matchingBonusBreadcrumbs(userId, accountId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId, accountId)), [{
    label: 'Matching Bonus'
  }]);
};
export var startupBonusBreadcrumbs = function startupBonusBreadcrumbs(userId, accountId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId, accountId)), [{
    label: 'Startup Bonus'
  }]);
};
export var networkBonusDetailsBreadcrumbs = function networkBonusDetailsBreadcrumbs(userId, accountId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId, accountId)), [{
    url: "/profiles/profile/".concat(userId, "/accounts/").concat(accountId, "/my-bonuses/network"),
    label: 'Network Bonus'
  }, {
    label: 'Details Page'
  }]);
};