function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.some.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import { add } from 'exact-math';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Row, Col } from '../../UI';
import { type } from '../../../enums/wallets';
import { currencyByType } from '../../../utils';
import { summaryBoxWrap } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var OrderSummaryBox = function OrderSummaryBox(props) {
  var orderItems = props.orderItems;
  var theme = useTheme();
  var areSamePackages = function areSamePackages(el1, el2) {
    return el1.name === el2.name;
  };
  var packageSummary = orderItems === null || orderItems === void 0 ? void 0 : orderItems.reduce(function (acc, el) {
    return acc.some(function (item) {
      return areSamePackages(el, item);
    }) ? acc.map(function (item) {
      return areSamePackages(el, item) ? _objectSpread(_objectSpread({}, item), {}, {
        amount: item.amount + 1,
        total: add(item.total, el.price)
      }) : item;
    }) : acc.concat(_objectSpread(_objectSpread({}, el), {}, {
      amount: 1,
      total: el.price
    }));
  }, []);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Row, {
    container: true,
    noWrap: true,
    gap: 8
  }, ___EmotionJSX(Col, {
    xs: 4,
    lg: 4,
    className: summaryBoxWrap(theme)
  }, ___EmotionJSX("h2", null, "Name")), ___EmotionJSX(Col, {
    xs: 4,
    lg: 4,
    className: summaryBoxWrap(theme)
  }, ___EmotionJSX("h2", null, "Amount")), ___EmotionJSX(Col, {
    xs: 4,
    lg: 4,
    className: summaryBoxWrap(theme)
  }, ___EmotionJSX("h2", null, "Total"))), packageSummary === null || packageSummary === void 0 ? void 0 : packageSummary.map(function (item, ind) {
    return ___EmotionJSX(Row, {
      container: true,
      noWrap: true,
      gap: 8,
      key: ind
    }, ___EmotionJSX(Col, {
      lg: 4,
      className: summaryBoxWrap(theme)
    }, ___EmotionJSX("p", null, item.name)), ___EmotionJSX(Col, {
      lg: 4,
      className: summaryBoxWrap(theme)
    }, ___EmotionJSX("p", null, item.amount)), ___EmotionJSX(Col, {
      lg: 4,
      className: summaryBoxWrap(theme)
    }, ___EmotionJSX("p", null, currencyByType(item.total, type.CASH))));
  }));
};
OrderSummaryBox.propTypes = {
  orderItems: PropTypes.array
};
export default OrderSummaryBox;