function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import PropTypes from 'prop-types';
import _validate from '../../../utils/inputValidation';
import { Button, CheckBoxForm, colComp, Form, FormDropdown, FormInput, FormParagraph, rowComp } from '../../UI';
import { validationsRegexp } from '../../../utils/validations';
import { checkboxesContainer, formActionButtonContaner } from './styles';
import { currencyType } from '../../../enums/wallets';
import ColorBatch from '../ColorBatch';
import { jsx as ___EmotionJSX } from "@emotion/react";
var EditSubscriptionPlanForm = function EditSubscriptionPlanForm(props) {
  var data = props.data,
    onChange = props.onChange,
    onErrors = props.onErrors,
    onRemove = props.onRemove,
    canRemove = props.canRemove,
    canEdit = props.canEdit;
  var testPattern = function testPattern(val, regex) {
    return regex.test(val);
  };
  var currencyOptions = [{
    displayName: currencyType.EURO_LETTERS,
    value: currencyType.EURO_LETTERS
  }];
  var pricePattern = validationsRegexp.pricePattern,
    positiveIntPattern = validationsRegexp.positiveIntPattern;
  var handleErrors = function handleErrors(error, currentErrorsRef) {
    onErrors(data.id, {
      id: data.id,
      errors: _objectSpread({}, currentErrorsRef)
    });
  };
  var handleChange = function handleChange(name, value) {
    onChange(data.id, {
      name: name,
      value: value
    });
  };
  var handleRemoveClick = function handleRemoveClick() {
    return onRemove(data.id);
  };
  return ___EmotionJSX(Form, {
    values: data,
    renderSubmit: function renderSubmit() {
      return ___EmotionJSX(React.Fragment, null);
    },
    onChange: handleChange,
    onError: handleErrors
  }, ___EmotionJSX(FormParagraph, {
    className: rowComp({
      margin: '0 50px 0 -16px'
    })
  }, ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 4,
      lg: 6,
      horizontalGap: 8
    })
  }, ___EmotionJSX(FormInput, {
    id: "name",
    label: "Name",
    value: data.name,
    required: true,
    disabled: !canEdit
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 2,
      lg: 2,
      horizontalGap: 8
    })
  }, ___EmotionJSX(FormInput, {
    id: "price",
    label: "Price",
    value: data.price,
    required: true,
    disabled: !canEdit,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'price']);
    },
    pattern: function pattern(val) {
      return testPattern(val, pricePattern);
    }
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 1,
      lg: 2,
      horizontalGap: 8
    })
  }, ___EmotionJSX(FormDropdown, {
    id: "currency",
    label: "\xA0",
    placeholder: "Currency",
    mapValue: function mapValue(val) {
      return val.value;
    },
    value: data.currency || currencyType.EURO_LETTERS,
    options: currencyOptions,
    noClear: true,
    disabled: !canEdit,
    uniqueKey: "value",
    displayKey: "displayName"
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 2,
      lg: 2,
      horizontalGap: 8
    })
  }, ___EmotionJSX(FormInput, {
    id: "businessVolume",
    label: "Business volume",
    value: data.businessVolume,
    required: true,
    number: true,
    disabled: !canEdit,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, positiveIntPattern);
    }
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 2,
      lg: 2,
      horizontalGap: 8
    })
  }, ___EmotionJSX(FormInput, {
    id: "duration",
    label: "Duration /months/",
    value: data.duration,
    required: true,
    number: true,
    disabled: !canEdit,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveIntWithoutZero']);
    },
    pattern: function pattern(val) {
      return testPattern(val, positiveIntPattern);
    }
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 1,
      lg: 1,
      horizontalGap: 8
    })
  }, canEdit ? ___EmotionJSX(CheckBoxForm, {
    id: "active",
    label: "Active",
    disabled: !canEdit,
    className: checkboxesContainer
  }) : ___EmotionJSX("div", {
    className: checkboxesContainer
  }, ___EmotionJSX(ColorBatch, {
    type: data.active ? 'success' : 'error'
  }, data.active ? 'Active' : 'Inactive'))), canRemove && ___EmotionJSX(Button, {
    className: formActionButtonContaner,
    onClick: handleRemoveClick,
    leftIcon: {
      iconName: 'remove',
      material: true,
      size: 15
    }
  }, "\xA0")));
};
EditSubscriptionPlanForm.propTypes = {
  data: PropTypes.any,
  onChange: PropTypes.func,
  onErrors: PropTypes.func,
  onRemove: PropTypes.func,
  canRemove: PropTypes.bool,
  canEdit: PropTypes.bool
};
export default EditSubscriptionPlanForm;