import _startCase from "lodash/startCase";
import _isFunction from "lodash/isFunction";
import "core-js/modules/es.array.concat.js";
import moment from 'moment';
import { type, currencyType } from '../enums/wallets';
import { validDate } from './moment';
import { getNestedProperty } from './nestedValuesModifications';
var ONE = type.ONE,
  COINSAFE = type.COINSAFE,
  CASHSAFE = type.CASHSAFE,
  CASH = type.CASH,
  BV = type.BV,
  TRADING = type.TRADING,
  TOKEN = type.TOKEN;
var EURO = currencyType.EURO,
  EURO_LETTERS = currencyType.EURO_LETTERS,
  COINCURRENCY = currencyType.ONE,
  BVP = currencyType.BVP,
  TKN = currencyType.TKN;
export var generateName = function generateName() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!data) return null;
  var firstName = data.firstName,
    middleName = data.middleName,
    lastName = data.lastName;
  return (firstName || '').concat(middleName ? " ".concat(middleName) : '').concat(lastName ? " ".concat(lastName) : '') || 'N/A';
};
export var currencyByType = function currencyByType(value, currency, letter) {
  var amount = value || 0;
  switch (true) {
    case currency === ONE || currency === COINSAFE:
      return "".concat(amount, " ").concat(COINCURRENCY);
    case currency === CASH || currency === TRADING || currency === CASHSAFE:
      return letter ? "".concat(amount, " ").concat(EURO_LETTERS) : "".concat(EURO, " ").concat(amount);
    case currency === TOKEN:
      return "".concat(amount, " ").concat(TKN);
    case currency === BV:
      return "".concat(amount, " ").concat(BVP);
    default:
      return amount !== null && amount !== void 0 ? amount : 'N/A';
  }
};
export var displayProperty = function displayProperty(config, data) {
  var _config$value, _validDate;
  var value = getNestedProperty(data, (_config$value = config.value) === null || _config$value === void 0 ? void 0 : _config$value.split('.'));
  var checkFlag = function checkFlag(flag) {
    return config[flag] && value !== null;
  };
  switch (true) {
    case checkFlag('bool'):
      return value ? 'Yes' : 'No';
    case checkFlag('date'):
      return validDate(value) ? moment(value).format('DD.MM.YYYY') : 'N/A';
    case checkFlag('validDate'):
      return (_validDate = validDate(value)) !== null && _validDate !== void 0 ? _validDate : 'N/A';
    case checkFlag('dateTime'):
      return validDate(value) ? moment(value).format('DD.MM.YYYY, HH:mm:ss') : 'N/A';
    case checkFlag('time'):
      return validDate(value) ? moment(value).format('HH:mm:ss') : 'N/A';
    case checkFlag('currency'):
      return currencyByType(value, config.currency);
    case checkFlag('startCase'):
      return _startCase(value) || 'N/A';
    case _isFunction(config.render):
      return config.render(data);
    default:
      return value !== null && value !== void 0 ? value : 'N/A';
  }
};