import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import PropTypes from 'prop-types';
import CheckButton from './CheckButton';
import { checkGroup } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ButtonGroup = function ButtonGroup(_ref) {
  var value = _ref.value,
    options = _ref.options,
    onChange = _ref.onChange,
    multiSelect = _ref.multiSelect;
  var selectOption = function selectOption(isChecked, element) {
    if (!multiSelect) return value !== element && onChange(element);
    return onChange(isChecked ? value.concat(element) : value.filter(function (el) {
      return el !== element;
    }));
  };
  return ___EmotionJSX("div", {
    className: checkGroup
  }, Object.keys(options).map(function (el, index) {
    var isChecked = multiSelect ? value.indexOf(el) !== -1 : value === el;
    return ___EmotionJSX(CheckButton, {
      key: "OptionButton".concat(index),
      radio: !multiSelect,
      checked: isChecked,
      onChange: function onChange(val) {
        return selectOption(val, el);
      }
    }, options[el]);
  }));
};
ButtonGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
  options: PropTypes.object,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func
};
export default ButtonGroup;