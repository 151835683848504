import _isNil from "lodash/isNil";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { displayProperty } from '../../../utils/displayData';
import { Row, Col, Skeleton } from '../../UI/index';
import { labelContainer, valueContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ItemDetails = function ItemDetails(_ref) {
  var item = _ref.item,
    properties = _ref.properties;
  return properties.map(function (el, index) {
    var _el$label, _item$el$value, _el$label2;
    return el.listDefinition ? ___EmotionJSX(Fragment, {
      key: (_el$label = el.label) !== null && _el$label !== void 0 ? _el$label : index
    }, el.label && ___EmotionJSX("h3", {
      className: labelContainer
    }, el.label), (_item$el$value = item[el.value]) === null || _item$el$value === void 0 ? void 0 : _item$el$value.map(function (item, ind, arr) {
      return ___EmotionJSX(Fragment, {
        key: ind
      }, el.listDefinition.map(function (itemDefinition, i) {
        var _itemDefinition$label;
        return ___EmotionJSX(Row, {
          key: (_itemDefinition$label = itemDefinition.label) !== null && _itemDefinition$label !== void 0 ? _itemDefinition$label : i
        }, ___EmotionJSX(Col, {
          md: 3,
          className: labelContainer
        }, itemDefinition.label), ___EmotionJSX(Col, {
          md: 9,
          className: valueContainer
        }, _isNil(item) ? ___EmotionJSX(Skeleton, {
          width: 120,
          height: 18
        }) : displayProperty(itemDefinition, item)));
      }), arr.length - 1 !== ind && ___EmotionJSX("hr", null));
    })) : ___EmotionJSX(Row, {
      key: (_el$label2 = el.label) !== null && _el$label2 !== void 0 ? _el$label2 : index,
      md: 6
    }, ___EmotionJSX(Col, {
      className: labelContainer
    }, ___EmotionJSX("h4", null, el.label)), ___EmotionJSX(Col, {
      className: valueContainer
    }, _isNil(item) ? ___EmotionJSX(Skeleton, {
      width: 150,
      height: 18
    }) : ___EmotionJSX("span", null, displayProperty(el, item))));
  });
};
ItemDetails.propTypes = {
  item: PropTypes.object,
  properties: PropTypes.array
};
export default ItemDetails;