function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import NetworkBonusBvPointsTable from '../NetworkBonusBVPointsTable';
import { Button } from '../../UI';
import { currencyByType } from '../../../utils';
import { type as walletType } from '../../../enums/wallets';
import { ReactComponent as AccordionWrapIcn } from '../../../assets/images/icn-accordion-arrow.svg';
import { collapseDiv, networkBonusHistory, networkBonusHistoryDateItem, networkBonusHistoryItemBody, networkBonusHistoryItemHeader, networkBonusHistoryItemHeaderAmountLabel, networkBonusHistoryItemHeaderAmountWrap, networkBonusHistoryItemHeaderArrowWrap, networkBonusHistoryItemHeaderDate, networkBonusHistoryItemHeaderRightWrap, networkBonusHistoryShowMore, networkBonusHistoryShowMoreButton, networkBonusSectionHeadingLight, networkBonusSectionHeadingSecondary } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkBonusHistory = function NetworkBonusHistory(props) {
  var history = props.history,
    onShowMore = props.onShowMore,
    hasMore = props.hasMore,
    detailsRootPath = props.detailsRootPath;
  var theme = useTheme();
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    collapse = _useState2[0],
    setCollapse = _useState2[1];
  var handleCollapse = function handleCollapse(id) {
    setCollapse(function (prev) {
      return prev === id ? '0' : id;
    });
  };
  return !!(history !== null && history !== void 0 && history.length) && ___EmotionJSX("div", {
    className: networkBonusHistory
  }, ___EmotionJSX("h3", {
    className: networkBonusSectionHeadingSecondary
  }, "Bonuses History"), ___EmotionJSX("div", null, history.map(function (el, ind) {
    var _el$id, _el$packageInfoForRou, _el$networkBonus$amou, _el$networkBonus;
    return ___EmotionJSX("div", {
      className: networkBonusHistoryDateItem(theme),
      key: (_el$id = el === null || el === void 0 ? void 0 : el.id) !== null && _el$id !== void 0 ? _el$id : ind
    }, ___EmotionJSX("div", {
      className: networkBonusHistoryItemHeader(theme)
    }, ___EmotionJSX("div", null, ___EmotionJSX("p", {
      className: networkBonusHistoryItemHeaderDate((el === null || el === void 0 ? void 0 : el.id) === collapse)
    }, moment(el === null || el === void 0 ? void 0 : el.targetDateTo).format('DD/MM/YYYY'))), ___EmotionJSX("div", {
      className: networkBonusHistoryItemHeaderRightWrap
    }, ___EmotionJSX("div", {
      className: networkBonusHistoryItemHeaderAmountWrap
    }, el !== null && el !== void 0 && (_el$packageInfoForRou = el.packageInfoForRound) !== null && _el$packageInfoForRou !== void 0 && _el$packageInfoForRou.hasNetworkBonus ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", {
      className: networkBonusHistoryItemHeaderAmountLabel
    }, "Bonuses earned"), ___EmotionJSX("h3", {
      className: networkBonusSectionHeadingLight
    }, currencyByType((_el$networkBonus$amou = el === null || el === void 0 || (_el$networkBonus = el.networkBonus) === null || _el$networkBonus === void 0 ? void 0 : _el$networkBonus.amount) !== null && _el$networkBonus$amou !== void 0 ? _el$networkBonus$amou : 0, walletType.CASH))) : ___EmotionJSX("p", null, "No bonus for this round")), ___EmotionJSX("div", {
      role: "button",
      tabIndex: "0",
      className: networkBonusHistoryItemHeaderArrowWrap(theme, (el === null || el === void 0 ? void 0 : el.id) === collapse),
      onClick: function onClick() {
        return handleCollapse("".concat(el === null || el === void 0 ? void 0 : el.id));
      }
    }, ___EmotionJSX(AccordionWrapIcn, null)))), ___EmotionJSX("div", {
      className: collapseDiv(collapse === "".concat(el === null || el === void 0 ? void 0 : el.id))
    }, ___EmotionJSX("div", {
      className: networkBonusHistoryItemBody
    }, ___EmotionJSX(NetworkBonusBvPointsTable, {
      content: _objectSpread(_objectSpread({}, el === null || el === void 0 ? void 0 : el.networkBonus), {}, {
        id: el === null || el === void 0 ? void 0 : el.id,
        packageInfoForRound: el === null || el === void 0 ? void 0 : el.packageInfoForRound
      }),
      detailsRootPath: detailsRootPath
    }))));
  })), hasMore && ___EmotionJSX("div", {
    className: networkBonusHistoryShowMore
  }, ___EmotionJSX(Button, {
    onClick: onShowMore,
    className: networkBonusHistoryShowMoreButton(theme)
  }, "SHOW MORE")));
};
NetworkBonusHistory.propTypes = {
  history: PropTypes.any,
  onShowMore: PropTypes.func,
  hasMore: PropTypes.bool,
  detailsRootPath: PropTypes.string
};
export default NetworkBonusHistory;