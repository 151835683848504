import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNil from "lodash/isNil";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import React from 'react';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { currencyByType } from '../../../utils/displayData';
import { type } from '../../../enums/wallets';
import { depositUserContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var mapType = _defineProperty(_defineProperty({}, type.ONE, {
  text: 'Coin Safe',
  path: 'coin-safe',
  currency: type.ONE
}), type.CASH, {
  text: 'Cash Safe',
  path: 'cash-safe',
  currency: type.CASH
});
export var depositsColumns = function depositsColumns(type) {
  return [{
    name: 'Date of create',
    value: 'createdOn',
    dateTimeFormat: 'DD/MM/YYYY',
    width: 95
  }, {
    name: 'Maturity Date',
    value: 'maturityDate',
    dateTimeFormat: 'DD/MM/YYYY',
    width: 90
  }, {
    name: 'Deposit ID',
    value: 'id',
    width: 70
  }, {
    name: 'User',
    render: function render(row) {
      var _row$account$nickName, _row$account, _row$account2, _row$account3;
      return ___EmotionJSX("div", {
        className: depositUserContainer
      }, ___EmotionJSX("p", null, "AccountID: ", ___EmotionJSX("span", null, row === null || row === void 0 ? void 0 : row.accountId, " ")), ___EmotionJSX("p", null, "NickName: ", ___EmotionJSX("span", null, (_row$account$nickName = row === null || row === void 0 || (_row$account = row.account) === null || _row$account === void 0 ? void 0 : _row$account.nickName) !== null && _row$account$nickName !== void 0 ? _row$account$nickName : 'N/A')), ___EmotionJSX("p", null, "Email: ", ___EmotionJSX("span", null, row === null || row === void 0 || (_row$account2 = row.account) === null || _row$account2 === void 0 ? void 0 : _row$account2.profileEmail)), ___EmotionJSX("p", null, "Name: ", ___EmotionJSX("span", null, row === null || row === void 0 || (_row$account3 = row.account) === null || _row$account3 === void 0 ? void 0 : _row$account3.profileFullName)));
    }
  }, {
    name: 'Initial Amount',
    render: function render(row) {
      return currencyByType(row.initialAmount, mapType[type].currency);
    },
    width: 70
  }, {
    name: 'Accrued Amount',
    render: function render(row) {
      return currencyByType(row.accruedAmount, mapType[type].currency);
    },
    width: 70
  }, {
    name: 'Automatic Cash-out',
    render: function render(row) {
      return row.isAutomaticCashout ? 'Yes' : 'No';
    },
    width: 80
  }, {
    name: 'Sharia Compliant',
    render: function render(row) {
      return row.isShariaCompliant ? 'Yes' : 'No';
    },
    width: 80
  }, {
    name: 'Prematurely Closed',
    render: function render(row) {
      return row.prematurelyClosed ? 'Yes' : 'No';
    },
    width: 90
  }, {
    name: 'Deposit State',
    render: function render(row) {
      return ___EmotionJSX(ColorBatch, {
        type: !_isNil(row.paidOn) ? 'success' : 'error'
      }, row.paidOn ? 'Paid' : 'Not Paid');
    },
    width: 90
  }, {
    name: 'View details',
    render: function render(row) {
      return ___EmotionJSX(DetailsLink, {
        row: _objectSpread(_objectSpread({}, row), {}, {
          type: mapType[type].currency
        }),
        url: "/deposits/details/".concat(row.id, "/").concat(mapType[type].currency)
      });
    },
    width: 100
  }];
};
export var pagesDetails = function pagesDetails(type) {
  return [{
    url: "/deposits/".concat(mapType[type].path),
    label: 'Deposits'
  }, {
    label: 'Detail Page'
  }];
};
export var generalInfo = function generalInfo(type) {
  return [{
    label: 'Date of create',
    value: 'createdOn',
    dateTime: true
  }, {
    label: 'Maturity Date',
    value: 'maturityDate',
    dateTime: true
  }, {
    label: 'Date of completion ',
    value: 'modifiedOn',
    dateTime: true,
    validDate: true
  }, {
    label: 'Date of paid on',
    value: 'paidOn',
    dateTime: true
  }, {
    label: 'Deposit ID',
    value: 'id'
  }, {
    label: 'Initial Amount',
    value: 'initialAmount',
    currency: mapType[type].currency
  }, {
    label: 'Accrued Amount',
    value: 'accruedAmount',
    currency: mapType[type].currency
  }, {
    label: 'Account Interest',
    value: 'accountInterest'
  }, {
    label: 'Automatic Cash-out',
    value: 'isAutomaticCashout',
    bool: true
  }, {
    label: 'Sharia Compliant',
    value: 'isShariaCompliant',
    bool: true
  }];
};
export var userInfo = [{
  label: 'NickName',
  value: 'nickName'
}, {
  label: 'Email',
  value: 'profileEmail'
}, {
  label: 'Name',
  value: 'profileFullName'
}];