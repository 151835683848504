import { css } from 'emotion';
export var containerShop = function containerShop(className) {
  return css({
    position: 'relative',
    cursor: 'pointer',
    minWidth: 100
  }, className);
};
export var containerPackageShop = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});
export var shopPackageCardTop = function shopPackageCardTop(theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 3,
    border: "solid 1px ".concat(theme.whiteGrayDark),
    backgroundColor: theme.whiteGrayLight,
    minHeight: 100,
    cursor: 'pointer',
    padding: '16px 8px',
    '&:hover + .package-card-add': {
      opacity: 1,
      visibility: 'visible'
    }
  });
};
export var pictureContainer = css({
  borderRadius: '50%',
  flexBasis: 60,
  flexShrink: 0,
  marginRight: 12
});
export var shopPackageCardAdd = function shopPackageCardAdd(theme) {
  return css({
    position: 'absolute',
    top: 'calc(100% - 3px)',
    left: 0,
    right: 0,
    zIndex: 60,
    opacity: 0,
    visibility: 'hidden',
    backgroundColor: theme.white,
    padding: 12,
    boxShadow: "0 1px 2px 0 ".concat(theme.whiteGray),
    border: "solid 1px ".concat(theme.whiteGray),
    transition: 'opacity 0.3s ease',
    '&:hover': {
      zIndex: 70,
      opacity: 1,
      visibility: 'visible'
    }
  });
};
export var buttonAddToCard = function buttonAddToCard(theme) {
  return css({
    padding: '12px 6px',
    fontSize: 13,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.secondaryLight
    },
    '&:focus, &:active': {
      backgroundColor: theme.secondary,
      color: theme.white
    }
  });
};
export var titlePackage = css({
  wordBreak: 'break-all',
  flexGrow: 1
});