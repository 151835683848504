function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import React from 'react';
import { Image } from '../../../components/UI';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { taskStatus } from '../../../enums/tasks';
import defaultAvatar from '../../../assets/images/default-avatar.png';
import { assigneeToImageContainer, assigneeContainer, nameContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var mapTaskStatus = _defineProperty(_defineProperty(_defineProperty({}, taskStatus.toDo, {
  text: 'To Do',
  color: 'warning'
}), taskStatus.inProgress, {
  text: 'In Progress',
  color: 'info'
}), taskStatus.done, {
  text: 'Done',
  color: 'success'
});
var refTypeMap = {
  kyc: 'kyc'
};
export var tasksColumns = function tasksColumns(handleClickAssignee) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [{
    name: 'ID',
    value: 'id',
    width: 60
  }, {
    name: 'Type',
    value: 'type',
    startCase: true
  }, {
    name: 'Status',
    value: 'status',
    render: function render(row) {
      var _mapTaskStatus$row$st, _mapTaskStatus$row$st2;
      return ___EmotionJSX(ColorBatch, {
        type: (_mapTaskStatus$row$st = mapTaskStatus[row.status]) === null || _mapTaskStatus$row$st === void 0 ? void 0 : _mapTaskStatus$row$st.color
      }, (_mapTaskStatus$row$st2 = mapTaskStatus[row.status]) === null || _mapTaskStatus$row$st2 === void 0 ? void 0 : _mapTaskStatus$row$st2.text);
    }
  }, options.showAssignee && {
    name: 'Assignee to',
    render: function render(row) {
      var _row$assigneeProfile$, _row$assigneeProfile;
      return ___EmotionJSX("div", {
        className: assigneeContainer,
        onClick: handleClickAssignee,
        role: "button",
        tabIndex: 0
      }, ___EmotionJSX(Image, {
        img: true,
        src: defaultAvatar,
        alt: "".concat(row.id),
        className: assigneeToImageContainer
      }), ___EmotionJSX("h5", {
        className: nameContainer
      }, (_row$assigneeProfile$ = row === null || row === void 0 || (_row$assigneeProfile = row.assigneeProfile) === null || _row$assigneeProfile === void 0 ? void 0 : _row$assigneeProfile.userName) !== null && _row$assigneeProfile$ !== void 0 ? _row$assigneeProfile$ : 'Unassigned'));
    }
  }, {
    name: 'View details',
    render: function render(row) {
      var item = row.items && row.items[0];
      var type = refTypeMap[item === null || item === void 0 ? void 0 : item.refType];
      var itemId = item === null || item === void 0 ? void 0 : item.refId;
      return ___EmotionJSX(DetailsLink, {
        row: row,
        url: "/tasks/".concat(type, "/").concat(row.id, "/").concat(itemId)
      });
    }
  }].filter(Boolean);
};
export var detailPageBreadcrumb = [{
  url: '/tasks',
  label: 'Tasks'
}, {
  label: 'Detail Page'
}];
export var statsInformation = [{
  label: 'Pending',
  value: 'pending'
}, {
  label: 'Approved',
  value: 'approved'
}, {
  label: 'Declined',
  value: 'rejected'
}, {
  label: 'Total',
  value: 'total'
}, {
  label: 'Daily goal',
  value: 'dailyGoal'
}];