import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.trim.js";
import React from 'react';
import PropTypes from 'prop-types';
import NetworkTreeAvatar from '../NetworkTreeAvatar';
import { networkTreeContainer, networkTreeFirstLevel, networkTreePlaceholder, networkTreeRoot, networkTreeSecondLevel } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTree = function NetworkTree(props) {
  var _data$children$;
  var data = props.data,
    root = props.root,
    packageColorsMap = props.packageColorsMap,
    onAccountCreate = props.onAccountCreate,
    onChange = props.onChange;
  var getColor = function getColor(el) {
    var _el$planName;
    return packageColorsMap[el === null || el === void 0 || (_el$planName = el.planName) === null || _el$planName === void 0 ? void 0 : _el$planName.trim()];
  };
  return ___EmotionJSX("div", {
    className: networkTreeContainer
  }, ___EmotionJSX("div", {
    className: networkTreeRoot
  }, ___EmotionJSX(NetworkTreeAvatar, {
    root: root,
    nodeData: data,
    packageColor: getColor(data),
    onAccountCreate: onAccountCreate,
    onChange: onChange
  })), ___EmotionJSX("div", {
    className: networkTreeFirstLevel
  }, ___EmotionJSX(NetworkTreeAvatar, {
    root: root,
    nodeData: data.children[0],
    packageColor: getColor(data.children[0]),
    onAccountCreate: onAccountCreate,
    onChange: onChange
  }), ___EmotionJSX("div", {
    className: networkTreePlaceholder
  }), ___EmotionJSX(NetworkTreeAvatar, {
    root: root,
    nodeData: data.children[1],
    packageColor: getColor(data.children[1]),
    onAccountCreate: onAccountCreate,
    onChange: onChange
  })), ___EmotionJSX("div", {
    className: networkTreeSecondLevel
  }, ((_data$children$ = data.children[0]) === null || _data$children$ === void 0 ? void 0 : _data$children$.children.length) > 0 ? data.children[0].children.map(function (el, ind) {
    return ___EmotionJSX(NetworkTreeAvatar, {
      key: "".concat(el.id, "-").concat(ind),
      root: root,
      nodeData: el,
      packageColor: getColor(el),
      onAccountCreate: onAccountCreate,
      onChange: onChange
    });
  }) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: networkTreePlaceholder
  }), ___EmotionJSX("div", {
    className: networkTreePlaceholder
  })), data.children[1].children.length > 0 ? data.children[1].children.map(function (el, ind) {
    return ___EmotionJSX(NetworkTreeAvatar, {
      key: "".concat(el.id, "-").concat(ind),
      root: root,
      nodeData: el,
      packageColor: getColor(el),
      onAccountCreate: onAccountCreate,
      onChange: onChange
    });
  }) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: networkTreePlaceholder
  }), ___EmotionJSX("div", {
    className: networkTreePlaceholder
  }))));
};
NetworkTree.propTypes = {
  data: PropTypes.object,
  root: PropTypes.object,
  packageColorsMap: PropTypes.object,
  onAccountCreate: PropTypes.func,
  onChange: PropTypes.func
};
export default NetworkTree;