import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { orderStatus, orderPaymentStatus } from '../../../enums/orders';
export var mapOrderPaymentStatus = _defineProperty(_defineProperty(_defineProperty({}, orderPaymentStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), orderPaymentStatus.Confirmed, {
  text: 'Confirmed',
  color: 'success'
}), orderPaymentStatus.Denied, {
  text: 'Denied',
  color: 'error'
});
export var mapOrderStatus = _defineProperty(_defineProperty(_defineProperty({}, orderStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), orderStatus.Paid, {
  text: 'Completed',
  color: 'success'
}), orderStatus.Rejected, {
  text: 'Rejected',
  color: 'error'
});
export var orderStatusAdditionalInfo = [{
  label: 'Date and time of completion',
  value: 'modifiedOn',
  dateTime: true
}];
export var orderGeneralInfo = [{
  label: 'Date and time of order',
  value: 'createdOn',
  dateTime: true
}, {
  label: 'Order id',
  value: 'id'
}, {
  label: 'Payment type',
  value: 'paymentTypeId'
}, {
  label: 'Total amount',
  value: 'totalAmount'
}];