function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _isFunction from "lodash/isFunction";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Row, Col, Button, Image, pluralizeMonths } from '../../UI';
import avatar from '../../../assets/images/package.png';
import { containerShop, containerPackageShop, shopPackageCardTop, pictureContainer, shopPackageCardAdd, buttonAddToCard, titlePackage } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Subscription = function Subscription(props) {
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onAddToCard = props.onAddToCard,
    onClick = props.onClick,
    className = props.className,
    _props$detailsRoute = props.detailsRoute,
    detailsRoute = _props$detailsRoute === void 0 ? '/subscriptions/subscription' : _props$detailsRoute;
  var name = item.name,
    price = item.price,
    duration = item.duration,
    vendor = item.vendor,
    picThumbnailUrl = item.picThumbnailUrl,
    subscriptionCatalogueItemId = item.subscriptionCatalogueItemId;
  var theme = useTheme();
  var handleLinkClick = function handleLinkClick(e) {
    return e.preventDefault();
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: containerShop(className),
    onClick: onClick
  }, ___EmotionJSX(Link, _extends({
    to: "".concat(detailsRoute, "/").concat(subscriptionCatalogueItemId),
    className: "package-card-info ".concat(shopPackageCardTop(theme))
  }, _isFunction(onClick) && {
    onClick: handleLinkClick
  }), ___EmotionJSX("h4", {
    style: {
      flex: '0 0 100%',
      marginBottom: 12
    }
  }, vendor), ___EmotionJSX("div", {
    className: containerPackageShop
  }, ___EmotionJSX(Image, {
    size: "contain",
    src: picThumbnailUrl !== null && picThumbnailUrl !== void 0 ? picThumbnailUrl : avatar,
    width: 60,
    height: 60,
    className: pictureContainer
  }), ___EmotionJSX("h4", {
    className: titlePackage
  }, name, ___EmotionJSX("br", null), "\u20AC", price.toFixed(2), ___EmotionJSX("br", null), duration, " ", pluralizeMonths(duration)))), onAddToCard && ___EmotionJSX("div", {
    className: "package-card-add ".concat(shopPackageCardAdd(theme))
  }, ___EmotionJSX(Row, {
    align: "center",
    horizontalGap: 4,
    verticalGap: 4,
    justify: "space-between"
  }, ___EmotionJSX(Col, {
    basis: "auto",
    grow: "0"
  }, ___EmotionJSX(Button, {
    className: buttonAddToCard(theme),
    secondary: true,
    onClick: function onClick() {
      return onAddToCard(_objectSpread({}, item));
    }
  }, "Add to cart")))));
};
Subscription.propTypes = {
  item: PropTypes.any,
  onAddToCard: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  detailsRoute: PropTypes.string
};
export default Subscription;