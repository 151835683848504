import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.to-string.js";
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'emotion';
import { withTheme } from 'emotion-theming';
// import Icon from '../Icon';
import useSearchBar from './useSearchBar';
import { searchBarContainer, searchBarInput,
// searchBarSearchIcon,
// searchBarDeleteIcon,
searchBarHistoryContainer, searchBarHistoryItem } from './styles';

// This component is for searching based on previous searches that the user is done
// Into localStorage we safe the previously done searches and during typing we show suggestions to the user
// Those suggestions are called history and they are generated by useSearchBar hook
import { jsx as ___EmotionJSX } from "@emotion/react";
var SearchBar = function SearchBar(props) {
  var inputRef = props.inputRef,
    placeholder = props.placeholder,
    _props$maxHistory = props.maxHistory,
    maxHistory = _props$maxHistory === void 0 ? 7 : _props$maxHistory,
    _props$value = props.value,
    value = _props$value === void 0 ? '' : _props$value,
    storageKey = props.storageKey,
    _props$id = props.id,
    id = _props$id === void 0 ? 'searchBar' : _props$id,
    onSubmit = props.onSubmit,
    className = props.className,
    theme = props.theme;
  var _useSearchBar = useSearchBar(value, onSubmit, storageKey),
    input = _useSearchBar.input,
    container = _useSearchBar.container,
    isOpen = _useSearchBar.isOpen,
    history = _useSearchBar.history;

  // const { onBlur, onSelect, onDelete } = container;
  var onBlur = container.onBlur,
    onSelect = container.onSelect;
  var hasText = !!input.value.length;
  var hasMatches = !!history.length;
  return ___EmotionJSX("div", {
    className: cx(searchBarContainer(isOpen, hasMatches), className),
    role: "button",
    tabIndex: "0",
    onBlur: onBlur
  }, ___EmotionJSX("input", _extends({
    ref: inputRef,
    id: id.toLowerCase(),
    placeholder: placeholder,
    className: searchBarInput(hasText, theme),
    maxLength: "2048",
    type: "text",
    spellCheck: "false",
    "aria-autocomplete": "both",
    "aria-haspopup": "false",
    autoCapitalize: "off",
    autoComplete: "off",
    autoCorrect: "off"
  }, input)), isOpen && !!history.length && ___EmotionJSX("div", {
    className: searchBarHistoryContainer(history.length, maxHistory, theme)
  }, history.map(function (el, i) {
    return ___EmotionJSX("div", {
      key: "Suggestion".concat(i),
      role: "button",
      tabIndex: "-2",
      className: searchBarHistoryItem(theme),
      onClick: function onClick() {
        return onSelect(el);
      }
    }, el);
  })));
};
SearchBar.propTypes = {
  inputRef: PropTypes.object,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  storageKey: PropTypes.string,
  maxHistory: PropTypes.number,
  className: PropTypes.string,
  theme: PropTypes.object,
  id: PropTypes.string
};
export default withTheme(SearchBar);