function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Paper, Form, FormInput, FormParagraph, CheckBoxForm, FormDropdown, Modal, Button, Icon, CheckButton } from '../../UI';
import { rowComp, colComp } from '../../UI/Grid';
import BackButton from '../BackButton';
import UploadImage from '../UploadImage';
import FormColorInput from '../../UI/Form/FormColorInput';
import _validate from '../../../utils/inputValidation';
import { validateLength, validationsRegexp } from '../../../utils/validations';
import { optionsTypePackage } from './definitions';
import avatar from '../../../assets/images/package.png';
import { backContainer, marginBottom, bonusContainer, paddingLeft, levelMatching, bonus, marginTop, questionUpdatePackage, buttonContainer, questionIcon, versionCheckContainer, prevVersionContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var testPattern = function testPattern(val, regex) {
  return regex.test(val);
};
var AddPackageForm = function AddPackageForm(props) {
  var headerTitle = props.headerTitle,
    textSubmitButton = props.textSubmitButton,
    onSubmit = props.onSubmit,
    _props$values = props.values,
    values = _props$values === void 0 ? {} : _props$values,
    isEdit = props.isEdit,
    paymentMethodsOptions = props.paymentMethodsOptions;
  var theme = useTheme();
  var splitRef = useRef();
  var maxSplitRef = useRef();
  var _useState = useState({
      showModal: false,
      version: false
    }),
    _useState2 = _slicedToArray(_useState, 2),
    changeVersion = _useState2[0],
    setChangeVersion = _useState2[1];
  var validateSplit = function validateSplit(splitValue) {
    var _maxSplitRef$current, _maxSplitRef$current2, _maxSplitRef$current3;
    return _validate(splitValue, ['required', 'positiveInt']) || !!((_maxSplitRef$current = maxSplitRef.current) !== null && _maxSplitRef$current !== void 0 && _maxSplitRef$current.value) && ((_maxSplitRef$current2 = maxSplitRef.current) === null || _maxSplitRef$current2 === void 0 ? void 0 : _maxSplitRef$current2.value) < parseInt(splitValue, 10) && {
      msg: "Split must be less than or equal to ".concat((_maxSplitRef$current3 = maxSplitRef.current) === null || _maxSplitRef$current3 === void 0 ? void 0 : _maxSplitRef$current3.value)
    };
  };
  var validateMaxSplit = function validateMaxSplit(maxSplitValue) {
    var _splitRef$current, _splitRef$current2, _splitRef$current3;
    return _validate(maxSplitValue, ['required', 'positiveInt']) || !!((_splitRef$current = splitRef.current) !== null && _splitRef$current !== void 0 && _splitRef$current.value) && parseInt(maxSplitValue, 10) < ((_splitRef$current2 = splitRef.current) === null || _splitRef$current2 === void 0 ? void 0 : _splitRef$current2.value) && {
      msg: "Max splits must be greater than or equal to ".concat((_splitRef$current3 = splitRef.current) === null || _splitRef$current3 === void 0 ? void 0 : _splitRef$current3.value)
    };
  };
  var onClose = function onClose() {
    setChangeVersion({
      showModal: false,
      version: false
    });
  };
  var handleAccept = function handleAccept() {
    setChangeVersion({
      showModal: false,
      version: true
    });
  };
  var handleSubmit = function handleSubmit(values) {
    return onSubmit(isEdit ? _objectSpread(_objectSpread({}, values), {}, {
      updatePastVersions: changeVersion.version
    }) : values);
  };
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(BackButton, {
    className: backContainer,
    title: headerTitle
  }), ___EmotionJSX(Paper, null, ___EmotionJSX(Form, {
    onSubmit: handleSubmit,
    onChange: function onChange(id) {
      id === 'split' && maxSplitRef.current.checkForError(maxSplitRef.current.value);
      id === 'maxSplits' && splitRef.current.checkForError(splitRef.current.value);
    },
    submitButton: {
      children: textSubmitButton
    },
    values: values
  }, ___EmotionJSX(FormParagraph, {
    className: rowComp({
      margin: '0 -16px'
    })
  }, ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 12,
      lg: 12,
      horizontalGap: 16
    })
  }, ___EmotionJSX(UploadImage, {
    id: "picThumbnailUrl",
    defaultValue: avatar
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 4,
      lg: 6,
      horizontalGap: 16
    })
  }, ___EmotionJSX(FormInput, {
    id: "name",
    label: "Name",
    required: true
  }), ___EmotionJSX(FormDropdown, {
    id: "type",
    label: "Type",
    placeholder: "Select type...",
    mapValue: function mapValue(val) {
      return val === null || val === void 0 ? void 0 : val.value;
    },
    options: optionsTypePackage,
    required: true,
    noClear: true
  }), ___EmotionJSX(FormInput, {
    id: "description",
    label: "Description",
    required: true,
    validate: validateLength(2000)
  }), ___EmotionJSX(FormInput, {
    id: "price",
    label: "Price",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'price']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.pricePattern);
    }
  }), ___EmotionJSX(FormInput, {
    id: "coins",
    label: "Promotional Coins",
    number: true,
    required: true,
    validate: function validate(val) {
      return _validate(val, ['pricePositive15WithZero']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.longDecimalPattern);
    }
  }), ___EmotionJSX(FormInput, {
    id: "academyCoins",
    label: "Max Academy Coins",
    number: true,
    required: true,
    validate: function validate(val) {
      return _validate(val, ['pricePositive15WithZero']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.longDecimalPattern);
    }
  }), ___EmotionJSX(FormInput, {
    id: "split",
    label: "Split",
    required: true,
    number: true,
    ref: splitRef,
    validate: validateSplit,
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "maxSplits",
    label: "Max splits",
    required: true,
    number: true,
    ref: maxSplitRef,
    validate: validateMaxSplit,
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "promoSplits",
    label: "Promo splits",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 4,
      lg: 6,
      horizontalGap: 16
    })
  }, ___EmotionJSX(FormInput, {
    id: "difficulty",
    label: "Difficulty reduction",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "exchangeReduction",
    label: "Exchange reduction",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "businessVolume",
    label: "Business volume",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "bonusCap",
    label: "Bonus Cap",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "gasAmount",
    label: "GAS Amount",
    number: true,
    required: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormColorInput, {
    id: "color",
    label: "Color",
    required: true,
    placement: "right",
    defaultColor: "#6379c9",
    validate: function validate(val) {
      return _validate(val, ['required', 'validHex']);
    }
  }), isEdit && ___EmotionJSX(FormDropdown, {
    id: "shopPaymentMethodIds",
    label: "Payment Methods",
    placeholder: "Select payment methods...",
    multiSelect: true,
    mapValue: function mapValue(val) {
      return val === null || val === void 0 ? void 0 : val.map(function (el) {
        return el.id;
      });
    },
    options: paymentMethodsOptions,
    required: true,
    noClear: true,
    uniqueKey: "id",
    displayKey: "displayName"
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 4,
      lg: 6,
      horizontalGap: 16
    })
  }, ___EmotionJSX("h3", {
    className: marginBottom(16)
  }, "Applicable for:"), ___EmotionJSX(CheckBoxForm, {
    id: "hasNetworkBonus",
    label: "Networking bonus",
    className: bonusContainer
  }), ___EmotionJSX("h4", {
    className: "".concat(paddingLeft(24), " ").concat(marginBottom(16))
  }, "Matching bonus:"), ___EmotionJSX(FormParagraph, {
    className: levelMatching
  }, ___EmotionJSX(CheckBoxForm, {
    id: "hasMatch1Bonus",
    label: "1st level"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "hasMatch2Bonus",
    label: "2nd level"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "hasMatch3Bonus",
    label: "3rd level"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "hasMatch4Bonus",
    label: "4th level"
  })), ___EmotionJSX(FormParagraph, {
    className: bonus
  }, ___EmotionJSX("h4", {
    className: " ".concat(marginTop(16), " ").concat(marginBottom(16))
  }, "Properties:"), ___EmotionJSX(CheckBoxForm, {
    id: "active",
    label: "Status"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "visible",
    label: "Visibility"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "allSplitsAtOnce",
    label: "All Splits At Once"
  })))), isEdit && ___EmotionJSX("div", {
    className: versionCheckContainer
  }, ___EmotionJSX(CheckButton, {
    checked: changeVersion.version,
    onChange: function onChange(val) {
      setChangeVersion({
        showModal: true,
        version: val
      });
    }
  }), ___EmotionJSX("h5", null, "Update all previous versions of the package"))), ___EmotionJSX(Modal, {
    show: !!changeVersion.showModal,
    onClose: onClose
  }, ___EmotionJSX("div", {
    className: prevVersionContainer(theme)
  }, ___EmotionJSX(Icon, {
    iconName: "contact_support",
    material: true,
    className: questionIcon(theme),
    style: {
      display: 'flex',
      justifyContent: 'center'
    }
  }), ___EmotionJSX("h3", {
    className: questionUpdatePackage
  }, "Are you sure you want to update previous versions of the package that may have been redeemed?"), ___EmotionJSX("div", {
    className: buttonContainer
  }, ___EmotionJSX(Button, {
    onClick: onClose,
    secondary: true
  }, "Cancel"), ___EmotionJSX(Button, {
    onClick: handleAccept
  }, "Accept"))))));
};
AddPackageForm.propTypes = {
  headerTitle: PropTypes.string,
  textSubmitButton: PropTypes.string,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  isEdit: PropTypes.bool,
  paymentMethodsOptions: PropTypes.array
};
export default AddPackageForm;