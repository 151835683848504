import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isArray from "lodash/isArray";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import _orderBy from "lodash/orderBy";
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-iso-string.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import queryString from 'query-string';
import { add } from 'exact-math';
import moment from 'moment';
import { endOfTheDay, startOfTheDay } from '../../utils';
import { leadershipRunStatus, eagleStatus } from '../../enums/bonuses';
export var getBonusRunsListParams = function getBonusRunsListParams() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var startDate = params.startDate,
    endDate = params.endDate,
    _params$pageIndex = params.pageIndex,
    pageIndex = _params$pageIndex === void 0 ? 0 : _params$pageIndex,
    _params$pageSize = params.pageSize,
    pageSize = _params$pageSize === void 0 ? 5 : _params$pageSize,
    accountId = params.accountId,
    status = params.status,
    _params$sortOrder = params.sortOrder,
    sortOrder = _params$sortOrder === void 0 ? 'desc' : _params$sortOrder,
    bonusType = params.bonusType,
    isPreliminary = params.isPreliminary;
  var dateRange = {};
  if (startDate) dateRange.targetDateFrom = startOfTheDay(startDate).format();
  if (endDate) dateRange.targetDateTo = endOfTheDay(endDate).format();
  return queryString.stringify(_objectSpread(_objectSpread({
    pageSize: pageSize,
    pageIndex: pageIndex
  }, dateRange), {}, {
    accountId: accountId,
    status: status,
    sortOrder: sortOrder,
    bonusType: _isArray(bonusType) ? bonusType : [bonusType],
    isPreliminary: isPreliminary,
    loadPackageInfoForRounds: true
  }), {
    arrayFormat: 'index'
  });
};
export var getBonusRunsStatsParams = function getBonusRunsStatsParams() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var startDate = params.startDate,
    endDate = params.endDate,
    _params$pageIndex2 = params.pageIndex,
    pageIndex = _params$pageIndex2 === void 0 ? 0 : _params$pageIndex2,
    _params$pageSize2 = params.pageSize,
    pageSize = _params$pageSize2 === void 0 ? 20 : _params$pageSize2,
    accountId = params.accountId,
    status = params.status,
    _params$sortOrder2 = params.sortOrder,
    sortOrder = _params$sortOrder2 === void 0 ? 'desc' : _params$sortOrder2;
  var dateRange = {};
  if (startDate) dateRange.targetDateFrom = startOfTheDay(startDate).format();
  if (endDate) dateRange.targetDateTo = endOfTheDay(endDate).format();
  return {
    params: _objectSpread(_objectSpread({}, dateRange), {}, {
      pageSize: pageSize,
      pageIndex: pageIndex,
      accountId: accountId,
      status: status,
      sortOrder: sortOrder
    })
  };
};
export var getBonusHistoryParams = function getBonusHistoryParams(options) {
  var _options$pageIndex = options.pageIndex,
    pageIndex = _options$pageIndex === void 0 ? 0 : _options$pageIndex,
    _options$pageSize = options.pageSize,
    pageSize = _options$pageSize === void 0 ? 10 : _options$pageSize,
    startDate = options.startDate,
    endDate = options.endDate,
    _options$sortField = options.sortField,
    sortField = _options$sortField === void 0 ? 'date' : _options$sortField,
    _options$sortValue = options.sortValue,
    sortValue = _options$sortValue === void 0 ? 'desc' : _options$sortValue,
    type = options.type;
  var dateRange = {};
  if (startDate) dateRange.From = startOfTheDay(startDate).format();
  if (endDate) dateRange.Until = endOfTheDay(endDate).format();
  return queryString.stringify(_objectSpread(_objectSpread({
    pageIndex: pageIndex,
    pageSize: pageSize
  }, dateRange), {}, {
    sortValue: sortValue,
    sortField: sortField,
    type: type
  }));
};
export var startUpDirectBonusModel = function startUpDirectBonusModel(response) {
  var _response$businessVol;
  var businessVolumeData = response === null || response === void 0 || (_response$businessVol = response.businessVolumes) === null || _response$businessVol === void 0 ? void 0 : _response$businessVol.map(function (el) {
    var _el$bonuses;
    if (((_el$bonuses = el.bonuses) === null || _el$bonuses === void 0 ? void 0 : _el$bonuses.length) > 0) {
      var _el$bonuses$ = el.bonuses[0],
        totalBonus = _el$bonuses$.totalBonus,
        bonusCap = _el$bonuses$.bonusCap,
        paidBonus = _el$bonuses$.paidBonus;
      return _objectSpread(_objectSpread({}, el), {}, {
        totalBonus: totalBonus,
        bonusCap: bonusCap,
        paidBonus: paidBonus
      });
    }
    return _objectSpread({}, el);
  });
  return _objectSpread(_objectSpread({}, response), {}, {
    businessVolumes: businessVolumeData,
    total: response === null || response === void 0 ? void 0 : response.total
  });
};
export var networkMatchingBonusModel = function networkMatchingBonusModel(response) {
  return _orderBy((response.bonusRuns || []).map(function (bonusRun) {
    var _bonusRun$directBonus, _bonusRun$startupBonu, _bonusRun$networkBonu, _bonusRun$matchingBon, _bonusRun$targetDateF;
    return _objectSpread(_objectSpread({}, _omit(bonusRun, ['networkBonus', 'startupBonus', 'directBonus', 'matchingBonus'])), {}, {
      directBonus: (_bonusRun$directBonus = bonusRun === null || bonusRun === void 0 ? void 0 : bonusRun.directBonus) !== null && _bonusRun$directBonus !== void 0 ? _bonusRun$directBonus : {
        amount: 0
      },
      startupBonus: (_bonusRun$startupBonu = bonusRun === null || bonusRun === void 0 ? void 0 : bonusRun.startupBonus) !== null && _bonusRun$startupBonu !== void 0 ? _bonusRun$startupBonu : {
        amount: 0
      },
      networkBonus: (_bonusRun$networkBonu = bonusRun === null || bonusRun === void 0 ? void 0 : bonusRun.networkBonus) !== null && _bonusRun$networkBonu !== void 0 ? _bonusRun$networkBonu : {
        amount: 0
      },
      matchingBonus: (_bonusRun$matchingBon = bonusRun === null || bonusRun === void 0 ? void 0 : bonusRun.matchingBonus) !== null && _bonusRun$matchingBon !== void 0 ? _bonusRun$matchingBon : {
        amount: 0
      },
      total: Object.values(bonusRun).reduce(function (acc, el) {
        var _el$amount;
        return add(acc, (_el$amount = el === null || el === void 0 ? void 0 : el.amount) !== null && _el$amount !== void 0 ? _el$amount : 0);
      }, 0),
      id: bonusRun.id,
      targetDateFrom: (_bonusRun$targetDateF = bonusRun === null || bonusRun === void 0 ? void 0 : bonusRun.targetDateFrom) !== null && _bonusRun$targetDateF !== void 0 ? _bonusRun$targetDateF : moment().toISOString()
    });
  }), 'targetDateFrom', 'desc');
};
export var networkMatchingCurrentBonusModel = function networkMatchingCurrentBonusModel(response) {
  var _response$bonusRuns$, _response$bonusRuns$2, _response$bonusRuns$3, _response$bonusRuns$4;
  return _objectSpread(_objectSpread({}, _omit(response.bonusRuns[0], ['networkBonus', 'startupBonus', 'directBonus', 'matchingBonus'])), {}, {
    directBonus: (_response$bonusRuns$ = response.bonusRuns[0].directBonus) !== null && _response$bonusRuns$ !== void 0 ? _response$bonusRuns$ : {
      amount: 0
    },
    startupBonus: (_response$bonusRuns$2 = response.bonusRuns[0].startupBonus) !== null && _response$bonusRuns$2 !== void 0 ? _response$bonusRuns$2 : {
      amount: 0
    },
    networkBonus: (_response$bonusRuns$3 = response.bonusRuns[0].networkBonus) !== null && _response$bonusRuns$3 !== void 0 ? _response$bonusRuns$3 : {
      amount: 0
    },
    matchingBonus: (_response$bonusRuns$4 = response.bonusRuns[0].matchingBonus) !== null && _response$bonusRuns$4 !== void 0 ? _response$bonusRuns$4 : {
      amount: 0
    },
    total: Object.values(response.bonusRuns[0]).reduce(function (acc, el) {
      var _el$amount2;
      return add(acc, (_el$amount2 = el === null || el === void 0 ? void 0 : el.amount) !== null && _el$amount2 !== void 0 ? _el$amount2 : 0);
    }, 0),
    id: response.bonusRuns[0].id
  });
};
export var getAllLeadershipHistoryParams = function getAllLeadershipHistoryParams(options) {
  var date = options.date,
    status = options.status,
    type = options.type,
    _options$sort = options.sort,
    sort = _options$sort === void 0 ? {
      sortField: 'runId',
      sortOrder: 'desc'
    } : _options$sort;
  var filterField = [];
  var filterValue = [];
  var statusOptionsValues = _defineProperty(_defineProperty(_defineProperty({}, leadershipRunStatus.Pending, 'Pending'), leadershipRunStatus.Succeeded, 'Succeeded'), leadershipRunStatus.RanksSaved, 'RanksSaved');
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  if (date) {
    filterField.push('date');
    filterValue.push(date);
  }
  if (statusOptionsValues[status]) {
    filterField.push('status');
    filterValue.push(statusOptionsValues[status]);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize', 'runType'])), sort && mapSort(sort)), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), type && {
    runType: type
  }), {
    arrayFormat: 'index'
  });
};
export var getLeadershipHistoryParams = function getLeadershipHistoryParams(options) {
  var searchCriteria = options.searchCriteria,
    searchInput = options.searchInput,
    type = options.type,
    leadershipRunId = options.leadershipRunId,
    _options$sort2 = options.sort,
    sort = _options$sort2 === void 0 ? {
      sortField: 'accountId',
      sortOrder: 'asc'
    } : _options$sort2;
  var filterField = ['runId'];
  var filterValue = [leadershipRunId];
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  if (searchCriteria && searchInput !== null && searchInput !== void 0 && searchInput.length) {
    filterField.push(searchCriteria);
    filterValue.push(searchInput);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize', 'runType'])), sort && mapSort(sort)), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), type && {
    runType: type
  }), {
    arrayFormat: 'index'
  });
};
export var getAllEagleHistoryParams = function getAllEagleHistoryParams(options) {
  var date = options.date,
    status = options.status,
    _options$sort3 = options.sort,
    sort = _options$sort3 === void 0 ? {
      sortField: 'runId',
      sortOrder: 'desc'
    } : _options$sort3;
  var filterField = [];
  var filterValue = [];
  var statusOptionsValues = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, eagleStatus.Pending, 'Pending'), eagleStatus.Succeeded, 'Succeeded'), eagleStatus.RanksSaved, 'RanksSaved'), eagleStatus.WalletsRewarded, 'WalletsRewarded');
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  if (date) {
    filterField.push('date');
    filterValue.push(date);
  }
  if (statusOptionsValues[status]) {
    filterField.push('status');
    filterValue.push(statusOptionsValues[status]);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), sort && mapSort(sort)), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};
export var getEagleHistoryParams = function getEagleHistoryParams(options) {
  var searchCriteria = options.searchCriteria,
    searchInput = options.searchInput,
    eagleRunId = options.eagleRunId,
    _options$sort4 = options.sort,
    sort = _options$sort4 === void 0 ? {
      sortField: 'runId',
      sortOrder: 'asc'
    } : _options$sort4;
  var filterField = ['runId'];
  var filterValue = [eagleRunId];
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  if (searchCriteria && searchInput !== null && searchInput !== void 0 && searchInput.length) {
    filterField.push(searchCriteria);
    filterValue.push(searchInput);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize', 'runType'])), sort && mapSort(sort)), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};