function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import { Route } from 'react-router-dom';
import { OrdersDetailPage } from './Orders';
import AddOrder from './Orders/AddOrder';
import OrderShoppingCart from './Orders/ShoppingCart';
import DetailPagePackages from './Packages/Packages/DetailsPage';
import AddPackage from './Packages/Packages/AddPackage';
import EditPackage from './Packages/Packages/EditPackage';
import AccountDetails from './Account';
import ChangeOwner from './Account/ChangeOwner';
import History from './Profiles/History/index';
import { EditProfile, DetailPageProfiles } from './Profiles';
import TransactionsDetailPage from './Transactions/DetailPage';
import EditKycInfo from './Kyc/EditKycInfo';
import { DetailPageDeposit } from './Deposits';
import { DetailPagePlan, EditPlan, AddPlan } from './Plans';
import KycDetailPage from './Kyc/DetailsPage';
import TaskDetailPage from './Tasks/DetailsPage';
import DetailPageBundle from './Packages/Bundles/DetailPageBundle';
import CreateBundle from './Packages/Bundles/CreateBundle';
import EditBundle from './Packages/Bundles/EditBundle';
import SplitBalanceHistory from './Account/SplitBalanceHistory';
import SplitRunHistoryAccounts from './Transactions/SplitRunHistoryAccounts';
import WithdrawalDetailPage from './Transactions/WithdrawalDetailPage';
import LeadershipRunHistoryAccounts from './Recognition/LeadershipRank/LeadershipRunHistoryAccounts';
import EagleRunProfileHistory from './Recognition/EagleRank/EagleRunProfileHistory';
import DetailPagePaymentMethod from './Settings/PaymentMethods/DetailPagePaymentMethod';
import EditPaymentMethod from './Settings/PaymentMethods/EditPaymentMethod';
import AddSubscription from './Subscriptions/Subscriptions/AddSubscription';
import DetailPageSubscriptions from './Subscriptions/Subscriptions/DetailPageSubscriptions';
import EditSubscription from './Subscriptions/Subscriptions/EditSubscription';
import GiftCodeDetailsPage from './GiftCodes/DetailsPage';
import MyBonuses from './Bonuses';
import NetworkBonusDetails from './Bonuses/NetworkBonusDetails';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PrivateRoutes = function PrivateRoutes(_ref) {
  var isAdmin = _ref.isAdmin,
    isKycOperator = _ref.isKycOperator,
    isSupport = _ref.isSupport,
    isTeamLeader = _ref.isTeamLeader;
  return [(isAdmin || isSupport || isTeamLeader) && {
    path: '/orders/order/:orderId',
    component: OrdersDetailPage
  }, (isAdmin || isTeamLeader) && {
    path: '/orders/add-order',
    component: AddOrder
  }, (isAdmin || isTeamLeader) && {
    path: '/orders/shop-cart',
    component: OrderShoppingCart
  }, (isAdmin || isSupport || isTeamLeader) && {
    path: '/transactions/transaction/:transactionId',
    component: TransactionsDetailPage
  }, (isAdmin || isSupport || isTeamLeader) && {
    path: '/transactions/split-run-history/:splitRunId',
    component: SplitRunHistoryAccounts
  }, (isAdmin || isSupport || isTeamLeader) && {
    path: '/transactions/withdrawal/:withdrawalId',
    component: WithdrawalDetailPage
  }, (isAdmin || isKycOperator || isSupport || isTeamLeader) && {
    path: '/kyc/:kycId/edit',
    component: EditKycInfo
  }, (isAdmin || isKycOperator || isSupport || isTeamLeader) && {
    path: '/kyc/:kycId',
    component: KycDetailPage
  }, (isAdmin || isTeamLeader) && {
    path: '/packages/add-package',
    component: AddPackage
  }, (isAdmin || isTeamLeader) && {
    path: '/packages/edit-package',
    component: EditPackage
  }, (isAdmin || isSupport || isTeamLeader) && {
    path: '/packages/package/:packageId',
    component: DetailPagePackages
  }, (isAdmin || isTeamLeader) && {
    path: '/packages/create-bundle',
    component: CreateBundle
  }, (isAdmin || isTeamLeader) && {
    path: '/packages/edit-bundle',
    component: EditBundle
  }, (isAdmin || isTeamLeader) && {
    path: '/packages/bundle/:bundleId',
    component: DetailPageBundle
  }, (isAdmin || isKycOperator || isSupport || isTeamLeader) && {
    path: '/profiles/profile/:id/accounts/change-owner',
    component: ChangeOwner
  }, (isAdmin || isKycOperator || isSupport || isTeamLeader) && {
    path: '/profiles/profile/:id/accounts/:accountId/split-balance-history',
    component: SplitBalanceHistory
  }, (isAdmin || isKycOperator || isSupport || isTeamLeader) && {
    path: '/profiles/profile/:id/accounts/:accountId/my-bonuses/network/details/:bonusRoundId',
    component: NetworkBonusDetails
  }, (isAdmin || isKycOperator || isSupport || isTeamLeader) && {
    path: '/profiles/profile/:id/accounts/:accountId/my-bonuses',
    component: MyBonuses
  }, (isAdmin || isKycOperator || isSupport || isTeamLeader) && {
    path: '/profiles/profile/:id/accounts/:accountId',
    component: AccountDetails
  }, (isAdmin || isKycOperator || isTeamLeader) && {
    path: '/profiles/edit-profile',
    component: EditProfile
  }, (isAdmin || isKycOperator || isSupport || isTeamLeader) && {
    path: '/profiles/profile/:userId/history',
    component: History
  }, (isAdmin || isKycOperator || isSupport || isTeamLeader) && {
    path: '/profiles/profile/:id',
    component: DetailPageProfiles
  }, (isAdmin || isSupport || isTeamLeader) && {
    path: '/recognition/leadership-run-history/:leadershipRunId',
    component: LeadershipRunHistoryAccounts
  }, (isAdmin || isSupport || isTeamLeader) && {
    path: '/recognition/eagle-run-history/:eagleRunId',
    component: EagleRunProfileHistory
  }, (isAdmin || isSupport || isTeamLeader) && {
    path: '/deposits/details/:depositId/:type',
    component: DetailPageDeposit
  }, isAdmin && {
    path: '/plans/add-plan',
    component: AddPlan
  }, isAdmin && {
    path: '/plans/edit-plan',
    component: EditPlan
  }, isAdmin && {
    path: '/plans/details/:id',
    component: DetailPagePlan
  }, (isAdmin || isTeamLeader) && {
    path: '/tasks/kyc/:taskId/:kycId',
    component: TaskDetailPage
  }, isAdmin && {
    path: '/settings/payment-methods/:paymentMethodId/edit',
    component: EditPaymentMethod
  }, isAdmin && {
    path: '/settings/payment-methods/:paymentMethodId',
    component: DetailPagePaymentMethod
  }, (isAdmin || isTeamLeader) && {
    path: '/subscriptions/add-subscription',
    component: AddSubscription
  }, (isAdmin || isSupport || isTeamLeader) && {
    path: '/subscriptions/subscription/:subscriptionId',
    component: DetailPageSubscriptions
  }, (isAdmin || isTeamLeader) && {
    path: '/subscriptions/edit-subscription/:subscriptionCatalogueItemId',
    component: EditSubscription
  }, (isAdmin || isSupport || isTeamLeader) && {
    path: '/gift-codes/gift-code/:giftCode',
    component: GiftCodeDetailsPage
  }].filter(Boolean).map(function (route) {
    return ___EmotionJSX(Route, _extends({
      key: route.path
    }, route));
  });
};