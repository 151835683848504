export var giftCodeGeneralInfo = [{
  label: 'Order id',
  value: 'id'
}, {
  label: 'Date of order',
  value: 'dateOfOrder',
  dateTime: true
}, {
  label: 'Date of redeem',
  value: 'dateOfRedeem',
  dateTime: true
}, {
  label: 'Expire date',
  value: 'expireDate',
  dateTime: true
}, {
  label: 'Order Package Name',
  value: 'packageName'
}];