function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var splitBalanceHistoryBreadCrumb = function splitBalanceHistoryBreadCrumb(userId, accountId) {
  return [{
    url: '/profiles',
    label: 'Profiles'
  }, {
    url: "/profiles/profile/".concat(userId, "/accounts"),
    label: 'Accounts'
  }, {
    url: "/profiles/profile/".concat(userId, "/accounts/").concat(accountId, "/wallets"),
    label: 'Wallets'
  }, {
    label: 'Split Balance History'
  }];
};
export var splitHistoryColumns = [{
  name: 'Run Id',
  value: 'splitsRunId',
  width: 80
}, {
  name: 'Before run Available Splits',
  value: 'preRunAvailableSplits'
}, {
  name: 'After run Available Splits',
  value: 'postRunAvailableSplits'
}, {
  name: 'Before run Max Splits',
  value: 'preRunMaxSplits'
}, {
  name: 'After run Max Splits',
  value: 'postRunMaxSplits'
}, {
  name: 'Before run Immediate Mining Balance',
  value: 'preRunImmediateMiningBalance'
}, {
  name: 'After run Immediate Mining Balance',
  value: 'postRunImmediateMiningBalance'
}, {
  name: 'Before run Used Immediate Mining Balance',
  value: 'preRunUsedImmediateMiningBalance'
}, {
  name: 'After run Used Immediate Mining Balance',
  value: 'postRunUsedImmediateMiningBalance'
}, {
  name: 'Before run Token Wallet Balance',
  value: 'preRunTokenWalletBalance'
}, {
  name: 'After run Token Wallet Balance',
  value: 'postRunTokenWalletBalance'
}];
var accountSubBreadcrumbsPart = function accountSubBreadcrumbsPart(userId) {
  return [{
    url: '/profiles',
    label: 'Profiles'
  }, {
    url: "/profiles/profile/".concat(userId, "/accounts"),
    label: 'Accounts'
  }];
};
export var myPackagesBreadcrumbs = function myPackagesBreadcrumbs(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'My Packages'
  }]);
};
export var mySubscriptionsBreadcrumbs = function mySubscriptionsBreadcrumbs(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'My Subscriptions'
  }]);
};
export var breadcrumbsWalletPages = function breadcrumbsWalletPages(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'Wallets'
  }]);
};
export var myMatchingTreeBreadcrumbs = function myMatchingTreeBreadcrumbs(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'My Matching'
  }]);
};
export var myNetworkTreeBreadcrumbs = function myNetworkTreeBreadcrumbs(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'My Network'
  }]);
};